import { Input } from "@chakra-ui/react";
import { forwardRef } from "react";

const CreatedAtInput = forwardRef((props: any, ref) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = "CreatedAtInput";

export default CreatedAtInput;
