import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import RoleResource from "api/role";
import UserResource from "api/user";
import EditUserForm from "components/user/EditUserForm";
import { UserSchema } from "interface/user/userListItem";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import "./user.css";

const EditUser: React.FC<any> = (props: any) => {
  const { data: listData, setEditUserModalOpen } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<UserSchema>();
  const api = new UserResource();
  const roleAPI = new RoleResource();
  const [roleList, setroleList] = useState([]);


  const [errorMessage, setErrorMessage] = useState<any>();

  useQuery(
    ["roleList"],
    async () => {
      const response = await roleAPI.list();
      response?.data?.roles.shift();
      setroleList(response?.data?.roles);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateUser = useMutation(
    (data: any) => api.update(listData?.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "User has been updated.",
          status: "success",
          isClosable: true,
        });

        queryClient.invalidateQueries("organizationUserList");
        setEditUserModalOpen(false);
      },
      onError: (error: any) => {
        // setErrorMessage("User Creation Failed.");
        if(error.response.status === 403){
          setErrorMessage(error.response.data.detail);
        }
        else{
          setErrorMessage(error.response.data.message);
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    updateUser.mutate(data);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md" color={"#23282C"}>
          Edit User
        </Heading>
      </Flex>

      <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <EditUserForm listData={listData} roleList={roleList} />
              <Button
                mt={"8 !important"}
                colorScheme="primary"
                type="submit"
                isLoading={updateUser.isLoading}
                backgroundColor={"#000000"}
                _hover={{ backgroundColor: "#000000" }}
              >
                Update
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditUser;
