/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  List,
  ListItem,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { FiCamera } from "react-icons/fi";
import FileDetails from "./FileDetails";

interface Props {
  title?: string;
  description?: string;
  uploadedFiles?: any;
  fileKey?: any;
  required?: boolean;
  multiple?: boolean;
  maxFiles?: number;
}
const ImageUploader: React.FC<Props> = (props) => {
  const {
    title = "Upload Files",
    description = "Upload Image",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadedFiles,
    fileKey = "files",
    multiple = false,
    maxFiles = 1,
  } = props;
  const toast = useToast();
  const [myFiles, setMyFiles] = useState<any>([]);
  const [previewFile, setPreviewFile] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageName, setImageName] = useState<any>();
  const { setValue, clearErrors } = useFormContext();
  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        let title = "Maximum upload attachments size upto 15 MB.";
        if (fileRejections[0]?.errors[0]?.code === "too-many-files") {
          title = `Only ${maxFiles} file is allowed.`;
        }
        toast({
          title: title,
          status: "error",
          isClosable: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple, myFiles, toast]
  );

  React.useMemo(async () => {
    if (multiple) {
      setValue(fileKey, myFiles);
    } else {
      if (myFiles.length !== 0) {
        setValue(fileKey, myFiles[0]);
        setPreviewFile(URL.createObjectURL(myFiles[0]));
      }
    }
  }, [fileKey, multiple, myFiles, setValue]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      //Limit 15 MB
      return {
        code: "file-size-too-large",
        message: "Maximum upload attachments size upto 15 MB.",
      };
    }

    return null;
  };
  const getPreview = () => {
    if (previewFile) {
      return <Image src={previewFile} alt={imageName} title={imageName} />;
    }

    return (
      <VStack>
        <Icon as={BiPlus} h="16" w="16" color="gray.300" />
        {isDragActive ? (
          <Text textAlign="center" color="gray.400">
            {title}
          </Text>
        ) : (
          <Text textAlign="center" color="gray.400">
            {description}
          </Text>
        )}
      </VStack>
    );
  };

  const removeFile = (file: any) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file: any, index: number) => (
    <ListItem key={index} padding="8px 0 8px 0">
      <Flex justify="space-between">
        <FileDetails
          file={file}
          fileName={file.path.replace(/\.[^/.]+$/, "")}
          fileSize={file.size}
          mimeType={file.type}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: "pointer", color: "red.300" }}
            icon={<BiTrash />}
            variant="link"
            aria-label={"Delete"}
            onClick={removeFile(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    maxFiles: maxFiles,
    validator: fileSizeValidator,
    multiple: multiple,
  });

  return (
    <>
      <Center
        {...getRootProps()}
        cursor="pointer"
        w="100%"
        h="160px"
        bg="gray.50"
        overflow="hidden"
        objectFit="cover"
        shadow="box"
        rounded="sm"
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100"
      >
        <input {...getInputProps()} />
        {getPreview()}
        <Icon
          as={FiCamera}
          position="relative"
          w={6}
          h={6}
          color="white"
          zIndex="1"
          bottom=" 62px"
          right="24px"
        />
      </Center>
      {multiple && (
        <List py="2" spacing="2">
          {files}
        </List>
      )}
    </>
  );
};

export default ImageUploader;
