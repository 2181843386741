import {
  Avatar,
  Flex,
  Heading,
  IconButton,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import { setCurrentChatUser } from "actions/data/chat";
import ChatResource from "api/chat";
import React, { useEffect } from "react";
import DOMPurify from "dompurify";

import { MdOutlineDelete } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { truncateString } from "utils";

interface Props {
  name: string;
  createdBy: number;
  conversation: string;
  attachment: string;
  isSeen: boolean;
  unreadChatCount?: number;
  setShowMessages: any;
  lastMessage: string;
  isPrimaryGroup: boolean;
  isGroup: boolean;
  isAlert: boolean;
  isAlertActive: boolean;
  participants:[];
}

const ChatListItem: React.FC<Props> = (props) => {
  const {
    name,
    createdBy,
    conversation,
    attachment,
    isSeen,
    unreadChatCount = 0,
    setShowMessages,
    lastMessage,
    isPrimaryGroup,
    isGroup,
    isAlert,
    isAlertActive,
    participants
  } = props;
  const dispatch = useDispatch();
  const chatApi = new ChatResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { currentChatUserDetails, loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );


console.log(currentChatUserDetails,'chat details for current chat')






  useEffect(() => {
    dispatch(
      setCurrentChatUser({
        name: currentChatUserDetails.name,
        conversation: currentChatUserDetails.conversation,
        attachment: currentChatUserDetails.attachment,
        default_group: currentChatUserDetails.default_group,
        isPrimaryGroup: currentChatUserDetails.isPrimaryGroup,
        isGroup: currentChatUserDetails.isGroup,
        isAlert: currentChatUserDetails.isAlert,
        isAlertActive: currentChatUserDetails.isAlertActive,
        participants

      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatUserDetails.conversation]);

  const updateChatRead = useMutation(
    (data: any) => chatApi.readConversation(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
      },
      onError: (error: any) => {},
    }
  );

  const handleCurrentChatUser = async () => {
    setShowMessages(true);
    dispatch(
      setCurrentChatUser({
        name,
        conversation,
        attachment,
        default_group: isPrimaryGroup,
        isPrimaryGroup: isPrimaryGroup,
        isGroup: isGroup,
        isAlert: isAlert,
        isAlertActive: isAlertActive,
        participants

      })
    );
    // set 0 readConversation
    updateChatRead.mutate({ conversation });
  };

  const chatDeleteMutation = useMutation(
    (id: string) => chatApi.deleteGroupChat(id),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
        dispatch(
          setCurrentChatUser({
            name: "",
            conversation: "",
            attachment: "",
            default_group: "",
          })
        );
      },
      onError: (error: any) => {
        toast({
          title: error?.response?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const handleChatDelete = (roomId: string) => {
    chatDeleteMutation.mutate(roomId);
  };

  // useEffect(() => {
  //   if (unreadChatCount > 0) {
  //   }
  // }, [unreadChatCount]);

  const formatMessage = (message: string) => {
    const sanitizedMessage = DOMPurify.sanitize(message);
    const mentionPattern = /@\{id:(\d+),name:([^\\}]+)\}/g;
    return sanitizedMessage.replace(mentionPattern, (match, id, name) => {
      return `<strong >${name}</strong>`;
    });
  };


  const tempMessage=truncateString(lastMessage, 40)||''
  const formattedMessage=formatMessage(tempMessage)
  return (
    <>
      <Stack
        _hover={{ cursor: "pointer" ,backgroundColor:'grey'}}
        position="relative"
        rounded="sm"
        p="1"
        onClick={() => handleCurrentChatUser()}
      >
        <Stack
          className="chat-user-wrap"
          borderRadius={"8px"}
          width={"100%"}
          direction={["row"]}
          spacing="2"
          p={["1", "2", "4", "4"]}
          alignItems={"center"}
          bg="white"
          sx={
            currentChatUserDetails.conversation === conversation
              ? { boxShadow: "0px 3px 0px 0px #8ef2d3" }
              : { boxShadow: "none" }
          }
        >
          <Stack width="90%" className="user-content-wrap" direction={"row"}>
            <Stack
              width={["20%", "15%"]}
              className="user-detail-wrap"
              backgroundColor={"#FFF"}
            >
              <Avatar
                showBorder
                shadow="md"
                size={"sm"}
                name={name}
                src={attachment}
                style={{
                  border: isAlert ? "1px solid red" : "1px solid #5e4c23",
                }}
              />
            </Stack>
            <Flex
              justify="space-between"
              width={["80%", "85%"]}
              pl={["2", "1"]}
              className="username-message-wrap"
              display={"block"}
            >
              <Heading
                width="75%"
                size="xs"
                fontWeight="bold"
                alignSelf="center"
                wordBreak="break-all"
                color={"#545454"}
              >
                {name}
              </Heading>
              <Text
                color={"#000"}
                width="75%"
                size="xs"
                fontWeight="medium"
                alignSelf="center"
                wordBreak="break-all"
                dangerouslySetInnerHTML={{
                  __html: formattedMessage.replace(
                    /<a/g,
                    '<a rel="noopener noreferrer" target="_blank"'
                  ),
                }}
              >
              </Text>
            </Flex>
          </Stack>
          {!isPrimaryGroup && isGroup && createdBy === loggedInUser?.id && (
            <IconButton
              variant={"unstyled"}
              borderRadius={"10px"}
              aria-label="Delete"
              sx={{
                _hover: {
                  color: "gray.300",
                  fontSize: "18px",
                },
              }}
              onClick={() =>
                handleChatDelete(currentChatUserDetails?.conversation)
              }
              icon={<MdOutlineDelete />}
            />
          )}
          {!isSeen &&
            unreadChatCount > 0 &&
            currentChatUserDetails.conversation !== conversation && (
              <Tag
                width={"10%"}
                bg="red.400"
                rounded="full"
                color="white"
                fontSize="10px"
                position="absolute"
                right="3"
                bottom="3"
                size="sm"
                className="chat-count"
              >
                1{/* {unreadChatCount} */}
              </Tag>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default ChatListItem;
