import Color from "@arcgis/core/Color";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import MapView from "@arcgis/core/views/MapView";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
export const polygonSymbol = new SimpleFillSymbol({
  color: new Color([0, 0, 0, 0.3]), // overlay color (red, semi-transparent)
  style: "solid",

  outline: {
    color: "black",
    width: 1,
    style: "dash",
  },
});
export async function OnDraw(viewProp: any) {
  return viewProp.when(() => {
    const sketchlayer = new GraphicsLayer();
    const sketch = new SketchViewModel({
      layer: sketchlayer,
      polygonSymbol: polygonSymbol,
      view: viewProp,
      snappingOptions: {
        // autocasts to SnappingOptions()
        enabled: true, // global snapping is turned on
      },
      defaultUpdateOptions: {
        tool: "reshape",
        toggleToolOnClick: true,
      },
    });

    return sketch;
  });
}

export async function focusFeature(view: MapView, item: any) {
  view.goTo(item);
}
