const routes = {
  dashboard: "/",
  map_mobile: "/mobile",
  auth: {
    login: "/login",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password/*",
    setPassword: "/set-password",
    verifyEmail: "/verify-email",
    register: "/register",
  },
  settings: {
    base: "/settings/*",
    index: "/settings/index",
  },
  chats: {
    list: "/chats",
  },
  bridges: {
    list: "/bridges",
  },
  infrastructures: {
    list: "/infrastructures/list",
    bridge: {
      assessment: {
        list: "/infrastructures/bridge/:bridge_id",
        fema_form: "/assessment/fema-form",
        detail:
          "/infrastructures/bridge/:bridge_id/assessments/:assessment_id/detail",
      },
    },
  },
  organization: {
    edit: "/organization/edit/:id",
    view: "/organization/view/:id",
  },
  privacy_policy: "/privacy-policy",
  terms_conditions: "/terms-conditions",
  remaining: "/*",
};

export default routes;
