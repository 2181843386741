import { Stack } from "@chakra-ui/react";
import ChatBot from "components/chat/ChatBot";
import ChatSearch from "components/chat/ChatSearch";
import ChatUserDetails from "components/chat/ChatUserDetails";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const Chat: React.FC = () => {
  const [showMessage, setShowMessages] = useState(true);
  const { currentChatUserDetails } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );



  return (
    <Stack direction="column" spacing="4">
      <Stack
        direction="row"
        spacing="4"
        maxW={"80%"}
        className="chat-msg-box-wrapper"
      >
        <ChatSearch setShowMessages={setShowMessages} />
        {currentChatUserDetails?.conversation && (
          <Stack
            direction="column"
            flex="1"
            spacing="2"
            pos="relative"
            className="chat-user-details-wrapper"
          >
            <ChatUserDetails
              name={currentChatUserDetails?.name}
              attachment={currentChatUserDetails?.attachment}
              company={currentChatUserDetails?.company}
              showMessage={showMessage}
              setShowMessages={setShowMessages}
            />
            <ChatBot
              conversation={currentChatUserDetails?.conversation}
              showMessage={showMessage}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Chat;
