/**
 * Component to show the table headers in table
 *
 */

import { Th, Thead, Tr } from "@chakra-ui/react";
import { TableHeaderOptions } from "interface/common/tableHeader";
import React, { useState } from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

export interface TableHeaderProps {
  setFilterParams?: any;
  columns: TableHeaderOptions[];
  showDelete: boolean
}

const TableHeaderComponent: React.FC<TableHeaderProps> = (props) => {
  const { setFilterParams, columns, showDelete } = props;

  const [order, setOrder] = useState<string>("asc");
  const [columnList, setColumnList] = useState<TableHeaderOptions[]>(columns);

  const handleSortingChange = (
    accessor: string,
    sortByOrder: string,
    index: number
  ) => {
    const col = [...columnList];
    col.forEach((column: any, ind: number) => {
      if (ind === index) {
        column.sortable = true;
        column.sortByOrder = sortByOrder === "asc" ? "desc" : "asc";
      } else {
        column.sortable = false;
        column.sortByOrder = "asc";
      }
    });
    setColumnList(col);
    const sortOrder = order === "asc" ? "desc" : "asc";
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      setFilterParams((prevState: any) => ({
        ...prevState,
        sort_by: sortField
          ? sortOrder === "desc"
            ? `-${sortField}`
            : sortField
          : "",
      }));
    }
  };

  return (
    <Thead>
      <Tr>
        {columnList?.map((column: any, index: number) => (
          <Th
            className="table-title"
            key={column?.accessor}
            onClick={() =>
              handleSortingChange(column?.accessor, column?.sortByOrder, index)
            }
          >
            {column?.label}
            {column?.sortable ? (
              column?.sortByOrder === "desc" ? (
                <TiArrowSortedDown />
              ) : (
                <TiArrowSortedUp />
              )
            ) : (
              <TiArrowUnsorted />
            )}
          </Th>
        ))}
        {!!showDelete &&
          <Th>Delete</Th>
        }
      </Tr>
    </Thead>
  );
};

export default TableHeaderComponent;
