import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Vector } from "assets/icons";
interface CustomToastProps {
  title: string;
  description: string;
  status?: "success" | "error";
}

const CustomToast: React.FC<CustomToastProps> = ({ title, description }) => {
  const toast = useToast();

  const handleClose = () => {
    toast.closeAll();
  };

  return (
    <Box
      boxShadow="md"
      zIndex={9}
      p={4}
      mb={4}
      borderRadius="md"
      backgroundColor={"white"}
    >
      <Flex>
        <Text color={"#4C4C4C"}>Recon</Text>
        <Spacer />
        <IconButton
          variant={"unstyled"}
          aria-label="ResetChat"
          onClick={handleClose}
          icon={<CloseIcon />}
        />
      </Flex>
      <Flex>
        <Center>
          <Image src={Vector} alt="Recon" title="Recon" />
        </Center>
        <Box ml={"8px"}>
          <Text as={"b"} color={"#4C4C4C"}>
            {title}
          </Text>
          <Text color={"#4C4C4C"}>{description}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default CustomToast;
