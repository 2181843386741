import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import JsonUploader from "components/common/JsonUploader";
import Uploader from "components/common/Uploader";
import { ASSESSMENT_FREQUENCY } from "constants/common";
import { OrganizationDataSchema } from "interface/organization/organizationListItem";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

const OrganizationForm: React.FC<OrganizationDataSchema> = (props) => {
  const { listData, setUploadDetail, setIsFileRemoved ,setIsHexFileRemoved,setUploadHexDetail} = props;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  useEffect(() => {
    if (!listData) return;
    setValue(
      "infrastructure_assessment_frequency",
      listData?.infrastructure_assessment_frequency
    );
    setValue("org_name", listData?.org_name);
  }, [listData, setValue]);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
        flex="1"
      >
        <>
          <GridItem>
            <FormControl isInvalid={!!errors?.org_name} isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                {...register(`org_name`, {
                  required: "Company Name",
                  maxLength: {
                    value: 255,
                    message: "Too many characters.",
                  },
                })}
                type="text"
                defaultValue={listData?.org_name}
                placeholder={"Company Name"}
              />
              <FormErrorMessage>
                {!!errors?.org_name && !!errors?.org_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Upload infrastructure file</FormLabel>
              <Uploader
                description={"Upload zip files with shapefiles"}
                uploadedFiles={listData?.file_name}
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                fileKey="file_doc"
                setUploadDetail={setUploadDetail}
                setIsFileRemoved={setIsFileRemoved}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
            <FormLabel>Upload Hex file <Link style={{color:'Orange'}} to="https://cityofaustin.github.io/geojson-grid/">(Create Hex)</Link></FormLabel>
            <JsonUploader
                description={"Upload Json files with shapefiles"}
                // uploadedFiles={listData?.file_name}
                accept="json,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                fileKey="hex_file"
                setUploadDetail={setUploadHexDetail}
                setIsFileRemoved={setIsHexFileRemoved}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={!!errors?.infrastructure_assessment_frequency}
              isRequired
            >
              <FormLabel>Infrastructure Assessment Frequency </FormLabel>
              <Select
                placeholder="Select Assessment Frequency"
                defaultValue={listData?.infrastructure_assessment_frequency}
                {...register(`infrastructure_assessment_frequency`, {
                  required: "Infrastructure Assessment Frequency",
                })}
              >
                {ASSESSMENT_FREQUENCY.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.infrastructure_assessment_frequency &&
                  !!errors?.infrastructure_assessment_frequency?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </>
      </Grid>
    </Stack>
  );
};

export default OrganizationForm;
