import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import UserResource from "api/user";
import UserForm from "components/user/UserCreateForm";
import { OrganizationDataSchema } from "interface/organization/organizationListItem";
import { UserSchema } from "interface/user/userListItem";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import "./user.css";

const CreateUser: React.FC<OrganizationDataSchema> = (
  props: OrganizationDataSchema
) => {
  const { listData, setDeleteModalOpen, showRole } = props;

  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<UserSchema>();
  const api = new UserResource();

  const [errorMessage, setErrorMessage] = useState<any>();
  // add more just a array to loop through to get desired number of rows to add new users
  const [addMore, setAddMore] = useState([{}]);

  const addUser = useMutation((data: any) => api.storeUser(data), {
    onSuccess: (res: any) => {
      toast({
        position: "top",
        title: "User created.",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries("organizationUserList");
      setDeleteModalOpen(false);
    },
    onError: (error: any) => {
      // setErrorMessage("User Creation Failed.");
      if(error.response.status === 403){
        setErrorMessage(error.response.data.detail);
      }
      else{
        setErrorMessage(error.response.data.message);
      }
    },
  });

  const addRow = () => {
    // add new {} to addMore in order to add new row in the add list
    setAddMore([...new Set(addMore), {}]);
  };

  const onSubmit = (data: any) => {
    const users = data.users.map((user: any) => {
      return {
        ...user,
        organization: listData?.id ? listData.id : 0,
      };
    });
    addUser.mutate(users);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md" color={"#23282C"}>
          Add User
        </Heading>
      </Flex>

      <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <UserForm
                addMore={addMore}
                organizationId={listData?.id}
                showRole={showRole}
              />
              <Button
                className="link-btn add-user-btn"
                maxW={"80px"}
                onClick={addRow}
              >
                + Add User
              </Button>

              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addUser.isLoading}
                  backgroundColor={"#000000"}
                  _hover={{ backgroundColor: "#000000" }}
                  className="send-invite-btn"
                >
                  Send Invite
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateUser;
