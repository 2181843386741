import Resource from "api/resource";
import http from "utils/http";

class NotificationResource extends Resource {
  constructor() {
    super("notifications");
  }

  storeNotification(resource: any, headers?: any) {
    return http({
      url: "/notifications/send/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }
}

export { NotificationResource as default };
