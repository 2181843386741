import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import CreatedAtInput from "components/common/CreatedAtInput";
import CustomModal from "components/common/CustomModal";
import FileUploader from "components/common/FileUploader";
import ImageUploader from "components/common/ImageUploader";
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from "constants/common";
import React, { ChangeEvent, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import Webcam from "react-webcam";

interface Props {
  femaFormData: any;
}

const FemaForm: React.FC<Props> = (props) => {
  const { femaFormData } = props;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<any>();
  const webcamRef = useRef<any>(null);
  const webcamOtherWearRef = useRef<any>(null);
  const closeUpInputRef = useRef<any>(null);
  const otherWearInputRef = useRef<any>(null);
  const [isOpenCloseUp, setIsOpenCloseUp] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showCloseUpImage, setShowCloseUpImage] = useState(
    "https://placehold.co/400x200?text=Upload Image"
  );
  const [isOpenOtherWear, setIsOpenOtherWear] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showOtherWearImage, setShowOtherWearImage] = useState(
    "https://placehold.co/400x200?text=Upload Image"
  );

  const closeModalCloseUp = () => {
    setIsOpenCloseUp(false);
  };

  const handleButtonClick = (identifier: string) => {
    if (identifier === "cancel") {
      setIsOpenCloseUp(false);
    } else {
      if (webcamRef.current) {
        const photoData = webcamRef.current?.getScreenshot();
        setShowCloseUpImage(photoData);
        setIsOpenCloseUp(false);
      }
    }
  };

  const buttonGroupConfig = [
    {
      label: "Take a picture",
      color: "#44627B",
      onClick: handleButtonClick,
      identifier: "submit",
    },
    {
      label: "Cancel",
      variant: "outline",
      onClick: handleButtonClick,
      identifier: "cancel",
    },
  ];

  const closeModalOtherWear = () => {
    setIsOpenOtherWear(false);
  };

  const handleButtonClickOtherWear = (identifier: string) => {
    if (identifier === "cancel") {
      setIsOpenOtherWear(false);
    } else {
      if (webcamOtherWearRef.current) {
        const photoData = webcamOtherWearRef.current?.getScreenshot();
        setShowOtherWearImage(photoData);
        setIsOpenOtherWear(false);
      }
    }
  };

  const buttonGroupOtherWearConfig = [
    {
      label: "Take a picture",
      color: "#44627B",
      onClick: handleButtonClickOtherWear,
      identifier: "submit",
    },
    {
      label: "Cancel",
      variant: "outline",
      onClick: handleButtonClickOtherWear,
      identifier: "cancel",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeImage = (
    event: ChangeEvent<HTMLInputElement>,
    imageType: string
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const imageUrl = URL.createObjectURL(selectedFile);

      if (imageType === "closeUp") {
        setShowCloseUpImage(imageUrl);
        if (closeUpInputRef.current) {
          closeUpInputRef.current.value = "";
        }
      } else if (imageType === "otherWear") {
        setShowOtherWearImage(imageUrl);
        if (otherWearInputRef.current) {
          otherWearInputRef.current.value = "";
        }
      }
    }
  };

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
        flex="1"
      >
        <GridItem>
          <FormControl isInvalid={!!errors?.facility_type}>
            <FormLabel>Facility type</FormLabel>
            <Input
              {...register(`facility_type`, {
                required: "Facility type",
              })}
              type="text"
              defaultValue={"Bridge"}
              placeholder={"Facility type"}
            />
            <FormErrorMessage>
              {errors?.facility_type && !!errors?.facility_type?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.facility_name}>
            <FormLabel>Facility name</FormLabel>
            <Input
              {...register(`facility_name`, {
                required: false,
              })}
              type="text"
              placeholder={"Facility name"}
            />
            <FormErrorMessage>
              {errors?.facility_name && !!errors?.facility_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.facility_description}>
            <FormLabel>Facility description (purpose and use)</FormLabel>
            <Input
              {...register(`facility_description`, {
                required: false,
              })}
              type="text"
              placeholder={"Facility description (purpose and use)"}
            />
            <FormErrorMessage>
              {errors?.facility_description &&
                !!errors?.facility_description?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.street_address}>
            <FormLabel>Street Address/location</FormLabel>
            <Input
              {...register(`street_address`, {
                required: false,
              })}
              type="text"
              placeholder={"Street Address/location"}
            />
            <FormErrorMessage>
              {errors?.street_address && !!errors?.street_address?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl>
            <FormLabel>
              GPS coordinates (include start and end for facilities or damage
              longer than 200 ft)
            </FormLabel>
            {femaFormData && (
              <Input
                disabled
                defaultValue={`POINT(${femaFormData?.longitude}, ${femaFormData?.latitude})`}
                type="text"
              />
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.year_built}>
            <FormLabel>Year built</FormLabel>
            <Input
              {...register(`year_built`, {
                required: false,
              })}
              type="text"
              placeholder={"Year built"}
            />
            <FormErrorMessage>
              {errors?.year_built && !!errors?.year_built?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.dimensions}>
            <FormLabel>
              Dimensions: type, measure, and units (e.g., width 10 ft x length
              40 ft x depth 5 ft)
            </FormLabel>
            <Input
              {...register(`dimensions`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Dimensions: type, measure, and units (e.g., width 10 ft x length 40 ft x depth 5 ft)"
              }
            />
            <FormErrorMessage>
              {errors?.dimensions && !!errors?.dimensions?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.magnitude}>
            <FormLabel>
              Capacity/volume/quantity/number and units (e.g., two lanes, 500
              yd3, 6,000 ft2)
            </FormLabel>
            <Input
              {...register(`magnitude`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Capacity/volume/quantity/number and units (e.g., two lanes, 500 yd3, 6,000 ft2)"
              }
            />
            <FormErrorMessage>
              {errors?.magnitude && !!errors?.magnitude?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.materials}>
            <FormLabel>Materials</FormLabel>
            <Select
              {...register(`materials`, {
                required: false,
              })}
            >
              <option value="Concrete">Concrete</option>
              <option value="Wood">Wood</option>
              <option value="Steel">Steel</option>
            </Select>
            <FormErrorMessage>
              {errors?.materials && !!errors?.materials?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.variant}>
            <FormLabel>Make/model/type</FormLabel>
            <Input
              {...register(`variant`, {
                required: false,
              })}
              type="text"
              placeholder={"Make/model/type"}
            />
            <FormErrorMessage>
              {errors?.variant && !!errors?.variant?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.incident_period_start_date}>
            <FormLabel>Start of incident period</FormLabel>
            <Controller
              control={control}
              name="incident_period_start_date"
              rules={{
                required: false,
              }}
              defaultValue={new Date()}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  id="incident_period_start_date"
                  fixedHeight
                  shouldCloseOnSelect={true}
                  autoComplete="off"
                  customInput={<CreatedAtInput />}
                  onChange={onChange}
                  minDate={new Date()}
                  selected={value as unknown as Date}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.incident_period_start_date &&
                !!errors?.incident_period_start_date?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.incident_period_end_date}>
            <FormLabel>End date of incident period</FormLabel>
            <Controller
              control={control}
              name="incident_period_end_date"
              rules={{
                required: false,
              }}
              defaultValue={new Date()}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  id="incident_period_end_date"
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  fixedHeight
                  shouldCloseOnSelect={true}
                  autoComplete="off"
                  customInput={<CreatedAtInput />}
                  onChange={onChange}
                  minDate={new Date()}
                  selected={value as unknown as Date}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.incident_period_end_date &&
                !!errors?.incident_period_end_date?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.damaged_date}>
            <FormLabel>Date damaged</FormLabel>
            <Controller
              control={control}
              name="damaged_date"
              rules={{
                required: false,
              }}
              defaultValue={new Date()}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  id="damaged_date"
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  fixedHeight
                  shouldCloseOnSelect={true}
                  autoComplete="off"
                  customInput={<CreatedAtInput />}
                  onChange={onChange}
                  minDate={new Date()}
                  selected={value as unknown as Date}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.damaged_date && !!errors?.damaged_date?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.damage_cause_desc}>
            <FormLabel>Description of the cause of damage</FormLabel>
            <Input
              {...register(`damage_cause_desc`, {
                required: false,
              })}
              type="text"
              placeholder={"Description of the cause of damage"}
            />
            <FormErrorMessage>
              {errors?.damage_cause_desc &&
                !!errors?.damage_cause_desc?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.component_type}>
            <FormLabel>Component type</FormLabel>
            <Select
              {...register(`component_type`, {
                required: false,
              })}
            >
              <option value="wall">Wall</option>
              <option value="pavement">Pavement</option>
            </Select>
            <FormErrorMessage>
              {errors?.component_type && !!errors?.component_type?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.component_location}>
            <FormLabel>Component location</FormLabel>
            <Input
              {...register(`component_location`, {
                required: false,
              })}
              type="text"
              placeholder={"Component location"}
            />
            <FormErrorMessage>
              {errors?.component_location &&
                !!errors?.component_location?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.specification}>
            <FormLabel>
              Dimension, material, and capacity of the original component
            </FormLabel>
            <Input
              {...register(`specification`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Dimension, material, and capacity of the original component"
              }
            />
            <FormErrorMessage>
              {errors?.specification && !!errors?.specification?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.damage_dimensions}>
            <FormLabel>Dimensions of the damage</FormLabel>
            <Input
              {...register(`damage_dimensions`, {
                required: false,
              })}
              type="text"
              placeholder={"Dimensions of the damage"}
            />
            <FormErrorMessage>
              {errors?.damage_dimensions &&
                !!errors?.damage_dimensions?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.facility_sketch}>
            <FormLabel>Sketch of facility and damage</FormLabel>
            <ImageUploader
              description={"Sketch of facility and damage"}
              fileKey="facility_sketch"
              multiple={true}
              maxFiles={5}
            />
            <FormErrorMessage>
              {errors?.facility_sketch && !!errors?.facility_sketch?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.whole_area_images}>
            <FormLabel>Photographs of whole area</FormLabel>
            <FileUploader
              description={"Photographs of whole area"}
              fileKey="whole_area_images"
              multiple={true}
              uploadedFiles={
                femaFormData?.bridge_image ? [femaFormData.bridge_image] : []
              }
              maxFiles={5}
            />

            {/* <ImageUploader
              description={"Photographs of whole area"}
              fileKey="whole_area_images"
              multiple={true}
              uploadedFiles={femaFormData && femaFormData?.bridge_image}
            />
            <FormErrorMessage>
              {errors?.whole_area_images &&
                !!errors?.whole_area_images?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.damage_images}>
            <FormLabel>
              Photographs of damage from many (three plus) angles
            </FormLabel>

            <FileUploader
              description={
                "Photographs of damage from many (three plus) angles"
              }
              fileKey="damage_images"
              multiple={true}
              uploadedFiles={
                femaFormData?.damage_images
                  ? [...femaFormData?.damage_images]
                  : []
              }
              maxFiles={5}
            />
            <FormErrorMessage>
              {errors?.damage_images && !!errors?.damage_images?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.component_images}>
            <FormLabel>Closeup photographs of each component</FormLabel>

            <ImageUploader
              description={"Closeup photographs of each component"}
              fileKey="component_images"
              multiple={true}
              maxFiles={5}
            />

            {/* <Image src={showCloseUpImage} width={400} height={200} />
            <Input
              type="file"
              accept="image/*"
              onChange={(event) => handleChangeImage(event, "closeUp")}
              ref={closeUpInputRef}
              style={{ display: "none" }}
            />
            <ButtonGroup gap="8" mt={"4"}>
              <Button onClick={() => setIsOpenCloseUp(true)}>
                Capture Photo
              </Button>
              <Button onClick={() => closeUpInputRef?.current?.click()}>
                Upload Image
              </Button>
            </ButtonGroup> */}
            <FormErrorMessage>
              {errors?.component_images && !!errors?.component_images?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.cracks_images}>
            <FormLabel>
              Photographs of all rot, crumbling, cracks, or other wear
            </FormLabel>
            <ImageUploader
              description={
                "Photographs of all rot, crumbling, cracks, or other wear"
              }
              fileKey="cracks_images"
              multiple={true}
              maxFiles={5}
            />

            {/* <Image src={showOtherWearImage} width={400} height={200} />
            <Input
              type="file"
              accept="image/*"
              onChange={(event) => handleChangeImage(event, "otherWear")}
              ref={otherWearInputRef}
              style={{ display: "none" }}
            />
            <ButtonGroup gap="8" mt={"4"}>
              <Button onClick={() => setIsOpenOtherWear(true)}>
                Capture Photo
              </Button>
              <Button onClick={() => otherWearInputRef?.current?.click()}>
                Upload Image
              </Button>
            </ButtonGroup> */}

            <FormErrorMessage>
              {errors?.cracks_images && !!errors?.cracks_images?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.firmette}>
            <FormLabel>Firmette</FormLabel>
            <ImageUploader
              description={"Firmette"}
              fileKey="firmette"
              multiple={true}
              maxFiles={5}
            />
            <FormErrorMessage>
              {errors?.firmette && !!errors?.firmette?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.facility_map_images}>
            <FormLabel>
              Map of the facility with damage annotated Map or Google Earth
              screenshots of the surrounding area with the facility and damage
              annotated
            </FormLabel>
            <ImageUploader
              description={
                "Map of the facility with damage annotated Map or Google Earth screenshots of the surrounding area with the facility and damage annotated"
              }
              fileKey="facility_map_images"
              multiple={true}
              maxFiles={5}
            />
            <FormErrorMessage>
              {errors?.facility_map_images &&
                !!errors?.facility_map_images?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.repair_method}>
            <FormLabel>
              Method of Repair - Applicant's method of repair for each DI item
            </FormLabel>
            <Input
              {...register(`repair_method`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Method of Repair - Applicant's method of repair for each DI item"
              }
            />
            <FormErrorMessage>
              {errors?.repair_method && !!errors?.repair_method?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.work_performed}>
            <FormLabel>Who performed/will perform the work?</FormLabel>
            <Input
              {...register(`work_performed`, {
                required: false,
              })}
              type="text"
              placeholder={"Who performed/will perform the work?"}
            />
            <FormErrorMessage>
              {errors?.work_performed && !!errors?.work_performed?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.material_change}>
            <FormLabel>Change of material from original design?</FormLabel>
            <Input
              {...register(`material_change`, {
                required: false,
              })}
              type="text"
              placeholder={"Change of material from original design?"}
            />
            <FormErrorMessage>
              {errors?.material_change && !!errors?.material_change?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.size_change}>
            <FormLabel>Change in size/footprint?</FormLabel>
            <Input
              {...register(`size_change`, {
                required: false,
              })}
              type="text"
              placeholder={"Change in size/footprint?"}
            />
            <FormErrorMessage>
              {errors?.size_change && !!errors?.size_change?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.repair_comment}>
            <FormLabel>
              Other work/repair comments - If planning to change the design,
              size, or capacity, please provide plans and explain why.
            </FormLabel>
            <Input
              {...register(`repair_comment`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Other work/repair comments - If planning to change the design, size, or capacity, please provide plans and explain why."
              }
            />
            <FormErrorMessage>
              {errors?.repair_comment && !!errors?.repair_comment?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.ehp_issues}>
            <FormLabel>
              Are there EHP issues associated with the proposed scope of work?
              Explain.
            </FormLabel>
            <Input
              {...register(`ehp_issues`, {
                required: false,
              })}
              type="text"
              placeholder={
                "Are there EHP issues associated with the proposed scope of work? Explain."
              }
            />
            <FormErrorMessage>
              {errors?.ehp_issues && !!errors?.ehp_issues?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <CustomModal
        isOpen={isOpenCloseUp}
        onClose={closeModalCloseUp}
        title="Take a picture"
        buttonGroup={buttonGroupConfig}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={400}
          height={200}
        />
      </CustomModal>
      <CustomModal
        isOpen={isOpenOtherWear}
        onClose={closeModalOtherWear}
        title="Take a picture"
        buttonGroup={buttonGroupOtherWearConfig}
      >
        <Webcam
          audio={false}
          ref={webcamOtherWearRef}
          screenshotFormat="image/jpeg"
          width={400}
          height={200}
        />
      </CustomModal>
    </Stack>
  );
};

export default FemaForm;
