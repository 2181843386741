import {
  Avatar,
  // Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { setCurrentChatUser } from "actions/data/chat";
import ChatResource from "api/chat";
import InfrastructureResource from "api/infrastructures";
import OrganizationResource from "api/organization";
import { MulitSelectAll } from "components/common/MultiSelectAll";
import React, { useEffect, useState, useDeferredValue } from "react";
import { GiCheckMark } from "react-icons/gi";
import { MdGroupAdd } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { getFullName } from "utils/recon-command-center";

interface Props {
  name: string;
  attachment: string;
  company: string;
  showMessage: boolean;
  setShowMessages: any;
}

const ChatUserDetails: React.FC<Props> = ({ name, attachment, showMessage, setShowMessages }) => {
  const organizationAPI = new OrganizationResource();
  const chatAPI = new ChatResource();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [groupChat, setGroupChat] = useState(true);
  const [groupClose, setGroupClose] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isEndAlertModalOpen, setEndAlertModalOpen] = useState(false);
  const deferredShowMessage = useDeferredValue(showMessage);

  const { currentChatUserDetails, organizationDetail, loggedInUser } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
      organizationDetail: state?.data?.organization.organizationDetail,
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    setShowMessages(true);
    if (!currentChatUserDetails?.isPrimaryGroup) {
      setGroupChat(true);
      setGroupClose(false);
    }
  }, [
    currentChatUserDetails.conversation,
    setShowMessages,
    currentChatUserDetails?.isPrimaryGroup,
  ]);

  const { data: userlist } = useQuery(
    [`userListChat-${loggedInUser.organization_id ? Number(loggedInUser.organization_id) : 0}`],
    async () => {
      const response = await organizationAPI.organizationUsersList(
        loggedInUser.organization_id ? Number(loggedInUser.organization_id) : 0,
        {
          page: 1,
          page_size: 1000,
        }
      );

      return response.data.data.filter((item: any) => item.id !== loggedInUser.id);
    }
  );

  const userOptions = userlist?.map((user: { first_name: string; last_name: string; id: number }) => ({
    name: `${user.first_name} ${user.last_name}`,
    id: user.id,
  }));

  // const createNewPrivateChat = useMutation(
  //   (data: any) => chatAPI.createNewChat(data),
  //   {
  //     onSuccess: (res: any) => {
  //       queryClient.invalidateQueries("chatList");
  //       dispatch(
  //         setCurrentChatUser({
  //           name: !!res?.data?.data?.is_group ? name : getFullName(res.data.data),
  //           conversation: res?.data?.data?.conversation,
  //           attachment,
  //           default_group: res?.data?.data?.primary_group,
  //           isPrimaryGroup: res?.data?.data?.primary_group,
  //           isGroup: res?.data?.data?.is_group,
  //           isAlert: res?.data?.data?.is_alert,
  //           isAlertActive: res?.data?.data?.is_alert_active,
  //         })
  //       );
  //       setShowMessages(false);
  //     },
  //   }
  // );

  // const handleUserClick = (user: any) => {
  //   createNewPrivateChat.mutate({ receiver_id: user.id });
  // };

  const {mutate:createNewGroupChats} = useMutation(
    (data: any) => chatAPI.createNewGroupChat(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
        dispatch(
          setCurrentChatUser({
            name: res?.data?.data?.name,
            conversation: res?.data?.data?.conversation,
            attachment,
            default_group: res?.data?.data?.primary_group,
            isPrimaryGroup: res?.data?.data?.primary_group,
            isGroup: res?.data?.data?.is_group,
            isAlert: res?.data?.data?.is_alert,
            isAlertActive: res?.data?.data?.is_alert_active,
          })
        );
        queryClient.invalidateQueries("messageList");
      },
    }
  );

  const addRemoveMemberGroupChat = useMutation(
    (data: any) => chatAPI.addRemoveMemberGroupChat(currentChatUserDetails.conversation, data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
        dispatch(
          setCurrentChatUser({
            name: res?.data?.data?.name,
            conversation: res?.data?.data?.conversation,
            attachment: res?.data?.data?.attachment,
            default_group: res?.data?.data?.primary_group,
          })
        );
      },
    }
  );

  const handleGroupView = async (type: string) => {
    if (type === "groupChat") {
      setGroupClose(true);
      setGroupChat(false);
      const response = await chatAPI.getMemberGroupChat(currentChatUserDetails?.conversation);
      setSelectedUser(response?.data.filter((res: { id: number }) => res?.id !== loggedInUser?.id));
    }
    if (type === "groupClose") {
      const users = selectedUser.map((user: { id: number }) => user.id);
      if (users.length > 0) {
        if (!currentChatUserDetails.isGroup) {
          createNewGroupChats({ receiver_ids: users });
        } else {
          addRemoveMemberGroupChat.mutate({ users });
        }
      }
      setGroupClose(false);
      setGroupChat(true);
    }
  };

  const toast = useToast();

  const infrastructureResource = new InfrastructureResource();

  const endAlertMutation = useMutation(
    (data: any) => infrastructureResource.endAlert(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("organizationInfrastructureList");
        queryClient.invalidateQueries("alertDataQuery");
        toast({
          title: "Alert ended",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setEndAlertModalOpen(false);
      },
      onError: (err: any) => {
        toast({
          title: err?.response?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onEndAlertConfirm = () => {
    endAlertMutation.mutate({});
  };

  return (
    <>
      {deferredShowMessage && (
        <Stack p="4" bg="white" shadow="box" rounded="md">
          <Flex justify={"space-between"}>
            <Stack direction="row" spacing="6">
              <Avatar size="lg" showBorder shadow="lg" src={attachment} name={name} />
              <Stack direction="column" spacing="3">
                <Heading size="sm" fontWeight="medium" pt="4">
                  {name}{" "}
                  {!!currentChatUserDetails.default_group ? "" : `(${organizationDetail.org_name})`}
                </Heading>
              </Stack>
            </Stack>
            <Stack>
              {groupChat && !currentChatUserDetails?.isPrimaryGroup && (
                <Stack direction="row">
                  <IconButton
                    variant={"unstyled"}
                    size="lg"
                    aria-label="GroupChat"
                    onClick={() => handleGroupView("groupChat")}
                    icon={<MdGroupAdd />}
                  />
                  {currentChatUserDetails?.isAlertActive && (
                    <IconButton
                      variant={"unstyled"}
                      size="lg"
                      aria-label="End Alert"
                      onClick={() => setEndAlertModalOpen(true)}
                      icon={<GiCheckMark />}
                    />
                  )}
                </Stack>
              )}
              {groupClose && !currentChatUserDetails?.isPrimaryGroup && (
                <Stack direction="row" w={"300px"}>
                  <MulitSelectAll
                    options={userOptions}
                    placeholder={"Select User"}
                    arrayValue={selectedUser}
                    setArrayValue={setSelectedUser}
                  />
                  <IconButton
                    variant={"unstyled"}
                    size="lg"
                    aria-label="Add"
                    onClick={() => handleGroupView("groupClose")}
                    icon={<GiCheckMark />}
                  />
                </Stack>
              )}
            </Stack>
          </Flex>
        </Stack>
      )}
      <Modal isOpen={isEndAlertModalOpen} isCentered onClose={() => setEndAlertModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>End Alert</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>Are you sure you want to end this alert?</Text>
              <Center>
                <ButtonGroup size="sm">
                  <Button variant="outline" onClick={() => setEndAlertModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={onEndAlertConfirm}>
                    Confirm
                  </Button>
                </ButtonGroup>
              </Center>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChatUserDetails;
