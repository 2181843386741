import Resource from "api/resource";
import http from "utils/http";

class OrganizationResource extends Resource {
  constructor() {
    super("organizations");
  }

  organizationUsersList(organiztionId?: Number, query?: any) {
    return http({
      url: `/organizations/${organiztionId}/users/`,
      method: "get",
      params: query,
    });
  }

  organizationInfrastructureList(organiztionId?: Number, query?: any) {
    return http({
      url: `/organizations/${organiztionId}/infrastructures-list/`,
      method: "get",
      params: query,
    });
  }

  organizationHex(organiztionId?: Number, url?: any) {
    return http({
      url: `/organizations/${organiztionId}/`,
      method: "get",
      // params: url,
    });
  }

  getchartView(organiztionId: Number) {
    return http({
      url: `/organizations/${organiztionId}/infrastructure/status/`,
      method: "get",
    });
  }
}

export { OrganizationResource as default };
