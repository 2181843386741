import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Progress,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { FaRegFileAlt } from "react-icons/fa";
import { FiUploadCloud } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { truncateString } from "utils";
import http from "utils/http";

interface Props {
  title?: string;
  description?: string;
  uploadedFiles?: any;
  fileKey?: any;
  required?: boolean;
  multiple?: boolean;
  accept?: any;
  setUploadDetail?: any;
  setIsFileRemoved?: any;
  maxFiles?: number;
}
const Uploader: React.FC<Props> = (props) => {
  const {
    title = "Upload Files",
    description = "Upload File",
    uploadedFiles,
    fileKey = "file_doc",
    multiple = false,
    accept = "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
    setUploadDetail,
    setIsFileRemoved,
    maxFiles = 1,
  } = props;

  const toast = useToast();
  const [currentFile, setCurrentFile] = useState("");
  const [myFiles, setMyFiles] = useState<any>([]);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [previewFile, setPreviewFile] = useState<any>();
  const { setValue } = useFormContext();
  const [uploadStatus, setUploadStatus] = useState<string>("empty");

  useEffect(() => {
    setCurrentFile(uploadedFiles ? uploadedFiles : "");
  }, [uploadedFiles]);

  const uploadProgressHandler = (progressEvent: any) => {
    let percent = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercentage(percent);
  };

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length > 0) {
        let title = "Maximum upload attachments size upto 15 MB.";
        if (fileRejections[0]?.errors[0]?.code === "too-many-files") {
          title = `Only ${maxFiles} file is allowed.`;
        }
        toast({
          position: "top",
          title: title,
          status: "error",
          isClosable: true,
        });
      }
      if (acceptedFiles) {
        if (acceptedFiles[0].type !== "application/zip"&& acceptedFiles[0].type !== "application/json") {
       
       
       console.log(acceptedFiles[0].type !== "application/zip"&& acceptedFiles[0].type !== "application/json")
          toast({
            position: "top",
            title: "Uploaded file is not zip file",
            status: "error",
            isClosable: true,
          });
          return;
        }
        setMyFiles(acceptedFiles);
        setUploadStatus("uploaded");
        setCurrentFile("");

        //the snippet below will be used for loader
        let attachementData = new FormData();
        if (acceptedFiles) attachementData.append(fileKey, acceptedFiles[0]);
        //TODO: let cancleUpload: any;
        if (attachementData) {
          // setLoading(true);
          http({
            url: "files/",
            method: "POST",
            data: attachementData,
            onUploadProgress: uploadProgressHandler,
            headers: {
              "content-type": "multipart/form-data",
            },
          })
            .then((res) => {
              setUploadDetail(res.data);
            })
            .catch((err) => {
              if (axios.isCancel(err)) return;
              toast({
                position: "top",
                title: "Unable to upload file to server",
                status: "error",
                isClosable: true,
              });
              setUploadStatus("empty");
              setMyFiles([]);
              setPreviewFile("");
              setUploadPercentage(0);
              setCurrentFile("");
              setUploadDetail();

              setIsFileRemoved(false);
            });
        }
        return () => {
          setUploadPercentage(0);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, fileKey, setUploadDetail, setIsFileRemoved] // IF ERROR: fileKey and setUploaderDetail was added later
  );

  const loadScreen = () => {
    if (uploadStatus && uploadStatus === "empty" && currentFile === "") {
      return (
        <Center
          {...getRootProps()}
          cursor="pointer"
          w="100%"
          h="160px"
          bg="gray.50"
          overflow="hidden"
          objectFit="cover"
          shadow="box"
          rounded="sm"
          border="2px"
          borderStyle="dashed"
          borderColor="gray.100"
        >
          <input {...getInputProps()} />
          {getPreview()}
        </Center>
      );
    } else if (
      (currentFile && currentFile.length > 0) ||
      (uploadStatus && uploadStatus === "uploaded")
    )
      return (
        <Box
          {...getRootProps()}
          cursor="pointer"
          w="100%"
          h="66px"
          bg="gray.50"
          overflow="hidden"
          objectFit="cover"
          shadow="box"
          rounded="sm"
          border="2px"
          borderStyle="dashed"
          borderColor="gray.100"
        >
          {getPreview()}
        </Box>
      );
  };

  React.useMemo(() => {
    if (myFiles.length !== 0) {
      setValue(fileKey, myFiles[0]);
      setPreviewFile(URL.createObjectURL(myFiles[0]));
    }
  }, [fileKey, myFiles, setValue]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 25) {
      //Limit 25 MB
      return {
        code: "file-size-too-large",
        message: "Maximum upload attachments size upto 25 MB.",
      };
    }

    return null;
  };
  const getPreview = () => {
    if (previewFile || currentFile) {
      return (
        <Flex
          justify={"space-between"}
          p={4}
          alignItems="center"
          flexWrap={"wrap"}
        >
          <Flex>
            <FaRegFileAlt style={{ marginTop: "4px" }} />
            <Text color={"#8B8B8B"} ml={1}>
              {uploadPercentage < 100 && !currentFile
                ? truncateString(
                    currentFile ? currentFile : myFiles[0].name,
                    10
                  )
                : truncateString(
                    currentFile ? currentFile : myFiles[0].name,
                    30
                  )}
            </Text>
          </Flex>
          {uploadPercentage < 100 && !currentFile && (
            <Progress
              value={uploadPercentage}
              width={"25%"}
              colorScheme={"teal"}
            />
          )}
          <Button
            border={"none"}
            background={"none"}
            p={0}
            color={"#000000"}
            cursor={"pointer"}
            onClick={() => {
              setUploadStatus("empty");
              setMyFiles([]);
              setPreviewFile("");
              setUploadPercentage(0);
              setCurrentFile("");
              setUploadDetail();
              setIsFileRemoved(true);
            }}
            _hover={{ background: "none" }}
          >
            <IoCloseSharp />
          </Button>
        </Flex>
      );
    }

    return (
      <VStack>
        <Icon as={FiUploadCloud} h="16" w="16" color="gray.300" />
        {isDragActive ? (
          <Text textAlign="center" color="gray.400">
            {title}
          </Text>
        ) : (
          <>
            <Text textAlign="center" color="gray.400">
              {description}
            </Text>
            <Stack>
              <Button
                size="sm"
                colorScheme="primary"
                backgroundColor={"#FFFFFF"}
                border={"2px"}
                borderColor={"#616A72"}
                color={"#383861"}
                rounded={"md"}
              >
                Select File
              </Button>
            </Stack>
          </>
        )}
      </VStack>
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    onDrop,
    maxFiles: maxFiles,
    validator: fileSizeValidator,
    multiple: multiple,
  });

  return <>{loadScreen()}</>;
};

export default Uploader;
