// @ts-nocheck
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import { BridgeSymbol, customPopup } from "./MapConstants";

import store from "store";


export const getGeoJsonLayerBridge = (BridgeList: any) => {
  const bridgeBlob = new Blob([JSON.stringify(BridgeList)], {
    type: "application/json",
  });
  const urlbridge = URL.createObjectURL(bridgeBlob);

  const BridgeLayer = new GeoJSONLayer({
    // url: "https://services7.arcgis.com/NLjNvtlX2BYq9yBK/arcgis/rest/services/bridge/FeatureServer",
    url: urlbridge,
    editingEnabled: true,
    screenSizePerspectiveEnabled: true,

    opacity: 1,
    minScale: 325500,
    renderer: BridgeSymbol,
    popupTemplate: customPopup,
  });

  return { BridgeLayer };
};

export const getGeoJsonLayer = (hexUrl: string) => {
  console.log(hexUrl,'New-hexagons-Last',hexUrl)
  // const bridgeBlob = new Blob([JSON.stringify(Hexagon)], {
  //   type: "application/json",
  // });
  const state=store.getState()
   
  console.log(state.data.organization.organizationDetail.hex_file,'hex url in getGeoJsonLayer')
  // const url = URL.createObjectURL(state.data.organization.organizationDetail.hex_file);

  const tessalationLayer = new GeoJSONLayer({
    // url:'http://172.190.49.166/media/hex_files/grid_1_Bc9wM66.json ',
url:state.data.organization.organizationDetail.hex_file,

    // url: "https://services7.arcgis.com/NLjNvtlX2BYq9yBK/arcgis/rest/services/bridge/FeatureServer",

    opacity: 0.5,
    // renderer: renderer,
    screenSizePerspectiveEnabled: true,

    visible: true,
    popupEnabled: true,
    maxScale: 8,
  });

  return { tessalationLayer };
};
