/* eslint-disable import/no-anonymous-default-export */
import { OrganizationAction, OrganizationActionTypes } from "actions/data/organization";

const INITIAL_STATE = {
  organizationDetail: {},
};

export default function (organizationState = INITIAL_STATE, action: OrganizationAction) {
  switch (action.type) {
    case OrganizationActionTypes.SET_ORGANIZATION:
      return {
        ...organizationState,
        organizationDetail: action.data,
      };

    default:
      return organizationState;
  }
}
