import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InfrastructureResource from "api/infrastructures";
import { MAP_FILTERS } from "constants/common";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdOutlineRestartAlt } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import BridgeListCard from "./BridgeListCard";

const BridgeList: any = ({
  showLocation,
  infrastructureLists,
}: {
  showLocation: any;
  infrastructureLists: any;
}) => {
  const [infrastructureList, setInfrastructureList] =
    useState(infrastructureLists);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    if (infrastructureLists?.features.length > 0) {
      let data = [];
      const newInfraList = { ...infrastructureLists };

      if (searchKeyword === "" && selectedStatus === "") {
        return setInfrastructureList(infrastructureLists);
      }

      if (searchKeyword.length > 0 && selectedStatus.length > 0) {
        data = infrastructureLists.features.filter(
          (infrastructure: any) =>
            infrastructure.properties.bridge_name
              .toLocaleLowerCase()
              .includes(searchKeyword.toLocaleLowerCase()) &&
            infrastructure.properties.infrastructure_status.toLocaleLowerCase() ===
              selectedStatus.toLocaleLowerCase()
        );
      }

      if (searchKeyword.length > 0 && selectedStatus === "") {
        data = infrastructureLists.features.filter((infrastructure: any) =>
          infrastructure.properties.bridge_name
            .toLocaleLowerCase()
            .includes(searchKeyword.toLocaleLowerCase())
        );
      }

      if (searchKeyword === "" && selectedStatus.length > 0) {
        data = infrastructureLists.features.filter(
          (infrastructure: any) =>
            infrastructure.properties.infrastructure_status.toLocaleLowerCase() ===
            selectedStatus.toLocaleLowerCase()
        );
      }
      newInfraList.features = data;
      setInfrastructureList(newInfraList);
    } else {
      setInfrastructureList([]);
    }
    // eslint-disable-next-line
  }, [selectedStatus, searchKeyword]);

  const [isResetStatusModalOpen, setResetStatusModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const infrastructureAPI = new InfrastructureResource();
  const toast = useToast();

  const resetStatusMutation = useMutation(
    () => infrastructureAPI.organizationReset(),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("organizationInfrastructureList");
        toast({
          title: "Infrastructure status reset successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        // const objKey: any = Object.keys(error?.response?.data);
        // if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
        // else setErrorMessage('Company Creation Failed.');
      },
    }
  );

  const onResetStatusConfirm = () => {
    resetStatusMutation.mutate();
  };

  return (
    <Flex
      bgColor="blackAlpha.50"
      justifyContent={"flex-start"}
      align="center"
      pt="13"
      flexDir={"column"}
      className={"map-sidebar-wrap"}
    >
      <InputGroup
        bgColor={"#fff"}
        borderRadius="6"
        w={"95%"}
        h="min"
        py="5px"
        display="flex"
      >
        <InputLeftElement
          pointerEvents="none"
          color="#333333"
          fontSize="1em"
          alignSelf={"center"}
          position="relative"
          children={<FiSearch />}
        />
        <Input
          placeholder="Search"
          color="#333333"
          _placeholder={{ opacity: 1, color: "#333333" }}
          borderWidth="0"
          h={"40px"}
          padding="0"
          focusBorderColor="null"
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </InputGroup>
      <Box minHeight={"30px"} />
      <Box w={"95%"}>
        <Select
          variant="outline"
          placeholder="Filter"
          bgColor={"white"}
          w="40%"
          h={"40px"}
          borderWidth="1px"
          borderColor={"#44474A"}
          onChange={(e: any) => setSelectedStatus(e.target.value)}
        >
          {MAP_FILTERS.map((item) => (
            <option value={item.id} key={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </Box>
      <Box minHeight={"20px"} />
      <Box maxH={"calc(100vh - 30vh)"} overflowY={"scroll"} ml="5px">
        <BridgeListCard
          data={infrastructureList}
          showLocation={(e: any, f: any) => showLocation(e, f)}
        />
        <Button
          style={{
            width: "20%",
            bottom: "0px",
            paddingTop: "21px",
            paddingBottom: "21px",
            paddingRight: "130px",
            position: "absolute",
          }}
          leftIcon={<MdOutlineRestartAlt />}
          onClick={() => setResetStatusModalOpen(true)}
        >
          Reset status
        </Button>
      </Box>
      {/* Reset Status Modal Box */}
      <Modal
        isOpen={isResetStatusModalOpen}
        isCentered
        onClose={() => setResetStatusModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Center>
              <VStack>
                <IconButton
                  variant={"unstyled"}
                  aria-label="ResetChat"
                  icon={<MdOutlineRestartAlt />}
                />
                <Text color={"primary"}>
                  Are you sure you wold like to reset all?
                </Text>
                <ButtonGroup mt={"1.5rem !important"}>
                  <Button
                    color="#44627B"
                    onClick={onResetStatusConfirm}
                    isLoading={resetStatusMutation.isLoading}
                  >
                    {"Reset"}
                  </Button>

                  <Button
                    variant="outline"
                    onClick={() => setResetStatusModalOpen(false)}
                  >
                    {"Cancel"}
                  </Button>
                </ButtonGroup>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default BridgeList;
