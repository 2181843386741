export const nameDetails = {
  first_name: "Sushan",
  last_name: "Shrestha",
  full_name: "Sushan Shrestha",
  email: "sushan.shr100@gmail.com",
};

export const columns = [
  {
    label: "Name",
    accessor: "first_name",
    sortByOrder: "asc",
    sortable: false,
  },
  { label: "Email", accessor: "email", sortByOrder: "asc", sortable: false },
  {
    label: "Status",
    accessor: "is_active",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Date Added",
    accessor: "date_joined",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const dataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 6,
    total: 6,
  },
  data: [
    {
      id: 1,
      first_name: "sushan",
      last_name: "shrestha",
      email: "sushan.shr100@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-11"),
    },
    {
      id: 2,
      first_name: "sushan1",
      last_name: "shrestha1",
      email: "sushan.shr1000@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-13"),
    },
    {
      id: 3,
      first_name: "sushan2",
      last_name: "shrestha2",
      email: "sushan.shr10000@gmail.com",
      status: "invited",
      createdAt: new Date("2022-10-15"),
    },
    {
      id: 4,
      first_name: "abc",
      last_name: "xyz",
      email: "abc@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-20"),
    },
    {
      id: 5,
      first_name: "cde",
      last_name: "edc",
      email: "cde@gmail.com",
      status: "active",
      createdAt: new Date("2022-12-13"),
    },
    {
      id: 6,
      first_name: "efg",
      last_name: "gfe",
      email: "efg@gmail.com",
      status: "active",
      createdAt: new Date("2023-01-13"),
    },
  ],
};

export const organizationListColumns = [
  {
    label: "Organization Name",
    accessor: "org_name",
    sortByOrder: "asc",
    sortable: false,
  },
  { label: "Users", accessor: "users", sortByOrder: "asc", sortable: false },
  {
    label: "Infrastructures",
    accessor: "infrastructure",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const organizationDataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 6,
    total: 6,
  },
  data: [
    {
      id: 1,
      org_name: "ABC company",
      users: 10,
      upload: "abc.zip",
      infrastructures: 10,
    },
    {
      id: 2,
      org_name: "ABC2 company",
      users: 100,
      infrastructures: 300,
    },
    {
      id: 3,
      org_name: "ABC3 company",
      users: 110,
      upload: "acc.zip",
      infrastructures: 350,
    },
    {
      id: 4,
      org_name: "ABC4 company",
      users: 99,
      infrastructures: 500,
    },
    {
      id: 5,
      org_name: "XYZ company",
      upload: "acrc.zip",
      users: 510,
      infrastructures: 1250,
    },
    {
      id: 6,
      org_name: "DAC company",
      users: 90,
      infrastructures: 650,
    },
  ],
};

export const assessmentListColumns = [
  {
    label: "Infrastructure Number",
    accessor: "infrastructure_number",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Infrastructure Name",
    accessor: "bridge_name",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Infrastructure Status",
    accessor: "infrastructure_status",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Event Type",
    accessor: "event_type",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Assessment Date",
    accessor: "assessment_date",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Next Assessment",
    accessor: "next_assessment_date",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const assessmentDataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 4,
    total: 4,
  },
  data: [
    {
      id: 1,
      infrastructure_number: "12345664",
      infrastructure_name: "Brige Name 1",
      status: "Severe",
      event_type: "Timed",
      assessment_date: new Date("2022-12-13"),
      next_date: new Date("2023-12-13"),
    },
    {
      id: 2,
      infrastructure_number: "12345665",
      infrastructure_name: "Brige Name 2",
      status: "No Damange",
      event_type: "Emergency",
      assessment_date: new Date("2022-12-13"),
      next_date: new Date("2023-6-13"),
    },
    {
      id: 3,
      infrastructure_number: "12345614",
      infrastructure_name: "Brige Name 3",
      status: "Moderate",
      event_type: "Timed",
      assessment_date: new Date("2022-12-13"),
      next_date: new Date("2023-12-13"),
    },
    {
      id: 4,
      infrastructure_number: "12345669",
      infrastructure_name: "Brige Name 4",
      status: "Minor",
      event_type: "Emergency",
      assessment_date: new Date("2022-12-13"),
      next_date: new Date("2023-6-23"),
    },
  ],
};

export const infrastructureAssessmentListColumns = [
  {
    label: "Assessment Date",
    accessor: "assessment_date",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Infrastructure Status",
    accessor: "status",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Event Type",
    accessor: "event_type",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Inspected By",
    accessor: "inspected_by",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "FEMA",
    accessor: "fema",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const infrastructureEditHistoryListColumns = [
  {
    label: "Changed by",
    accessor: "user",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "From",
    accessor: "from",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "To",
    accessor: "to",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Time and Date",
    accessor: "createdAt",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const infrastructureAssessmentDataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 4,
    total: 4,
  },
  data: [
    {
      id: 1,
      inspected_by: "Sushan1",
      status: "Severe",
      event_type: "Timed",
      assessment_date: new Date("2022-12-13"),
    },
    {
      id: 2,
      status: "No Damange",
      event_type: "Emergency",
      assessment_date: new Date("2022-12-13"),
      inspected_by: "Sushan1",
    },
    {
      id: 3,
      status: "Moderate",
      event_type: "Timed",
      assessment_date: new Date("2022-12-13"),
      inspected_by: "Sushan2",
    },
    {
      id: 4,
      status: "Minor",
      event_type: "Emergency",
      assessment_date: new Date("2022-12-13"),
      inspected_by: "Sushan3",
    },
  ],
};

export const infrastructureEditedHistory = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 4,
    total: 4,
  },
  data: [
    {
      id: 1,
      from: "No Damage",
      to: "Minor",
      user: "sushan shrestha",
      createdAt: new Date("2023-01-31"),
    },
    {
      id: 2,
      from: "Minor",
      to: "No Damange",
      user: "sushan shrestha",
      createdAt: new Date("2023-02-01"),
    },
    {
      id: 3,
      from: "No Damage",
      to: "Moderate",
      user: "sushan shrestha",
      createdAt: new Date("2023-02-05"),
    },
    {
      id: 4,
      from: "Moderate",
      to: "Severe",
      user: "sushan shrestha",
      createdAt: new Date("2023-02-21"),
    },
  ],
};

export const currentChatUserDetail = {
  conversation: "first111",
  name: "Sushan Shrestha",
  attachment: "",
  company: "My First Organizations",
};

export const chatListItems = [
  {
    id: 2,
    first_name: "Sushan",
    last_name: "Shrestha",
    middle_name: "",
    is_seen: false,
    image: "",
    new_message_count: 3,
    conversation_id: "first111",
    last_message: "Hello Sushan",
  },
  {
    id: 3,
    first_name: "Sushan2",
    last_name: "Shrestha",
    middle_name: "",
    is_seen: true,
    image: "",
    new_message_count: 0,
    conversation_id: "first111",
    last_message: "Hello Sushan2",
  },
];

export const infrastructureStatuses: any = {
  DA: "Damaged",
  ND: "Not Damaged",
  DS: "Damage Suspected",
  PD: "Partially Damaged",
  DNS: "Damage Not Suspected",
  DSE: "Damage Seen",
  NDS: "No Damage Seen",
  PDS: "Partial Damage Seen",
};

export const damageLevels: any = {
  TI: "Timed",
  EM: "Emergency",
  EQ: "Earthquake",
  FL: "Flood",
  TR: "Training",
};

export const damageTypes: any = {
  AD: "Approach Damage",
  DD: "Deck Damage",
  SD: "Substructure Damage",
  SSD: "SuperStructure Damage",
};
