import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { damageLevels, infrastructureStatuses } from "constants/data";
import dayjs from "dayjs";
import React from "react";
import { DateFormatYMD } from "utils";
import { getFullName } from "utils/recon-command-center";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  line: {
    borderTop: "0.2px solid gray",
  },
  table: {
    display: "flex",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  overallDamage: {
    marginTop: 10,
    fontSize: 12,
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
  },
  title: {
    fontSize: 12,
  },
});

interface Props {
  bridgeDetail: any;
  assessmentList: any;
}

const InfraStructureAssessmentPDF: React.FC<Props> = (props) => {
  const { bridgeDetail, assessmentList } = props;

  return (
    <>
      <Document>
        <Page style={styles.body} size={"A4"}>
          <Text style={styles.header} fixed>
            Bridge {bridgeDetail?.structure}
          </Text>
          <Image
            style={{
              width: "189",
              height: "126",
              marginRight: "18",
            }}
            src={bridgeDetail?.image}
          />
          <Text style={styles.title}>
            Next Assessment: {DateFormatYMD(bridgeDetail?.next_assessment_date)}
          </Text>
          <Text style={styles.title}>
            Risk: {infrastructureStatuses[bridgeDetail?.infrastructure_status]}
          </Text>

          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <View
              style={[
                styles.tableRow,
                { backgroundColor: "#D2D6DE", padding: 6 },
              ]}
            >
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>ASSESSMENT DATE</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>INFRASTRUCTURE STATUS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>EVENT TYPE</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>INSPECTED BY</Text>
              </View>
            </View>
            {assessmentList?.map((data: any, index: number) => {
              return (
                <View style={styles.table} key={index}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {dayjs(data?.date).format("MM/DD/YY")}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {infrastructureStatuses[data?.damage_level]}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {damageLevels[data?.event_type]}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {getFullName(data?.user)}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        </Page>
      </Document>
    </>
  );
};

export default InfraStructureAssessmentPDF;
