import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { setCurrentChatUser, setUnreadCount } from "actions/data/chat";
import ChatResource from "api/chat";
import NotificationResource from "api/notification";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiBell } from "react-icons/bi";
import { IoCreateOutline } from "react-icons/io5";
import { MdOutlineRestartAlt } from "react-icons/md";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getFullName } from "utils/recon-command-center";
import ChatListItem from "./ChatListItem";

import OrganizationResource from "api/organization";

const ChatSearch: React.FC<{ setShowMessages: any }> = (props) => {
  const chatAPI = new ChatResource();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setShowMessages } = props;
  const [isResetChatModalOpen, setResetChatModalOpen] = useState(false);
  const [isPushModalOpen, setPushModalOpen] = useState(false);
  const notificationAPI = new NotificationResource();
  const methods = useForm<any>();
  const toast = useToast();
  const [openModalUserList, setOpenModalUserList] = useState(false);

  const organizationAPI = new OrganizationResource();

  const { data: chatItemList, isLoading: isListLoading } = useQuery(
    ["chatList"],
    async () => {
      const response = await chatAPI.list();

      dispatch(setUnreadCount(response?.data?.unread_count));

      return response?.data;
    }
  );

  const resetChatMutation = useMutation(() => chatAPI.resetChatStatus(), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("chatList");
      setResetChatModalOpen(false);
      dispatch(
        setCurrentChatUser({
          name: "",
          conversation: "",
          attachment: "",
          default_group: "",
        })
      );
    },
    onError: (error: any) => {
      // const objKey: any = Object.keys(error?.response?.data);
      // if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      // else setErrorMessage('Company Creation Failed.');
    },
  });
  const onResetChatConfirm = () => {
    resetChatMutation.mutate();
  };

  const onPushModalClose = () => {
    setPushModalOpen(false);
  };

  const startAlertMutation = useMutation(
    (data: any) => notificationAPI.storeNotification(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: `"Notification Sent"`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (error: any) => {},
    }
  );

  const onSubmitAlert = (data: any) => {
    setPushModalOpen(false);
    startAlertMutation.mutate({ title: "Alert", body: data?.alert });
  };

  const { loggedInUser } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
      organizationDetail: state?.data?.organization.organizationDetail,
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const { data: userlist } = useQuery(["userListChat"], async () => {
    const response = await organizationAPI.organizationUsersList(
      loggedInUser.organization_id ? Number(loggedInUser.organization_id) : 0,
      {
        page: 1,
        page_size: 1000,
      }
    );
    return response.data.data.filter(
      (item: any) => item.id !== loggedInUser.id
    );
  });

  const createNewPrivateChat = useMutation(
    (data: any) => chatAPI.createNewChat(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
        dispatch(
          setCurrentChatUser({
            name: getFullName(res.data.data),
            conversation: res?.data?.data?.conversation,
            attachment: res?.data?.data?.attachment,
            default_group: res?.data?.data?.primary_group,
            isPrimaryGroup: res?.data?.data?.primary_group,
            isGroup: res?.data?.data?.is_group,
            isAlert: res?.data?.data?.is_alert,
            isAlertActive: res?.data?.data?.is_alert_active,
          })
        );
        setShowMessages(false);
        setOpenModalUserList(false);
      },
      onError: (error: any) => {},
    }
  );

  const handleUserClick = (user: any) => {
    createNewPrivateChat.mutate({ receiver_id: user.id });
  };

  return (
    <>
      <Stack
        direction="column"
        spacing="6"
        maxW="300px"
        w={"100%"}
        bgColor={"#F9F9F9"}
      >
        <Box maxH="80vh" overflow="auto">
          <PerfectScrollbar>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              className="chat-header"
            >
              <Heading size="md" textTransform="capitalize">
                Chat
              </Heading>
              <Stack direction={"row"}>
                <BiBell
                  className="new-chat-icon"
                  onClick={() => {
                    setPushModalOpen(true);
                    methods.reset();
                  }}
                />
                <IoCreateOutline
                  className="new-chat-icon"
                  onClick={() => {
                    setShowMessages(false);
                    setOpenModalUserList(!openModalUserList);
                  }}
                />
              </Stack>
            </Flex>
            {!isListLoading && (
              <>
                <Stack direction="column" spacing="4" rounded="md">
                  {chatItemList?.data?.length > 0 &&
                    chatItemList?.data?.map((user: any) => (
                      <ChatListItem
                        key={user.id}
                        name={!!user.is_group ? user.name : getFullName(user)}
                        createdBy={user?.created_by}
                        attachment={user?.image}
                        isSeen={user?.is_seen}
                        unreadChatCount={user?.new_message_count}
                        conversation={user.conversation}
                        setShowMessages={setShowMessages}
                        lastMessage={user?.last_message}
                        isPrimaryGroup={user?.primary_group}
                        isGroup={user?.is_group}
                        isAlert={user?.is_alert}
                        isAlertActive={user?.is_alert_active}
                        participants={user?.participants}
                      />
                    ))}
                </Stack>
                <Stack>
                  <Button
                    leftIcon={<MdOutlineRestartAlt />}
                    onClick={() => setResetChatModalOpen(true)}
                  >
                    Reset chat
                  </Button>
                </Stack>
              </>
            )}
          </PerfectScrollbar>
        </Box>

        {/* Reset Chat Modal Box */}
        <Modal
          isOpen={isResetChatModalOpen}
          isCentered
          onClose={() => setResetChatModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <Center>
                <VStack>
                  <IconButton
                    variant={"unstyled"}
                    aria-label="ResetChat"
                    icon={<MdOutlineRestartAlt />}
                  />
                  <Text color={"primary"}>
                    Are you sure you wold like to reset all chats?
                  </Text>
                  <ButtonGroup mt={"1.5rem !important"}>
                    <Button
                      colorScheme="primary"
                      onClick={onResetChatConfirm}
                      isLoading={resetChatMutation.isLoading}
                    >
                      {"Reset"}
                    </Button>

                    <Button
                      variant="outline"
                      onClick={() => setResetChatModalOpen(false)}
                    >
                      {"Cancel"}
                    </Button>
                  </ButtonGroup>
                </VStack>
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* Push Notification */}
        <Modal isOpen={isPushModalOpen} isCentered onClose={onPushModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmitAlert)}>
                  <Center>
                    <VStack>
                      <IconButton
                        variant={"unstyled"}
                        aria-label="Notification"
                        icon={<BiBell />}
                      />
                      <Text color={"primary"} as={"b"}>
                        Create push notification.
                      </Text>
                      <Textarea
                        placeholder="Aa"
                        {...methods.register("alert")}
                        required
                      />
                      <Text color={"primary"} textAlign={"center"}>
                        when you create a push notification it will push a
                        notification directly to all organization members.
                      </Text>
                      <ButtonGroup mt={"1.5rem !important"}>
                        <Button
                          color="#44627B"
                          type="submit"
                          isLoading={startAlertMutation.isLoading}
                        >
                          {"Push"}
                        </Button>
                        <Button
                          variant="outline"
                          onClick={() => {
                            methods.reset();
                            setPushModalOpen(false);
                          }}
                        >
                          {"Cancel"}
                        </Button>
                      </ButtonGroup>
                    </VStack>
                  </Center>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Stack>
      {openModalUserList && (
        <Stack
          p="4"
          bg="white"
          shadow="box"
          rounded="md"
          position={"absolute"}
          marginLeft={"300px"}
          marginTop={"40px"}
          zIndex={"9"}
        >
          <Stack direction="row" spacing="6">
            <Box>
              <Text as={"b"} color={"gray"}>
                To:
              </Text>
              <Divider />
              <List>
                {userlist?.map((user: any, index: number) => {
                  return (
                    <ListItem
                      py={1}
                      key={index}
                      _hover={{ color: "gray", cursor: "pointer" }}
                      onClick={() => handleUserClick(user)}
                    >
                      <Flex>
                        <Avatar
                          size={"sm"}
                          showBorder
                          name={getFullName(user)}
                        />
                        <Center>{getFullName(user)}</Center>
                      </Flex>
                      <Divider />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ChatSearch;
