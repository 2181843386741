import React, { ReactElement } from "react";

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { damageTypes, infrastructureStatuses } from "constants/data";
import dayjs from "dayjs";
import { DateFormat } from "utils";
import { getFullName } from "utils/recon-command-center";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  line: {
    borderTop: "0.2px solid gray",
  },
  table: {
    display: "flex",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  overallDamage: {
    marginTop: 10,
    fontSize: 12,
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
  },
  title: {
    fontSize: 12,
  },
});

interface Props {
  assessmentDetail: any;
  assessmentList: any;
}

const InfraStructureAssessmentDetailPDF: React.FC<Props> = (props) => {
  const { assessmentDetail, assessmentList } = props;

  const getStatus = (currentStatus: string) => {
    let backgroundColor = "#ECA47B";
    switch (currentStatus) {
      case "DA":
        backgroundColor = "#FC6C72";
        break;
      case "ND":
        backgroundColor = "#8CB832";
        break;
      case "DS":
        backgroundColor = "#ECD37B";
        break;
      case "DNS":
        backgroundColor = "#AFAFAF";
        break;
      case "DSE":
        backgroundColor =
          "#repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px)";
        break;
      case "PDS":
        backgroundColor =
          "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px)";
        break;
      case "NDS":
        backgroundColor =
          "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px)";
        break;
      default:
        backgroundColor = "#ECA47B";
    }
    return (
      <Text
        style={{
          backgroundColor: backgroundColor,
          color: "#000",
          borderRadius: "lg",
          padding: 10,
        }}
      >
        {infrastructureStatuses[currentStatus]}
      </Text>
    );
  };

  const ImageGallery = (props: any) => {
    const { images } = props;
    return (
      <View
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {images.length > 0 &&
          images?.map((img: any, index: number) => {
            if (img)
              return (
                <React.Fragment key={index}>
                  <Image
                    style={{
                      width: "189px",
                      height: "126px",
                    }}
                    src={img}
                  />
                  <br />
                </React.Fragment>
              );
            return null;
          })}
      </View>
    );
  };

  const DamageSection = ({
    title,
    children,
    buttonData,
    images = [],
    notes = [],
    measurements = [],
  }: {
    title: String;
    children?: ReactElement;
    buttonData?: { status: String | null | boolean };
    images?: String[];
    measurements?: any;
    notes?: String[];
  }) => {
    return (
      <View>
        <Text
          style={{
            color: "black",
            fontSize: "13px",
            marginTop: "5px",
          }}
        >
          {title}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "6px",
            marginTop: "30px",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <Text
              style={{
                backgroundColor:
                  buttonData?.status === "ND"
                    ? "#8CB832"
                    : buttonData?.status === true
                    ? "#8CB832"
                    : "#9C9C9C",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  display: "flex",
                  fontSize: "10px",
                }}
              >
                {"No Damage"}
              </Text>
            </Text>
            <Text
              style={{
                backgroundColor:
                  buttonData?.status === "PD"
                    ? "#ECD37B"
                    : buttonData?.status === true
                    ? "#ECD37B"
                    : "#D1D1D1",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: "10px",
                }}
              >
                Partial Damage
              </Text>
            </Text>
            <Text
              style={{
                backgroundColor:
                  buttonData?.status === "DA"
                    ? "#FC6C72"
                    : buttonData?.status === true
                    ? "#FC6C72"
                    : "#989898",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: "10px",
                }}
              >
                Severe
              </Text>
            </Text>
          </View>
        </View>

        {images && images.length > 0 && <ImageGallery images={images} />}

        {measurements && measurements.length > 0 && (
          <ImageGalleryMeasurement measurements={measurements} />
        )}

        {notes?.length > 0 &&
          notes?.map((note: any, index: number) => {
            return (
              <View key={index}>
                <NoteBox heading={`Note ${index}`} desc={note} />
              </View>
            );
          })}
      </View>
    );
  };

  const NoteBox = ({ heading, desc }: { heading?: string; desc: string }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          borderWidth: "1px",
          borderColor: "#DEDEDE",
          borderRadius: "8px",
          padding: "18px",
          marginTop: 2,
        }}
      >
        <Text
          style={{
            color: "#8B8B8B",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          {heading}
        </Text>
        <Text
          style={{
            color: "#8B8B8B",
            fontWeight: "semibold",
            fontSize: "12px",
          }}
        >
          {desc}
        </Text>
      </View>
    );
  };

  const ImageGalleryMeasurement = (props: any) => {
    const { measurements } = props;
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {measurements &&
          measurements?.map((measurement: any, index: number) => {
            if (measurement)
              return (
                <React.Fragment key={index}>
                  <Image
                    style={{
                      width: "189px",
                      height: "126px",
                      marginRight: "18px",
                    }}
                    src={measurement?.image}
                  />
                  <br />
                </React.Fragment>
              );
            return null;
          })}
      </View>
    );
  };

  return (
    <>
      <Document>
        <Page style={styles.body} size={"A4"}>
          <Text style={styles.header}>
            Bridge {assessmentDetail?.infrastructure_number} Assessment{" "}
            {DateFormat(assessmentDetail?.date)}
          </Text>
          <Text style={styles.title}>
            Inspection By: {getFullName(assessmentDetail?.user)}
          </Text>
          <Text style={styles.title}>
            Inspection Time & Date:{" "}
            {dayjs(assessmentDetail?.date).format("MMM D, YYYY h:mm A")}
          </Text>

          <View style={{ marginTop: "20px" }}>
            <DamageSection
              title="Overall Damage"
              buttonData={{ status: assessmentDetail?.damage_level }}
            />
          </View>
          <View style={{ marginTop: "20px" }}>
            {assessmentDetail?.damages?.map((damage: any, index: number) => {
              return (
                <DamageSection
                  title={damageTypes[damage?.type]}
                  key={index}
                  buttonData={{ status: damage?.status }}
                  images={damage.images}
                  notes={damage?.notes}
                  measurements={damage?.measurements}
                />
              );
            })}
          </View>

          {assessmentDetail?.additional_notes && (
            <View style={{ marginTop: "20px" }}>
              <Text style={{ color: "#000" }}>Additional Notes</Text>
              <View>
                <Text
                  style={{
                    color: "#8B8B8B",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {assessmentDetail?.additional_notes}
                </Text>
                <Text
                  style={{
                    color: "#8B8B8B",
                    fontWeight: "semibold",
                    fontSize: "12px",
                  }}
                ></Text>
              </View>
            </View>
          )}
          <View style={{ marginTop: "20px" }}>
            <View
              style={[
                styles.tableRow,
                { backgroundColor: "#D2D6DE", padding: 6 },
              ]}
            >
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>CHANGED BY</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>FROM</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TO</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TIME AND DATE</Text>
              </View>
            </View>
            {assessmentList?.map((data: any, index: number) => {
              const prevStatus =
                index === assessmentList.length - 1
                  ? "ND"
                  : assessmentList[index + 1].damage_level;

              return (
                <View style={styles.table} key={index}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {getFullName(data?.user)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {getStatus(prevStatus)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {getStatus(data?.damage_level)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {dayjs(data?.date).format("MM/DD/YY hh:mm A")}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        </Page>
      </Document>
    </>
  );
};

export default InfraStructureAssessmentDetailPDF;
