import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Logo } from "assets/images";
import ChatRegister from "components/chat/ChatRegister";
import CustomToast from "components/common/CustomToast";
import { withAuthState } from "components/hoc/auth";
import routes from "constants/routes";
import { MessagePayload, onMessage } from "firebase/messaging";
import { messaging } from "FirebaseConfig";
import React, { useEffect } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  logout: () => void;
}
interface Props {
  logout: () => void;
}

const Nav: React.FC<Props> = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const { user, unreadCount } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
      unreadCount: state?.data?.chat.unreadCount,
    }),
    shallowEqual
  );

  const { logout } = props;

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(routes.organization.edit.replace(":id", user.organization_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, user]);

  (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        toast({
          position: "top-right",
          render: () => (
            <CustomToast
              title={payload?.notification?.title || ""}
              description={payload?.notification?.body || ""}
            />
          ),
        });
      });
    }
  })();

  return (
    <>
      <Box bg={"#44474A"} px={4} className="header--wrap">
        <ChatRegister/>
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          color={"#fff"}
        >
          <Flex className={"left-content-wrapper"}>
            <HStack spacing={8} alignItems={"center"}>
              <Box>
                <Image
                  src={Logo}
                  alt={"Recon"}
                  w={[56, 60, 60, 72]}
                  className="logo-wrap"
                />
              </Box>
            </HStack>
          </Flex>
          <Flex className="right-content-wrapper" alignItems={"center"}>
            <Menu>
              <HStack spacing={8} alignItems={"center"}>
                <Text fontWeight={600}>Howdy, {user.first_name}.</Text>
                <Stack
                  justify={"center"}
                  align={"center"}
                  onClick={() => {
                    navigate(routes.chats.list);
                  }}
                >
                  {unreadCount > 0 && (
                    <Badge
                      fontSize={8}
                      color="white"
                      // top={0}
                      right={"-90%"}
                      top={-2}
                      width={18}
                      height={18}
                      display="flex"
                      backgroundColor="#56AAC3"
                      borderRadius={"30"}
                      position={"relative"}
                      textAlign={"center"}
                      justifyContent="center"
                      alignItems={"center"}
                      zIndex={2}
                    >
                      {unreadCount}
                    </Badge>
                  )}
                  <Stack
                    borderWidth={1}
                    borderColor="white"
                    borderRadius={30}
                    width={30}
                    height={30}
                    align="center"
                    justify="center"
                    alignSelf={"center"}
                    position={"absolute"}
                    mt={"0"}
                    className="email-wrapper"
                  >
                    <FiMail></FiMail>
                  </Stack>
                </Stack>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={""}
                    name={`${user.first_name} ${user.last_name}`}
                    border={"1px solid white"}
                    background={"transparent"}
                  ></Avatar>
                </MenuButton>
              </HStack>
              <MenuList color={"#000"}>
                <MenuItem
                  onClick={() => {
                    navigate(routes.settings.index);
                  }}
                  className="setting-dropdown-menu"
                >
                  <Center px={"4"}>
                    <Text aria-label={"Settings"} color="blue.300">
                      <AiOutlineSetting />
                    </Text>
                    Settings
                  </Center>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={onLogoutClick}
                  className="logout-dropdown-menu"
                >
                  <Text aria-label={"Settings"} color="blue.300">
                    <BiLogOut />
                  </Text>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default withAuthState(Nav);
