import React, { createContext, useEffect, useMemo, useState, ReactNode, memo } from 'react';

interface ThemeContextInterface {
  theme: string;
  setTheme?: (theme: string) => void;
}

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<ThemeContextInterface>({
  theme: 'default',
});

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>('theme-cyan');

  useEffect(() => {
    // Remove previous theme class if it exists
    const previousTheme = document.body.className;
    if (previousTheme) {
      document.body.classList.remove(previousTheme);
    }
    // Add the new theme class
    document.body.classList.add(theme);
  }, [theme]);

  const defaultValue = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme]
  );

  return (
    <ThemeContext.Provider value={defaultValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default memo(ThemeProvider);
