import OrganizationResource from "api/organization";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-plugin-datalabels";
import { CenterSpinner } from "components/common/CenterSpinner";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";

ChartJS.register(...registerables);

const BarChart: React.FC = () => {
  const [colors, setColors] = useState([]);
  const organizationAPI = new OrganizationResource();
  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const { data: chartViewList, isLoading: isChartViewListLoading } = useQuery(
    ["chartViewList"],
    async () => {
      const response = await organizationAPI.getchartView(
        user?.organization_id
      );
      if (response?.data?.data?.fields?.length > 0) {
        const colors = response?.data?.data?.fields.map((field: string) => {
          let color = "#FC6C72";
          switch (field) {
            case "Damaged":
              color = "#FC6C72";
              break;
            case "No Damaged":
              color = "#8CB832";
              break;
            case "Damage Seen":
              color = "#ECD37B";
              break;
            case "Damage Not Suspected":
              color = "#AFAFAF";
              break;
            case "Damage Suspected":
              color =
                "repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px);";
              break;
            case "Partial Damage Seen":
              color =
                "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px);";
              break;
            case "No Damage Seen":
              color =
                "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px);";
              break;
            default:
              color = "#ECA47B";
          }
          return { color };
        });
        setColors(colors);
      }
      return response?.data?.data;
    }
  );

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    // scales: {
    //   x: {
    //     display: false,
    //   },
    //   y: {
    //     display: false,
    //   },
    // },
  };

  const data = {
    labels: chartViewList?.fields,
    datasets: [
      {
        data: chartViewList?.values,
        backgroundColor: colors.map((res: any) => res?.color), // Bar color
        borderColor: colors.map((res: any) => res?.color), // Border color
        borderWidth: 2,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  return (
    <>
      {!isChartViewListLoading && <Bar data={data} options={options} />}
      {isChartViewListLoading && <CenterSpinner />}
    </>
  );
};

export default BarChart;
