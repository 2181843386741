import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
// import * as authService from "services/auth";
// @ts-ignore
import { MentionsInput, Mention } from 'react-mentions';
import { setUnreadCount } from "actions/data/chat";
import ChatResource from "api/chat";
import { INITIAL_CURRENT_PAGE } from "constants/common";
import React, { useEffect, useRef, useState } from "react";
import { BiPaperPlane } from "react-icons/bi";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMutation, useInfiniteQuery, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { truncateString } from "utils";
import { isValidDate } from "utils/validate-date";
import ChatMessageDetails from "./ChatMessageDetails";
import defaultStyle from "./defaultStyle";
import { reconnect, socket } from "services/socket";

interface Props {
  conversation: string;
  showMessage: any;
}

const ChatBot: React.FC<Props> = (props) => {
  const { conversation, showMessage } = props;
  const dispatch = useDispatch();
  const [mentionText, setMentionText] = useState('');
  const { loggedInUser, currentChatUserDetails } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      currentChatUserDetails: state?.data?.chat?.currentChatUser,
    }),
    shallowEqual
  );
  const [scrollEl, setScrollEl] = useState<any>();
  const txtAreaChatRef = useRef<HTMLTextAreaElement>(null);
  const chatAPI = new ChatResource();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  const formattedParticipants = currentChatUserDetails?.participants?.map((item: any) => ({ id: item.id, display: item?.first_name + ' ' + item?.last_name }));

  const updateChatRead = useMutation(
    (data: any) => chatAPI.readConversation(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(["chatList"]);
      },
      onError: (error: any) => { },
    }
  );
// @ts-ignore
  const fetchMessages = async ({ pageParam = INITIAL_CURRENT_PAGE }: { pageParam?: string | null }) => {
    const queryParams = {
      page: pageParam,
      page_size: 1000,
    };

    const response = await chatAPI.getAllMessage(conversation, queryParams);
    const chatMessages = Object.values(response?.data?.data?.messages).reverse();
    const nextUrl = response.data.meta.next ? response.data.meta.next.replace("http://", "https://") : null;

    return {
      chatMessage: chatMessages,
      nextMessage: nextUrl,
    };
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    // isFetching,
    // isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["ChatMessages", conversation],
    queryFn: fetchMessages,
    getNextPageParam: (lastPage) => lastPage.nextMessage,
    enabled: !!conversation, // Enable the query only if conversation is truthy
  });

  const chatMessage = React.useMemo(
    () => data?.pages.flatMap((page) => page.chatMessage) || [],
    [data]
  );

  const executeScroll = async (container: any) => {
    if (container.scrollTop === 0 && hasNextPage) {
      fetchNextPage();
    }
  };

  const handleDeleteMessage = (id: number) => {
    // Logic to delete message
  };

  const [groupedMessages, setGroupedMessages] = useState<any>({});

  useEffect(() => {
    // Group messages by date
    const updatedGroupedMessages: any = {};

    chatMessage.forEach((message: any) => {
      const date = new Date(message.created_at).toDateString();
      if (!updatedGroupedMessages[date]) {
        updatedGroupedMessages[date] = [];
      }
      updatedGroupedMessages[date].push(message);
    });

    setGroupedMessages(updatedGroupedMessages);
  }, [chatMessage]);

  const handleChatByEnter = (e: any) => {
    if (e.key === "Enter") {
      saveChat(mentionText);
      queryClient.invalidateQueries(["ChatMessages", conversation]);
      e.preventDefault();
    }
  };

  const handleChat = () => {
    if (mentionText.trim()) {
      saveChat(mentionText);
      // queryClient.invalidateQueries(["ChatMessages", conversation]);
      
      // Get the current data from the query cache
      const oldData = queryClient.getQueryData(["ChatMessages", conversation]);
  
      if (oldData) {
        // Append the new message to the existing messages
        const newMessage = {
          id: Date.now(), // or use the actual ID if available
          message: mentionText,
          created_at: new Date().toISOString(),
          sender: {
            id: loggedInUser.id,
            full_name: loggedInUser.full_name
          }
        };
  
        // Update the query data
        queryClient.setQueryData(["ChatMessages", conversation], (old: any) => {
          if (old) {
            // Append the new message to the first page (assuming messages are ordered)
            const updatedPages = old.pages.map((page: any) => ({
              ...page,
              chatMessage: [ ...page.chatMessage,newMessage] // Add new message at the beginning of the current page
            }));
            
            return {
              ...old,
              pages: updatedPages,
            };
          }
          return old;
        });
      }
  
      setMentionText('');
    }
  };

  const saveChat = async (message: string) => {
    if (message.trim() === "") return;

    const socketData = {
      event_type: "chat_message",
      message: mentionText,
      conversation,
    };

    if (socket?.readyState === 1) {
      socket.send(JSON.stringify(socketData));
    } else {
      window.location.reload();
    }
    setMentionText('');
  };

  const onFocus = () => {
    if (socket?.readyState === 2 || socket?.readyState === 3) {
      reconnect();
    }
  };

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  });


  const keys = (obj:any) => {
    return obj === Object(obj) ? Object.keys(obj) : []
  }
  const isPlainObject = (obj:any) =>
    !(obj instanceof Date) && obj === Object(obj) && !Array.isArray(obj)
  

  const mergeDeep = (target:any, source:any) => {
    let output = Object.assign({}, target)
    if (isPlainObject(target) && isPlainObject(source)) {
      keys(source).forEach(key => {
        if (isPlainObject(source[key])) {
          if (!(key in target)) Object.assign(output, { [key]: source[key] })
          else output[key] = mergeDeep(target[key], source[key])
        } else {
          Object.assign(output, { [key]: source[key] })
        }
      })
    }
    return output
  }
  
  //@ts-ignore
  const merge = (target:any, ...sources) => {
    return sources.reduce((t, s) => {
      return mergeDeep(t, s)
    }, target)
  }
  
  let style = merge({}, defaultStyle, {
    input: {
      overflow: 'auto',
      minHeight: 40,
      maxHeight:70

    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      minHeight: 40,
      maxHeight:70,
    },
  })
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    setLoading(false);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [loading]);

  if (socket) {
    socket.onmessage = (data: any) => {
      // queryClient.invalidateQueries(["chatList"]);
      const messageDetail = JSON.parse(data.data);
      if (
        messageDetail?.data?.sender?.id !== loggedInUser.id &&
        messageDetail?.event_type !== "join_room" &&
        messageDetail?.conversation === conversation
      ) {
        let title = `New Message From ${messageDetail?.data?.sender?.full_name}`;
        dispatch(setUnreadCount(messageDetail?.unread_count));

        toast({
          title: title,
          description: truncateString(messageDetail?.message),
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        // chatMessage = [...chatMessage, messageDetail?.data];
//@ts-ignore
        queryClient.setQueriesData({ queryKey: ["ChatMessages", conversation] }, (oldData: unknown) => {

        })
          // queryClient.setQueryData( ["ChatMessages", conversation], { pages: newPagesArray, pageParams: commentsList?.pageParams })
        updateChatRead.mutate({ conversation: messageDetail?.conversation });
      }
    };
  }

  return (
    <Stack
      direction="column"
      pos="relative"
      p="1"
      bg="white"
      shadow="box"
      rounded="md"
      maxH="700px"
      height={"100%"}
      className="chat-msg-wrapper"
    >
      <PerfectScrollbar
        onScrollY={(ref) => {
          executeScroll(ref);
        }}
        containerRef={(ref: any) => {
          setScrollEl(ref);
        }}
      >
        <Stack h="calc (100vh-184px)" p="4">
          {isLoading && (
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          )}
          {!!showMessage &&
            !isLoading &&
            Object.keys(groupedMessages).map((date: string) => {
              return (
                <React.Fragment key={date}>
                  {isValidDate(date) && (
                    <Box position="relative" padding="10">
                      <Divider />
                      <AbsoluteCenter bg="white" px="4">
                        {date === new Date().toDateString()
                          ? "Today"
                          : date ===
                            new Date(Date.now() - 86400000).toDateString()
                            ? "Yesterday"
                            : date}
                      </AbsoluteCenter>
                    </Box>
                  )}
                  {groupedMessages[date]?.map((message: any, index: any) => (
                    <ChatMessageDetails
                      message={message}
                      key={index + message.id}
                      handleDeleteMessage={handleDeleteMessage}
                    />
                  ))}
                </React.Fragment>
              );
            })}
        </Stack>
      </PerfectScrollbar>

      <div style={{ position: 'relative' }}>
        <Stack p="3">
          <InputGroup size="md" pos={"relative"}>
          <MentionsInput
        value={mentionText}
        className="chat-msg-textarea"
        ref={txtAreaChatRef}

        onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMentionText(e.target.value)}
        style={style}
        placeholder={"Mention people using '@'"}
        a11ySuggestionsListLabel={"Suggested mentions"}
        allowSuggestionsAboveCursor={true}
        
        onKeyPress={handleChatByEnter}

        customSuggestionsContainer={(children:React.ReactNode)=><div>{children}</div>}
      >
               <Mention data={ formattedParticipants}  
        markup={'@{id:__id__,name:__display__}'}
        style={{backgroundColor: '#cee4e5'}}  />
            
            </MentionsInput>
            <InputRightElement p="10" className="send-btn-wrapper">
              <Button
                     className="chat-send-btn"
                     size="md"
                     rounded="3xl"
                     bg="green.300"
                     p="2"
                     onClick={handleChat}
                     _hover={{ color: "white", bgColor: "green.500" }}
              >
                <Icon as={BiPaperPlane} />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Stack>
      </div>
    </Stack>
  );
};

export default ChatBot;
