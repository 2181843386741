import Resource from "api/resource";
import http from "utils/http";

class InfrastructureResource extends Resource {
  constructor() {
    super("geo/infrastructures");
  }

  getInfrastructureDetail(infrastructureId?: Number, query?: any) {
    return http({
      url: `/geo/infrastructures/${infrastructureId}/`,
      method: "get",
      params: query,
    });
  }

  getInfrastructureAssessment(infrastructureId?: Number, query?: any) {
    return http({
      url: `/geo/infrastructures/${infrastructureId}/assessments/`,
      method: "get",
      params: query,
    });
  }

  getAssessmentDamages(assessmentId?: Number, query?: any) {
    return http({
      url: `/geo/assessments/${assessmentId}/damages/`,
      method: "get",
      params: query,
    });
  }

  updateInfrastructureStatus(resource: any) {
    return http({
      url: `/geo/infrastructures/status/update/`,
      method: "put",
      data: resource,
    });
  }

  updateInfrastructureImage(id: any, resource: any, headers?: any) {
    return http({
      url: `/geo/infrastructures/${id}/image/`,
      method: "patch",
      data: resource,
      headers: headers,
    });
  }

  organizationReset() {
    return http({
      url: `/geo/infrastructures/reset/`,
      method: "put",
    });
  }

  infrastuctureReset(id: number) {
    return http({
      url: `/geo/infrastructures/${id}/reset/`,
      method: "put",
    });
  }

  assessmentReset(id: number) {
    return http({
      url: `/geo/assessments/${id}/reset/`,
      method: "put",
    });
  }

  assessmentDelete(id: number) {
    return http({
      url: `/geo/assessments/${id}/`,
      method: "delete",
    });
  }

  startAlert(resource: any, headers?: any) {
    return http({
      url: "/geo/infrastructures/start-alert/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }

  hasAlert() {
    return http({
      url: "/geo/infrastructures/check-alert/",
      method: "get",
    });
  }

  endAlert(resource: any, headers?: any) {
    return http({
      url: "/geo/infrastructures/end-alert/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }
}

export { InfrastructureResource as default };
