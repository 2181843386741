/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { setUpdateFCMToken } from "actions/data/auth";
import { setFCMToken } from "api/auth";
import { getFirebaseToken } from "FirebaseConfig";
import theme from "config/theme";
import { ClientJS } from "clientjs";

import ThemeProvider from "context/ThemeContext";
import Router from "router/Router";
import ErrorBoundary from "./errors/ErrorBoundary";
import "assets/css/App.css";
import "assets/css/theme.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    const initializeFCMToken = async () => {
      try {
        if ("Notification" in window && window.Notification.permission === "granted") {
          if (!loggedInUser?.fcmToken) {
            const fcmToken = await getFirebaseToken();
            const client = new ClientJS();
            const fingerprint = client.getFingerprint();
            await setFCMToken({ token: fcmToken }, fingerprint);
            dispatch(setUpdateFCMToken({ fcmToken, deviceId: fingerprint }));
          }
        }
      } catch (error) {
        console.error("Notification Permission Error:", error);
      }
    };

    initializeFCMToken();
  }, [dispatch, loggedInUser]);

  return (
    <ThemeProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
