// @ts-nocheck
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";

import ArcGISMap from "@arcgis/core/Map";

import Graphic from "@arcgis/core/Graphic";
import { Box } from "@chakra-ui/react";
import {
  damagedNotSuspectedSymbol,
  damagedSymbol,
  damageSeenSymbol,
  damageSuspectedSymbol,
  noDamageSeenSymbol,
  notDamagedSymbol,
  partialDamageSeenSymbol,
  partiallyDamagedSymbol,
} from "components/map/MapConstants";
import { useEffect, useRef } from "react";

export const webmap = new ArcGISMap({
  basemap: "streets-navigation-vector",
});

export const AssessmentMap = ({
  status = "good",
  geometry,
}: {
  geometry?: any;
  status: any;
}) => {
  const mapRef = useRef<Map>(null);
  const app = {
    map: webmap,
    center: geometry,
    scale: 25000,
  };
  const mapView = new MapView(app);

  // eslint-disable-next-line
  const loadMap = async () => {
    mapView.container = mapRef.current;
    mapView.ui.components = [];
    const graphicsLayer = new GraphicsLayer();
    mapView.map.add(graphicsLayer);

    let lng = 0,
      lat = 0;
    if (geometry && geometry.length > 0) {
      lng = geometry[0];
      lat = geometry[1];
    }
    const point = {
      //Create a point
      type: "point",
      longitude: lng,
      latitude: lat,

      // longitude: -111.767139802,
      // latitude: 41.114619357,
      // spatialReference:
      //   "SRID=4326;LINESTRING (-111.767139802 41.114619357, -111.767424063 41.1145400870001)",
    };

    const pointAttributes = {
      status: "ND",
      image:
        "https://media.istockphoto.com/id/117751351/photo/ravenel-bridge-in-charleston-sc.jpg?s=612x612&w=0&k=20&c=gd2DYSiomkQEswKcp-MPT3VvBMhwTY5HAqL7UEwfcXI=",
      globalid: "d60a1938-5414-4ce9-877e-176b95d8ada6",
      CreationDate: "2021-09-30",
      Creator: "hannah.rettig",
    };
    const pointGraphic = new Graphic({
      // geometry: webMercatorUtils.geographicToWebMercator(point),
      geometry: point,
      aggregateGeometries: pointAttributes,
      hasZ: false,
      hasM: true,
      // symbol: "simple-line",
      symbol:
        status === "ND"
          ? notDamagedSymbol
          : status === "DS"
          ? damageSuspectedSymbol
          : status === "DNS"
          ? damagedNotSuspectedSymbol
          : status === "DSE"
          ? damageSeenSymbol
          : status === "NDS"
          ? noDamageSeenSymbol
          : status === "PDS"
          ? partialDamageSeenSymbol
          : status === "PD"
          ? partiallyDamagedSymbol
          : damagedSymbol,
    });
    graphicsLayer.add(pointGraphic); // setView(mapView);
    // geoJsonLayer = geojsonlayerBridge;
    // }
  };

  // const destroyMap = async () => {
  //   // setMount(false);
  //   // viewss?.map.destroy();
  //   views?.map.removeAll();
  // };

  useEffect(() => {
    setTimeout(() => {
      loadMap();
    }, 0);
    // return () => {
    //   // destroyMap();
    // };
  }, [loadMap]);

  return (
    <Box display={"flex"} height="100%" width={"100%"}>
      <Box ref={mapRef} className="mapDiv" />
    </Box>
  );
};

// export const TessalationMapMobile;
