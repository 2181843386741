import { Center, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BridgePlaceHolder from '../../assets/images/bridgePlaceholder.webp'
interface IBridgeImageProps {
  imageUrl: string;
  imageAlt: string;
}
export function BridgeImageComponent(props: IBridgeImageProps) {

  const [imageUrl, setImageUrl] = useState<string>(props.imageUrl || BridgePlaceHolder);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const handleImageError = () => {
    setImageUrl(BridgePlaceHolder);
    setIsImageLoading(false);
  };

  const handleImageLoad = (e: any) => {
    setIsImageLoading(false);
  };
  useEffect(() => {
    if (props.imageUrl) setImageUrl(props.imageUrl);
    else setImageUrl(BridgePlaceHolder);
  }, [props.imageUrl]);

  return (
    <>
      <Center>
        {isImageLoading && <Spinner position={"absolute"} />}

        <Image
          minH={"110px"}
          objectFit="contain"
          src={imageUrl}
          alt={props.imageAlt}
          onError={handleImageError}
          onLoad={handleImageLoad}
        />
      </Center>
    </>
  );
}
