// @ts-nocheck
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Graphic from "@arcgis/core/Graphic";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand";
import Feature from "@arcgis/core/widgets/Feature";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { setCurrentChatUser } from "actions/data/chat";
import InfrastructureResource from "api/infrastructures";
// import { DangerCircle } from "assets/icons";
import { CenterSpinner } from "components/common/CenterSpinner";
import { EVENT_TYPE_LIST } from "constants/common";
import routes from "constants/routes";
import { useCallback, useEffect, useRef, useState } from "react";
// import { GiCheckMark } from "react-icons/gi";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { basemapGallery } from "./MapConstants";
import { OnDraw, polygonSymbol } from "./MapFunctions";
import { initialize } from "./MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

// export var view = new MapView(app);
// var sketchlayer: GraphicsLayer;
var sketch: SketchViewModel;

var geoJsonLayer: GeoJSONLayer;
var temp = [];

const TessalationMap = ({
  views,
  hexLayer,
  app,
  infrastructureLists,
  setBridgeLayer,
  mapRef,
}: {
  views: MapView;
  app: any;
  infrastructureLists: any;
  hexLayer:FeatureLayer,
  setBridgeLayer: any;
  mapRef: any;
}) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const infrastructureAPI = new InfrastructureResource();
  const [sketchGraphics, setSketchGraphics] = useState<Graphic>([]);
  // const [views, setViews] = useState<MapView>(view);
  const [featureSelected, setFeatureSelected] = useState<Feature>([]);
  const [save, setSave] = useState(false);
  const [draw, setDraw] = useState(false);
  const [isListShown, setIsListShown] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showStartAlertModal, setShowStartAlertModal] = useState(false);
  // const [showEndAlertModal, setShowEndAlertModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [infrastructureId, setInfrastructureID] = useState(0);

  const infrastructureResource = new InfrastructureResource();
  const queryClient = useQueryClient();

  const { data: alertDataQuery } = useQuery(["alertDataQuery"], async () => {
    const response = await infrastructureAPI.hasAlert();
    return response?.data;
  });

  // const updateInfrastructureStatuses = useMutation(
  //   (data: any) => infrastructureResource.updateInfrastructureStatus(data),
  //   {
  //     onSuccess: (res: any) => {
  //       // toast({
  //       //   position: "top",
  //       //   title: "Invitation has been re-sent!",
  //       //   status: "success",
  //       //   isClosable: true,
  //       // });
  //     },
  //     onError: (res: any) => {
  //       toast({
  //         position: "top",
  //         title: "Unable to update infrastructure statuses",
  //         status: "error",
  //         isClosable: true,
  //       });
  //     },
  //   }
  // );

  const dispatch = useDispatch();
const organisationData=useSelector((state:any)=>state.data.organization.organizationDetail)
console.log(organisationData,'organisation data')
  const navigate = useNavigate();
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      primaryGroup: state?.data?.auth?.primaryGroup,
    }),
    shallowEqual
  );

  const handleDelete = (featureSelected, n) => {
    setFeatureSelected(featureSelected.filter((item, index) => index !== n));
  };

  const loadMap = useCallback(async (prop) => {
    setLoading(true);
    if (!views.container) {
      // console.log("loading map");
      views.ui?.remove("expand");
      views.map?.removeAll();
      const { container, geojsonlayerBridge, joinLayer, SurveyorLayer } =
        await initialize(mapRef.current, views, app, prop,organisationData?.hex_file);
      views.container = container;
      app.map.add(geojsonlayerBridge);

      app.map.add(joinLayer);

      app.map.reorder(joinLayer, 10);
      app.map.add(SurveyorLayer);
      if (geojsonlayerBridge) {
        setBridgeLayer(geojsonlayerBridge);
      }
      // let a = new FeatureLayer({
      //   // url,
      //   url: "https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/Live_Stream_Gauges_v1/FeatureServer/0",

      //   // opacity: 0.5,
      //   // renderer: heatMapRenderer,
      //   // visible: true,
      //   // popupEnabled: true,
      //   legendEnabled: true,
      // });
      // let query = {
      //   where: "1=1", // returns all features
      //   returnGeometry: true, // includes the geometry in the result
      //   outFields: ["*"], // includes all fields in the result
      // };
      // console.log(await a.queryFeatures(query), "join layer created");
      // app.map.add(joinLayer);
      // app.map.add(a);

      let basemapGalleryExpand = new Expand({
        views: views,
        content: basemapGallery(views).domNode,
        id: "expand",
        // expandIconClass: "esri-icon-basemap",
      });

      // app.map.add(joinLayer);
      // app.map.reorder(joinLayer, 0);
      views?.ui?.add(basemapGalleryExpand, {
        position: "bottom-left",
      });

      geoJsonLayer = geojsonlayerBridge;
      setLoading(false);
    } else {
      views?.map?.destroy();
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const destroyMap = () => {
    views.destroy();
    // views = null;
  };

  const handleEventChange = async (e) => {
    if (featureSelected.length > 0) {
      const ids = [];
      // eslint-disable-next-line
      featureSelected.map((bridge) => {
        ids.push(bridge.attributes.id);
        // let message = "";
        // message += `Bridge Detail: ${
        //   bridge.attributes.bridge_name
        // } \n Next Assessment Date: ${
        //   bridge.attributes.next_assessment_date
        // } \n Risk: ${
        //   infrastructureStatuses[bridge.attributes.infrastructure_status]
        // } \n Event Type: ${damageLevels[e.currentTarget.value]} `;

        // const socket?.ata = {
        //   event_type: "chat_message",
        //   conversation: primaryGroup?.conversation,
        //   message,
        // };
        // socket?.send(JSON.stringify(socket?.ata));
      });

      // await updateInfrastructureStatuses.mutate({
      //   event_type: e.currentTarget.value,
      //   infrastructure_status: "DS",
      //   infrastructure_id: ids,
      // });

      startAlertMutation.mutate({
        event_type: e.currentTarget.value,
        // infrastructure_status: "DS",
        infrastructure_ids: ids,
      });
    } else {
      toast({
        title: `No bridge selected for assessment`,
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadMap(infrastructureLists);

    return () => {
      destroyMap();
      console.log("leaving map view");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function startDraw() {
    sketch = await OnDraw(views);

    //  sketchlayer = b;
    setDraw(true);
    sketch.create("polygon");

    sketch.on("create", function (event: any) {
      if (event.state === "complete") {
        var geometry = event.graphic.geometry;
        var symbol = new SimpleFillSymbol();
        let tempGraphics = new Graphic({ geometry, symbol });
        // sketchGraphics.push(tempGraphics);
        // console.log(sketchGraphics, "after adding feature");
        setSketchGraphics((previous) => [...previous, tempGraphics]);
        // console.log(tempGraphics, "temporary graphics each time created");
        views.graphics.add(tempGraphics);
        queryFeaturelayer(geometry);
        controlDraw({ add: true });
      }
    });
  }
  function controlDraw({
    cancel = false,
    save = false,
    add = false,
    edit = false,
  }) {
    // console.log(sketch, save, add, "cancel, draw,add");

    if (cancel) {
      setSave(false);
      setDraw(false);
      sketch.destroy();
      // views.graphics.removeMany(sketchGraphics);
      views?.graphics.removeAll();
      setSketchGraphics([]);
      setFeatureSelected([]);
      temp = [];
      return;
    } else if (save) {
      setDraw(false);
      mergeShapes();

      sketch.destroy();
      setSave(true);
    } else if (add) {
      startDraw();
    } else if (edit) {
      // console.log(featureSelected, "feature selected in after edit button");
      // mergeShapes();

      setSave(false);
      setDraw(true);
      controlDraw({ add: true });
      // views.graphics.removeMany(sketchGraphics);
    } else {
      setDraw(false);
    }
  }
  async function queryFeaturelayer(geometry) {
    const parcelQuery = {
      spatialRelationship: "intersects", // Relationship operation to apply
      geometry: geometry, // The sketch feature geometry
      outFields: [
        "__OBJECTID",
        "bridge_name",
        "id",
        "infrastructure_status",
        "next_assessment_date",
      ], // Attributes to return
      returnGeometry: true,
    };

    geoJsonLayer
      .queryFeatures(parcelQuery)
      .then((results) => {
        temp = [];
        if (temp.length === 0) {
          temp = results.features;
        }
        const result = results.features.filter(
          ({ attributes: id1 }) =>
            !temp.some(
              ({ attributes: id2 }) => id1.__OBJECTID === id2.__OBJECTID
            )
        );

        temp = temp.concat(result);
        // console.log(featureSelected, "sketches in filtering");
        setFeatureSelected(temp);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function showToast() {
    toastIdRef.current = toast({
      position: "top",
      status: "success",
      duration: 4000,
      isClosable: true,
      render: () => (
        <CustomToast
          featureSelected={featureSelected}
          toast={toast}
          toastid={toastIdRef}
        />
      ),
    });
  }

  function mergeShapes() {
    var updatedGeometry = [];
    sketchGraphics.map(function (gra) {
      if (gra.geometry.spatialReference.wkid === 4326) {
        return updatedGeometry.push(
          webMercatorUtils.geographicToWebMercator(gra.geometry.clone())
        );
      } else {
        return updatedGeometry.push(gra.geometry.clone());
      }
    });
    var joinedPolygon = geometryEngine.union(updatedGeometry);
    // console.log(joinedPolygon, "joined polygon ");
    views?.graphics.removeAll();
    let resultgraphic = new Graphic({
      geometry: joinedPolygon,
      symbol: polygonSymbol,
    });
    // console.log(resultgraphic, "result graphics");
    views?.graphics.add(resultgraphic);

    views?.map.reorder(geoJsonLayer, 1);
    views?.map.reorder(resultgraphic.layer, 0);
  }

  /**
   * May be used later
   */
  // views?.on("click", (event) => {
  //   views.hitTest(event).then(function (response) {
  //     if (response.results.length > 0) {
  //       const geoJsonLayer = response.results[0]?.graphic?.layer;
  //       if (geoJsonLayer && geoJsonLayer.objectIdField === "__OBJECTID") {
  //         const objectIds = response.results.map(
  //           (result) => result.graphic.attributes[geoJsonLayer.objectIdField]
  //         );
  //         objectIds.length > 0 &&
  //           geoJsonLayer
  //             ?.queryFeatures({
  //               objectIds: objectIds,
  //               outFields: ["*"],
  //             })
  //             .then((featureSet) => {
  //               if (featureSet.features.length === 1) {
  //                 setInfrastructureID(featureSet.features[0].attributes?.id);
  //                 if (featureSet.features[0].attributes?.alert_date) {
  //                   setShowStartAlertModal(false);
  //                   setShowEndAlertModal(true);
  //                 } else {
  //                   setShowStartAlertModal(true);
  //                   setShowEndAlertModal(false);
  //                 }
  //               }
  //               // featureSet.features.forEach((feature) => {
  //               //   console.log(feature.attributes);
  //               // });
  //             });
  //       } else {
  //         setInfrastructureID(0);
  //         setShowStartAlertModal(false);
  //         setShowEndAlertModal(false);
  //       }
  //     }
  //   });
  // });

  const startAlertMutation = useMutation(
    (data: any) => infrastructureResource.startAlert(data),
    {
      onSuccess: (res: any) => {
        // navigate(routes.chats.list);
        // queryClient.invalidateQueries("organizationInfrastructureList");
        // setShowStartAlertModal(false);
        // setShowEndAlertModal(false);
        // toast({
        //   title: "Alert started",
        //   status: "success",
        //   duration: 9000,
        //   isClosable: true,
        // });
        dispatch(
          setCurrentChatUser({
            name: res?.data?.conversation?.name,
            default_group: res?.data?.conversation?.primary_group,
            conversation: res?.data?.conversation?.conversation,
          })
        );
        queryClient.invalidateQueries("organizationInfrastructureList");
        showToast();
        setTimeout(() => {
          navigate(routes.chats.list);
        }, 2000);
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable to update infrastructure statuses",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const endAlertMutation = useMutation(
    (data: any) => infrastructureResource.endAlert(data),
    {
      onSuccess: (res: any) => {
        navigate(routes.chats.list);
        queryClient.invalidateQueries("organizationInfrastructureList");
        queryClient.invalidateQueries("alertDataQuery");
        toast({
          title: "Alert ended",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (res: any) => {},
    }
  );

  const handleAlert = (type: string) => {
    if (type === "start") {
      startAlertMutation.mutate({ infrastructure_id: infrastructureId });
    } else if (type === "end") {
      endAlertMutation.mutate({ infrastructure_id: infrastructureId });
    }
  };

  return (
    <>
      <Box display={"flex"} height="100%" width={"100%"}>
        {loading && (
          <Box my={"auto"} display={"block"} w={"60%"} position={"absolute"}>
            <CenterSpinner />
          </Box>
        )}
        <Box ref={mapRef} className="mapDiv" />
        {save && (
          <Box className="edit-box" position={"absolute"}>
            <Box
              className="edit-draw-button"
              position={"absolute"}
              flexDirection="row"
              display={"flex"}
              alignItems="center"
            >
              <Button
                backgroundColor={"black"}
                textColor={"white"}
                _hover={{ bg: "black" }}
                onClick={() => controlDraw({ edit: true, add: false })}
              >
                Edit Draw
              </Button>
              <Divider
                orientation="vertical"
                borderColor={"white"}
                mx={"13px"}
              />
              <Button
                backgroundColor={"black"}
                textColor={"white"}
                _hover={{ bg: "black" }}
                onClick={() => controlDraw({ cancel: true, add: false })}
              >
                <ImCross color="white" size={12} />
              </Button>
            </Box>
            <Box
              className="edit-list-container"
              backgroundColor={"white"}
              textAlign="center"
            >
              <Text fontSize={"lg"} fontWeight="bold" mt={"12px"}>
                {featureSelected.length > 0 ? featureSelected.length : `No`}{" "}
                Bridges Selected
              </Text>
              {isListShown && (
                <List
                  textAlign={featureSelected.length > 0 ? "left" : "center"}
                  px="13px"
                  spacing={"5px"}
                  overflow="scroll"
                  maxHeight={"207px"}
                  mt={"12px"}
                >
                  {featureSelected.length > 0 ? (
                    featureSelected.map((p, n) => {
                      return (
                        <ListItem
                          display={"flex"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          key={n}
                        >
                          <Box flex={0.8}>
                            <Text>{p.attributes.bridge_name}</Text>
                          </Box>
                          <Box flex={0.1}>
                            <ImCross
                              color="#C7C7C7"
                              size={12}
                              onClick={() => handleDelete(featureSelected, n)}
                            />
                          </Box>
                        </ListItem>
                      );
                    })
                  ) : (
                    <Spinner />
                  )}
                </List>
              )}
              {isListShown ? (
                <Button
                  color={"#38606C"}
                  fontSize="lg"
                  mt={"8px"}
                  backgroundColor="transparent"
                  fontWeight={"normal"}
                  _hover={{ backgroundColor: "transparent" }}
                  _focus={{ backgroundColor: "transparent" }}
                  onClick={() => setIsListShown(false)}
                >
                  Hide list
                </Button>
              ) : (
                <Button
                  color={"#38606C"}
                  fontSize="lg"
                  // mt={"8px"}
                  onClick={() => setIsListShown(true)}
                  backgroundColor="transparent"
                  fontWeight={"normal"}
                  _focus={{ backgroundColor: "transparent" }}
                >
                  Show list
                </Button>
              )}

              <Divider borderBottomWidth={"2px"} />
              <Select
                className="event-type-list"
                py="4"
                alignItems="center"
                justifyContent={"space-evenly"}
                placeholder="Select Event Type"
                onChange={(e) => handleEventChange(e)}
              >
                {EVENT_TYPE_LIST.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
              {/* <Button
                className="edit-list-event-button"
                my={"12px"}
                alignItems="center"
                justifyContent={"space-evenly"}
                onClick={() =>
                  
                }
              >
                Select Event Type
                <IoChevronDown color="white" />
              </Button> */}
            </Box>
          </Box>
        )}

        {draw === false && save === false ? (
          <>
            {/* {loggedInUser?.role === "CCA" && showStartAlertModal && (
              <Box>
                <Button
                  backgroundColor={"#FFFFFF"}
                  className="alert-button"
                  position={"absolute"}
                  zIndex={0}
                  isLoading={startAlertMutation.isLoading}
                  onClick={() => {
                    handleAlert("start");
                  }}
                >
                  Start Alert
                </Button>
              </Box>
            )} */}
            {!alertDataQuery?.is_alert_active && (
              <Button
                zIndex={0}
                backgroundColor={"#FFFFFF"}
                className="draw-button"
                position={"absolute"}
                onClick={() => startDraw()}
              >
                Start Alert
              </Button>
            )}
          </>
        ) : save === false ? (
          <Box
            backgroundColor="blackAlpha.600"
            className="apply-banner"
            px="20px"
          >
            <Text color={"white"} fontSize="14px">
              Draw a shape around the area you’d like to search
            </Text>
            <Box>
              <Button
                textColor={"white"}
                fontSize="14px"
                backgroundColor={"transparent"}
                _hover={{ bg: "transparent" }}
                onClick={() => controlDraw({ cancel: true, add: false })}
              >
                Cancel
              </Button>
              <Button
                textColor={"white"}
                fontSize="14px"
                backgroundColor={"transparent"}
                _hover={{ bg: "transparent" }}
                onClick={() =>
                  controlDraw({ save: true, add: false, cancel: false })
                }
                // onClick={() => mergeShapes()}
              >
                Apply
              </Button>
            </Box>
          </Box>
        ) : null}

        {loggedInUser?.role === "CCA" && alertDataQuery?.is_alert_active && (
          // <Box
          //   position={"absolute"}
          //   top={"140px"}
          //   border={"1px solid #A8A8A8"}
          //   right={"10px"}
          //   background={"#fff"}
          // >
          //   <Stack direction="row" h="80px" zIndex={0}>
          //     <Flex p={2} alignItems={"center"}>
          //       <Box
          //         w={"42px"}
          //         height={"42px"}
          //         border={"1px solid #535353"}
          //         borderRadius={"40px"}
          //         display={"flex"}
          //         alignItems={"center"}
          //         justifyContent={"center"}
          //       >
          //         <Image src={DangerCircle} alt="End Alert" title="End Alert" />
          //       </Box>
          //       <Box ml={2}>
          //         <Text color="red">Alert!</Text>
          //         <Text color="#B8B8B8">Did you hear about rehearsal </Text>
          //       </Box>
          //     </Flex>
          //     <Divider orientation="vertical" />
          //     <Center ml={2}>
          //       <IconButton
          //         variant={"unstyled"}
          //         size="lg"
          //         aria-label="End Alert"
          //         isLoading={endAlertMutation.isLoading}
          //         onClick={() => handleAlert("end")}
          //         icon={<GiCheckMark />}
          //       />
          //     </Center>
          //   </Stack>
          // </Box>
          <Button
            zIndex={0}
            backgroundColor={"#FFFFFF"}
            className="draw-button"
            position={"absolute"}
            onClick={() => handleAlert("end")}
          >
            End Alert
          </Button>
        )}
      </Box>
    </>
  );
};
export default TessalationMap;

const CustomToast = ({ featureSelected, toast, toastid }) => {
  function closeToast() {
    toast.close(toastid.current);
  }
  return (
    <Box
      className="toast-banner"
      display={"flex"}
      flexDirection="row"
      justifyContent={"space-between"}
    >
      <Box
        display={"flex"}
        flexDirection="row"
        flex={0.5}
        justifyContent={"space-evenly"}
      >
        <HiOutlineCheckCircle color="white" size={30} />
        <Text alignSelf={"center"}>
          {featureSelected.length} bridge assessment request sent
        </Text>
      </Box>
      <Button
        onClick={() => closeToast()}
        flex={0.1}
        backgroundColor="transparent"
        _hover={{ backgroundColor: "transparent" }}
        _focus={{ backgroundColor: "transparent" }}
      >
        <ImCross color="white" fontSize={"12px"} />
      </Button>
    </Box>
  );
};
