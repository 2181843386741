import FileSaver from "file-saver";
import JSZip from "jszip";
import { DateFormat } from "utils";
import * as XLSX from "xlsx";

const CSV_FILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

function csvExport(dataArray: any, fileName: string) {
  const ws = XLSX.utils.json_to_sheet(dataArray, {
    dateNF: "MM/DD/YYYY",
    skipHeader: false,
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: CSV_FILETYPE });
  FileSaver.saveAs(data, fileName);
}

function jsonExport(dataArray: any, fileName: string) {
  var blob = new Blob([JSON.stringify(dataArray)], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, fileName);
}

interface Image {
  image_url?: string | null; // Assuming image_url can be a string or null
  image_name?: string | null; // Assuming image_name can be a string or null
}

async function femaExport(
  images: Image[],
  dataArray: any,
  fileName: string,
  setLoading: Function
) {
  setLoading(true);
  const zip = new JSZip();
  const imagesFolder = zip.folder("images");
  if (imagesFolder && images.length > 0) {
    let index = 0;
    for (const img of images) {
      if (img?.image_url) {
        const imageBlob = await fetch(img?.image_url).then((response) => {
          return response.blob();
        });
        imagesFolder.file(`${index}.jpg`, imageBlob, {
          binary: true,
        });
        index++;
      }
    }
  }

  const dataFolder = zip.folder("data");
  if (dataFolder) {
    const ws = XLSX.utils.json_to_sheet(dataArray, {
      dateNF: "MM/DD/YYYY",
      skipHeader: false,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: CSV_FILETYPE });
    dataFolder.file(`${fileName}.xlsx`, data);
  }

  zip
    .generateAsync({ type: "blob" })
    .then((zipBlob) => {
      FileSaver.saveAs(zipBlob, `${fileName}-${DateFormat(new Date())}.zip`);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error generating the zip file:", error);
    });
}

const downloadPdf = async (url: string, fileName: string) => {
  const pdfUrl = url;

  try {
    const response = await fetch(pdfUrl);

    if (!response.ok) {
      throw new Error(`Failed to download PDF. Status: ${response.status}`);
    }

    // Use file-saver to save the blob as a file
    const blob = await response.blob();
    FileSaver.saveAs(blob, `${fileName}-${DateFormat(new Date())}.pdf`);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

export { csvExport, jsonExport, femaExport, downloadPdf };
