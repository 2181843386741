import { Td, Text, Tr } from "@chakra-ui/react";
import { damageLevels, infrastructureStatuses } from "constants/data";
import routes from "constants/routes";
import dayjs from "dayjs";
import { UserListItemType } from "interface/user/userListItem";
import React from "react";
import { useNavigate } from "react-router-dom";
import { checkNull } from "utils";

const InfrastructureListItem: React.FC<UserListItemType> = (props) => {
  const { listData } = props;

  const navigate = useNavigate();
  const handleShowDialog = (data: any) => {
    navigate(
      routes.infrastructures.bridge.assessment.list.replace(
        ":bridge_id",
        listData.id
      )
    );
  };

  return (
    <>
      <Tr key={listData?.id}>
        <Td>
          <Text
            color={"#3A3633"}
            fontWeight={"bold"}
            onClick={() => handleShowDialog(listData)}
            className={"name-pointer"}
          >
            {checkNull(listData?.structure) ? "-" : listData?.structure}
          </Text>
        </Td>
        <Td>
          <Text
            color={"#3A3633"}
            fontWeight={"bold"}
            onClick={() => handleShowDialog(listData)}
          >
            {listData?.bridge_name}
          </Text>
        </Td>
        <Td>
          <Text
            as={"span"}
            background={
              listData?.infrastructure_status === "DA"
                ? "#FC6C72"
                : listData?.infrastructure_status === "ND"
                ? "#8CB832"
                : listData?.infrastructure_status === "DS"
                ? "#ECD37B"
                : listData?.infrastructure_status === "DNS"
                ? "#AFAFAF"
                : listData?.infrastructure_status === "DSE"
                ? "repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px);"
                : listData?.infrastructure_status === "PDS"
                ? "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px);"
                : listData?.infrastructure_status === "NDS"
                ? "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px);"
                : "#ECA47B" // PD
            }
            color={"#000"}
            borderRadius={"lg"}
            className="infrastructure-status"
          >
            {infrastructureStatuses[listData?.infrastructure_status]}
          </Text>
        </Td>
        <Td>{damageLevels[listData?.event_type]}</Td>

        <Td>{dayjs(listData?.assessment_date).format("MM/DD/YY")}</Td>
        <Td>{dayjs(listData?.next_assessment_date).format("MM/DD/YY")}</Td>
      </Tr>
    </>
  );
};

export default InfrastructureListItem;
