import React, { Component, PropsWithChildren, ReactNode } from 'react';
import FallBackUI from './FallbackUI';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<{}>, ErrorBoundaryState> {
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <FallBackUI />;
    }

    return this.props.children as ReactNode;
  }
}

export default React.memo(ErrorBoundary);
