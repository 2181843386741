import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InfrastructureResource from "api/infrastructures";
import { AssessmentMap } from "components/assessment/AssessmentMap";
import { CenterSpinner } from "components/common/CenterSpinner";
import Pagination from "components/common/Pagination";
import TableHeaderComponent from "components/common/TableHeaderComponent";
import TableSkeletonLoader from "components/common/TableSkeletonLoader";
import InfraStructureAssessmentPDF from "components/infrastructure/InfraStructureAssessmentPDF";
import InfrastructureBridgeDetailListItem from "components/infrastructure/InfrastructureBridgeDetailListItem";
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from "constants/common";
import {
  damageLevels,
  infrastructureAssessmentListColumns,
  infrastructureStatuses,
} from "constants/data";
import routes from "constants/routes";
import dayjs from "dayjs";
import { FilterParams } from "interface/common/filterParam";
import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineLeft } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { FiCamera, FiUploadCloud } from "react-icons/fi";
import {
  MdOutlineDelete,
  MdOutlineDownload,
  MdOutlineRestartAlt,
} from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormat, DateFormatYMD, getStartingSerialNumber } from "utils";
import { femaExport } from "utils/fileExport";
import { getFullName } from "utils/recon-command-center";
import BridgePlaceholder from "../../../assets/images/bridgePlaceholder.webp";

const InfrastructureBridgeDetail: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isUploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const selectDownloadRef = useRef<HTMLSelectElement | null>(null);
  const [fileName, setFileName] = useState("");
  const queryClient = useQueryClient();
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean | null>(null);

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const infrastructureAPI = new InfrastructureResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: "",
  });

  const { data: bridgeDetail } = useQuery([`bridgeDetail-${params?.bridge_id}`], async () => {
    const response = await infrastructureAPI.getInfrastructureDetail(
      params?.bridge_id ? Number(params.bridge_id) : 0
    );

    return response?.data;
  });

  const { data: assessmentList, isLoading: isAssessmentLoading } = useQuery(
    [
      `assessmentList-${params?.bridge_id}`,
      {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
      };
      if (filterParams.keyword !== "")
        queryParams.keyword = filterParams.keyword;
      const response = await infrastructureAPI.getInfrastructureAssessment(
        params?.bridge_id ? Number(params.bridge_id) : 0,
        queryParams
      );

      return response?.data;
    }
  );

  const methods = useForm<any>();
  const methodsImage = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      is_approved: data.is_approved || 0,
      search: data.name,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const goToPrevPage = () => {
    navigate(routes.infrastructures.list);
    
  };



// useEffect(()=>{
// queryClient.refetchQueries({queryKey:["bridgeDetail"]});
// queryClient.refetchQueries({queryKey:["bridgeDetail"]});
// queryClient.refetchQueries({queryKey:[
//   "assessmentList",
//   {
//     page: filterParams.currentPage,
//     page_size: filterParams.pageSize,
//     keyword: filterParams.keyword,
//   },
// ]})
// },[])

  const resetMutation = useMutation(
    (id: number) => infrastructureAPI.infrastuctureReset(id),
    {
      onSuccess: (res: any) => {
        toast({
          title: `Status has been changed.`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        toast({
          title: `${error?.response?.data?.detail}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const handleResetStatus = () => {
    if (params?.bridge_id) {
      resetMutation.mutate(+params?.bridge_id);
    }
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDownloadModalClose = () => {
    setDownloadModalOpen(false);
  };

  const handleSelectClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      console.log("Selected file:", selectedFile);
      setFileName(selectedFile?.name);
      methodsImage.setValue("image", selectedFile);
    }
  };

  const imageUploadMutation = useMutation(
    (data: any) =>
      infrastructureAPI.updateInfrastructureImage(params?.bridge_id, data, {
        "content-type": "multipart/form-data",
      }),
    {
      onSuccess: (res: any) => {
        setUploadImageModalOpen(false);
        console.log("res", res);
        queryClient.invalidateQueries("bridgeDetail");
      },
      onError: (error: any) => {
   
      },
    }
  );

  const onSubmitImageUpload = (data: any) => {
    imageUploadMutation.mutate(data);
  };

  const handleCamera = () => {
    setUploadImageModalOpen(true);
    setFileName("");
  };

  const deleteInfraStructureMutation = useMutation(
    (id: number) => infrastructureAPI.destroy(id),
    {
      onSuccess: (res: any) => {
        navigate(routes.infrastructures.list);
      },
      onError: (error: any) => {
        toast({
          title: `${error?.response?.data?.detail}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const onDeleteConfirm = () => {
    if (params?.bridge_id) {
      deleteInfraStructureMutation.mutate(+params?.bridge_id);
    }
  };

  const [showPDF, setShowPDF] = useState(false);
  const handleDownload = async () => {
    if (selectDownloadRef.current?.value) {
      switch (selectDownloadRef.current?.value) {
        case "fema":
          const dataArray = assessmentList?.data?.map((res: any) => {
            return {
              "ASSESSMENT DATE": dayjs(res?.date).format("MM/DD/YY"),
              "INFRASTRUCTURE STATUS":
                infrastructureStatuses[res?.damage_level],
              "EVENT TYPE": damageLevels[res?.event_type],
              "INSPECTED BY": getFullName(res?.user),
            };
          });
          const images = [
            {
              image_url: bridgeDetail?.image,
              image_name: bridgeDetail?.bridge_name,
            },
          ];
          await femaExport(images, dataArray, "Bridge", setLoading);
          setDownloadModalOpen(false);
          break;
        case "pdf":
          setShowPDF(true);
          break;
        default:
          setDownloadModalOpen(false);
      }
    }
  };

  const handleDownloadChange = (e: any) => {
    if (e?.target?.value === "pdf") {
      setShowPDF(true);
    } else {
      setShowPDF(false);
    }
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection="column" pl={"60px"} width={"85%"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection="row"
          marginY="22px"
        >
          <Box
            flex={0.4}
            flexDirection="row"
            display={"flex"}
            alignItems={"center"}
          >
            <AiOutlineLeft
              size={20}
              onClick={goToPrevPage}
              cursor={"pointer"}
            />
            <Text fontSize={20} color="black" ml={"5px"}>
              Bridge {bridgeDetail?.structure}
            </Text>
          </Box>

          <Flex justify="space-between">
            <Button
              leftIcon={<MdOutlineRestartAlt />}
              variant={"white"}
              onClick={() => handleResetStatus()}
            >
              Reset Status
            </Button>
            <Button
              leftIcon={<MdOutlineDownload />}
              variant={"white"}
              onClick={() => setDownloadModalOpen(true)}
            ></Button>
            <Button
              leftIcon={<MdOutlineDelete />}
              variant={"white"}
              onClick={() => setDeleteModalOpen(true)}
            ></Button>
          </Flex>
     
        </Box>

        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Box maxWidth={"40%"} minWidth={"40%"} flexDirection="column" pr={4}>
            <Box display={"flex"} flexDirection="column">
              <Box position="relative">
                {!isImageLoaded === null && <CenterSpinner />}

                {isImageLoaded !== false && (
                  <Image
                    className="assessment-gallery-thumb"
                    minWidth="100%"
                    height="auto"
                    src={bridgeDetail?.image}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                )}

                {(bridgeDetail?.image === null || isImageLoaded === false) && (
                  <Image
                    className="assessment-gallery-thumb"
                    minWidth="100%"
                    height="auto"
                    // objectFit={"contain"}
                    src={BridgePlaceholder}
                    alt="Bridge Image"
                  />
                )}
                <Icon
                  as={FiCamera}
                  position="absolute"
                  w={6}
                  h={6}
                  color="black"
                  top="1"
                  right="2"
                  cursor={"pointer"}
                  onClick={() => handleCamera()}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              borderWidth={"1px"}
              borderColor="#DEDEDE"
              borderRadius={"8px"}
              className={"bridgedetails-wrap"}
            >
              {/* <Text color={"#8B8B8B"} fontWeight="semibold" fontSize={"12px"}>
                Type : {bridgeDetail?.infrastructure_type}
              </Text> */}
              <Text color={"#8B8B8B"} fontWeight="semibold" fontSize={"12px"}>
                Next Assessment:{" "}
                {DateFormatYMD(bridgeDetail?.next_assessment_date)}
              </Text>
              <Text color={"#8B8B8B"} fontWeight="semibold" fontSize={"12px"}>
                Risk:{" "}
                {infrastructureStatuses[bridgeDetail?.infrastructure_status]}
              </Text>
            </Box>
          </Box>
          <Box maxWidth={"60%"} minWidth={"60%"} flexDirection="column">
            <AssessmentMap
              status={bridgeDetail?.infrastructure_status}
              geometry={bridgeDetail?.geometry_json?.coordinates}
            />
          </Box>
        </Box>

        <Stack direction="column" spacing="1" mt={8}>
          <Flex justify={"space-between"}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputGroup>
                <InputLeftElement
                  children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
                />
                <Input
                  w={[96, 60, 60, 96]}
                  type="text"
                  placeholder="Search"
                  {...methods.register("search")}
                  onBlur={(e: any) => {
                    setFilterParams((prevState: any) => ({
                      ...prevState,
                      keyword: e.target.value,
                    }));
                  }}
                />
              </InputGroup>
            </form>

            {!isAssessmentLoading && (
              <Pagination
                dataList={assessmentList}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
              />
            )}
          </Flex>

          <Stack bg="white" shadow="box" rounded="sm">
            <TableContainer mt={4}>
              <Table>
                <TableHeaderComponent
                  columns={infrastructureAssessmentListColumns}
                  showDelete={loggedInUser?.role === "CCA" ? true : false}
                  setFilterParams={setFilterParams}
                />
                <Tbody>
                  {!isAssessmentLoading &&
                    assessmentList?.data?.map(
                      (listData: any, index: number) => (
                        <InfrastructureBridgeDetailListItem
                          listData={listData}
                          key={listData.id}
                          index={startingSN + index}
                        />
                      )
                    )}
                  {isAssessmentLoading && (
                    <TableSkeletonLoader
                      rows={filterParams.pageSize}
                      cols={6}
                    />
                  )}
                  {!isAssessmentLoading && assessmentList.data.length === 0 && (
                    <Tr>
                      <Td colSpan={5}>
                        <Center color={"#605A54"} w={"100%"}>
                          No assessments found
                        </Center>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Box>

      {/* Upload Image Modal Box */}
      <Modal
        isOpen={isUploadImageModalOpen}
        isCentered
        onClose={() => setUploadImageModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <FormProvider {...methodsImage}>
              <form onSubmit={methodsImage.handleSubmit(onSubmitImageUpload)}>
                <Center>
                  <VStack>
                    <IconButton
                      variant={"unstyled"}
                      aria-label="UploadImage"
                      icon={<FiUploadCloud />}
                    />
                    <Input
                      {...methodsImage.register("image")}
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    <Button onClick={handleSelectClick}>Select File</Button>
                    {fileName && <Text color={"primary"}>{fileName}</Text>}
                    <Text
                      colorScheme="gray.200"
                      color={"primary"}
                      fontSize="xs"
                    >
                      Upload image file
                    </Text>
                    <Text color={"primary"}>
                      Upload the photo you would like to update of this bridge.
                    </Text>
                    <ButtonGroup mt={"1.5rem !important"}>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={imageUploadMutation.isLoading}
                      >
                        {"Update"}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => setUploadImageModalOpen(false)}
                      >
                        {"Cancel"}
                      </Button>
                    </ButtonGroup>
                  </VStack>
                </Center>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Download Modal Box */}
      <Modal
        isOpen={isDownloadModalOpen}
        isCentered
        onClose={onDownloadModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Center>
              <VStack>
                <IconButton
                  variant={"unstyled"}
                  aria-label="Download"
                  icon={<MdOutlineDownload />}
                />
                <Select
                  placeholder="Format Type"
                  ref={selectDownloadRef}
                  onChange={handleDownloadChange}
                >
                  <option value="fema">FEMA Form</option>
                  <option value="pdf">PDF</option>
                </Select>
                <ButtonGroup mt={"1.5rem !important"}>
                  {showPDF && (
                    <PDFDownloadLink
                      onClick={() => setDownloadModalOpen(false)}
                      document={
                        <InfraStructureAssessmentPDF
                          bridgeDetail={bridgeDetail}
                          assessmentList={assessmentList?.data}
                        />
                      }
                      fileName={`Bridge-${DateFormat(new Date())}.pdf`}
                    >
                      {({ blob, url, loading, error }) => {
                        return (
                          <Button
                            colorScheme="primary"
                            isLoading={loading}
                            loadingText="Loading..."
                          >
                            Download
                          </Button>
                        );
                      }}
                    </PDFDownloadLink>
                  )}

                  {!showPDF && (
                    <Button colorScheme="primary" onClick={handleDownload}>
                      {"Download"}
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    onClick={() => setDownloadModalOpen(false)}
                  >
                    {"Cancel"}
                  </Button>
                </ButtonGroup>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Delete Modal Box */}
      <Modal isOpen={isDeleteModalOpen} isCentered onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Center>
              <VStack>
                <IconButton
                  variant={"unstyled"}
                  aria-label="Delete"
                  icon={<MdOutlineDelete />}
                />
                <Text color={"primary"} as={"b"}>
                  Are you sure you want to delete?
                </Text>
                <Text color={"primary"} textAlign={"center"}>
                  Once you confirm deleting this you will not have a chance to
                  undo.
                </Text>
                <ButtonGroup mt={"1.5rem !important"}>
                  <Button
                    colorScheme="primary"
                    onClick={onDeleteConfirm}
                    isLoading={deleteInfraStructureMutation.isLoading}
                  >
                    {"Yes, Delete"}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    {"Cancel"}
                  </Button>
                </ButtonGroup>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default React.memo(InfrastructureBridgeDetail);
