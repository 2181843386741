import PageNotFound from "components/errors/PageNotFound";
import PrivacyPolicy from "components/privacypolicy/privacypolicy";
import TermsAndConditions from "components/termsandconditions/termsandconditions";
import routes from "constants/routes";
import { Login } from "pages/auth";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import SetPassword from "pages/auth/SetPassword";
import Chat from "pages/dashboard/chat/Chat";
import HomeMap from "pages/dashboard/home/HomeMap";
import AssessmentDetail from "pages/dashboard/infrastructure/AssessmentDetail";
import FemaFormPage from "pages/dashboard/infrastructure/FemaFormPage";
import InfrastructureBridgeDetail from "pages/dashboard/infrastructure/InfrastructureBridgeDetail";
import InfrastructureList from "pages/dashboard/infrastructure/InfrastructureList";
import EditOrganization from "pages/dashboard/organizations/EditOrganization";
import Setting from "pages/dashboard/settings/Setting";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticateRoute from "./AuthenticateRoute";

// Top level application router.

const Router: React.FC = () => {
  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.auth.setPassword} element={<SetPassword />} />
        <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
        {/* <Route path={routes.map_mobile} element={<MobileMap />} /> */}
        <Route path={routes.privacy_policy} element={<PrivacyPolicy />} />
        <Route
          path={routes.terms_conditions}
          element={<TermsAndConditions />}
        />

        <Route path={routes.dashboard} element={<AuthenticateRoute />}>
          {/** Bridges */}
          <Route path={routes.bridges.list} element={<HomeMap />} />
          {/** End Bridges */}

          {/** Assessment */}
          <Route
            path={routes.infrastructures.list}
            element={<InfrastructureList />}
          />
          <Route
            path={routes.infrastructures.bridge.assessment.list}
            element={<InfrastructureBridgeDetail />}
          />
          <Route
            path={routes.infrastructures.bridge.assessment.detail}
            element={<AssessmentDetail />}
          />
          <Route
            path={routes.infrastructures.bridge.assessment.fema_form}
            element={<FemaFormPage />}
          />
          {/** End Assessment */}

          {/** Chat */}
          <Route path={routes.chats.list} element={<Chat />} />
          {/** End Assessment */}

          {/** Settings */}
          <Route path={routes.settings.index} element={<Setting />} />
          {/** End Settings */}

          {/** Organizations */}
          <Route
            path={routes.organization.edit.replace(":id", user.organization_id)}
            element={<EditOrganization />}
          />
          {/** End Organizations */}
        </Route>
        {/* <Route path={routes.map_mobile} element={<MobileMap />} /> */}
        <Route path={routes.remaining} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
