import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import AssessmentResource from "api/assessment";
import { damageLevels, infrastructureStatuses } from "constants/data";
import routes from "constants/routes";
import dayjs from "dayjs";
import { UserListItemType } from "interface/user/userListItem";
import React, { useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { useMutation, useQueryClient } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { parsedURL } from "utils";
import { downloadPdf } from "utils/fileExport";
import { getFullName } from "utils/recon-command-center";

const InfrastructureBridgeDetailListItem: React.FC<UserListItemType> = (
  props
) => {
  const { listData } = props;
  const [deleteMsg, setDeleteMsg] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isFemaModalOpen, setFemaModalOpen] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const assessmentAPI = new AssessmentResource();

  const params = useParams();
  const currentBridgeId = params?.bridge_id;
  const navigate = useNavigate();
  const handleShowDialog = (data: any) => {
    navigate(
      routes.infrastructures.bridge.assessment.detail
        .replace(":bridge_id", currentBridgeId ? currentBridgeId : "")
        .replace(":assessment_id", listData.id)
    );
  };

  const deleteMutation = useMutation(
    () => assessmentAPI.destroy(listData?.id),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "It has been deleted!",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries("assessmentList");

        setDeleteModalOpen(false);
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable to delete at this moment!",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg("");
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteMutation.mutate();
  };

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const handleFemaDoc = () => {
    setFemaModalOpen(true);
  };

  const onFemaModalClose = () => {
    setFemaModalOpen(false);
  };

  return (
    <>
      <Tr key={listData?.id}>
        <Td
          onClick={() => handleShowDialog(listData)}
          className={"name-pointer"}
        >
          {dayjs(listData?.date).format("MM/DD/YY")}
        </Td>

        <Td>
          {listData?.damage_level && (
            <Text
              as={"span"}
              backgroundColor={
                listData?.damage_level === "DA"
                  ? "#FC6C72"
                  : listData?.damage_level === "ND"
                  ? "#8CB832"
                  : listData?.damage_level === "DS"
                  ? "#ECD37B"
                  : listData?.damage_level === "DNS"
                  ? "#AFAFAF"
                  : listData?.damage_level === "DSE"
                  ? "repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px)"
                  : listData?.damage_level === "PDS"
                  ? "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px)"
                  : listData?.damage_level === "NDS"
                  ? "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px)"
                  : "#ECA47B" // PD
              }
              color={"#FFFFFF"}
              borderRadius={"lg"}
              className="infrastructure-status"
            >
              {infrastructureStatuses[listData?.damage_level]}
            </Text>
          )}
        </Td>
        <Td>{listData?.event_type && damageLevels[listData?.event_type]}</Td>
        <Td>{getFullName(listData?.user)}</Td>
        <Td
          _hover={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handleFemaDoc}
        >
          {listData?.fema_docs?.length > 0 && `Link`}
        </Td>
        {loggedInUser?.role === "CCA" && (
          <Td _hover={{ cursor: "pointer" }}>
            <CgTrashEmpty onClick={() => setDeleteModalOpen(true)} />
          </Td>
        )}
        <Modal
          isOpen={isDeleteModalOpen}
          id={"delete_modal"}
          isCentered
          onClose={onDeleteModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{"Delete"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody boxShadow={"none !important"}>
              {"Are you sure you want to delete this ?"}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteMutation.isLoading}
                >
                  {"Delete"}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {"Cancel"}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isFemaModalOpen}
          id={"Fema_modal"}
          isCentered
          onClose={onFemaModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody boxShadow={"none !important"}>
              {listData?.fema_docs?.map((res: string) => (
                <>
                  <List spacing={3}>
                    <ListItem
                      _hover={{
                        color: "#000",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color={"gray"}
                      onClick={() =>
                        downloadPdf(res, parsedURL(res) || "download")
                      }
                    >
                      {parsedURL(res)}
                    </ListItem>
                  </List>
                </>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Tr>
    </>
  );
};

export default InfrastructureBridgeDetailListItem;
