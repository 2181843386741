import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import chat from "./chat";
import organization from "./organization";

/**
 * Persist Auth Reducer.
 */
const authPersistConfig = {
  key: "auth",
  storage: storage,
};

const chatPersistConfig = {
  key: "chat",
  storage: storage,
};

const organizationPersistConfig = {
  key: "organization",
  storage: storage,
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  chat: persistReducer(chatPersistConfig, chat),
  organization: persistReducer(organizationPersistConfig, organization),
});
