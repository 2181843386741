import { Box, Stack } from "@chakra-ui/react";
import TopBar from "components/layout/TopBar";
import './privacypolicy.css';

const PrivacyPolicy = () => {
  return (
    <>
      <Box bg="gray.50">
        <TopBar />
      </Box>
      <Stack
        m="4"
        bgColor={"#FFF"}
      >
          <p className="c2"><span className="c3">PRIVACY NOTICE</span><span className="c0">&nbsp;</span></p>
          <p className="c2"><span className="c0">Last updated April 5, 2023 </span></p>

            <div className="c2"><span className="c0">&nbsp;</span></div>

            <p className="c2"><span>This privacy notice for </span><span className="c3">KIHOMAC RECON</span><span>("</span><span
                  className="c3">Company</span><span>," "</span><span className="c3">we</span><span>," "</span><span
                  className="c3">us</span><span>," or "</span><span className="c3">our</span><span>"), describes how and why we might
                  collect, store, use, and/or share ("process") your information when you use our services ("</span><span
                  className="c3">Services</span><span className="c0">"), such as when you: </span></p>

            <p className="c2"><span className="c0">Visit our website at kihomac.us, or any website of ours that links to this
                  privacy notice </span></p>

            <p className="c2"><span>Download and use our mobile application (</span><span className="c3">KIHOMAC RECON</span><span
                  className="c0">), or any other application of ours that links to this privacy notice </span></p>

            <p className="c2"><span className="c0">Engage with us in other related ways, including any sales, marketing, or events
              </span></p>

            <p className="c2"><span>Questions or concerns? Reading this privacy notice will help you understand your privacy rights
                  and choices. If you do not agree with our policies and practices, please do not use our Services. If you still
                  have any questions or concerns, please contact us at </span><span
                  className="c3">reconsupport@kihomac.us</span><span className="c0">. </span></p>

            <p className="c2"><span className="c0">&nbsp; </span></p>

            <p className="c2"><span className="c3">TABLE OF CONTENTS</span><span className="c0">&nbsp;</span></p>

            <p className="c2"><span className="c0">1. WHAT INFORMATION DO WE COLLECT? </span></p>

            <p className="c2"><span className="c0">2. HOW DO WE PROCESS YOUR INFORMATION? </span></p>

            <p className="c2"><span className="c0">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </span></p>

            <p className="c2"><span className="c0">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </span></p>

            <p className="c2"><span className="c0">5. HOW LONG DO WE KEEP YOUR INFORMATION? </span></p>

            <p className="c2"><span className="c0">6. HOW DO WE KEEP YOUR INFORMATION SAFE? </span></p>

            <p className="c2"><span className="c0">7. WHAT ARE YOUR PRIVACY RIGHTS? </span></p>

            <p className="c2"><span className="c0">8. CONTROLS FOR DO-NOT-TRACK FEATURES </span></p>

            <p className="c2"><span className="c0">9. DO WE MAKE UPDATES TO THIS NOTICE? </span></p>

            <p className="c2"><span className="c0">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </span></p>

            <p className="c2"><span className="c0">&nbsp; </span></p>

            <p className="c2"><span className="c0">&nbsp;</span></p>

            <p className="c2"><span className="c4">1. WHAT INFORMATION DO WE COLLECT?</span><span className="c0">&nbsp;</span></p>

            <p className="c2"><span className="c0">Personal information you disclose to us. In Short: We collect personal information
                  that you provide to us. </span></p>

            <p className="c2"><span className="c0">We collect personal information that you voluntarily provide to us when you register
                  on the Services, express an interest in obtaining information about us or our products and Services, when you
                  participate in activities on the Services, or otherwise when you contact us. </span></p>

            <p className="c2"><span className="c0">Personal Information Provided by You. The personal information that we collect depends
                  on the context of your interactions with us and the Services, the choices you make, and the products and
                  features you use. The personal information we collect may include the following: </span></p>

                  <ul className="c7 lst-kix_aol0lbh6x718-0 start">

                    <li className="c1 li-bullet-0"><span className="c0">names </span></li>

                    <li className="c1 li-bullet-0"><span className="c0">email addresses </span></li>

                  </ul>

                  <ul className="c7 lst-kix_lxo0h7tt4fun-0 start">

                    <li className="c1 li-bullet-0"><span className="c0">usernames </span></li>

                    <li className="c1 li-bullet-0"><span className="c0">passwords </span></li>

                    <li className="c1 li-bullet-0"><span className="c0">contact or authentication data </span></li>
                    
                    <li className="c1 li-bullet-0"><span className="c0">location </span></li>

                  </ul>


                  <p className="c2"><span className="c0">Sensitive Information. We do not process sensitive information. All personal
                        information that you provide to us must be true, complete, and accurate, and you must notify us of any changes
                        to such personal information. </span></p>

                  <p className="c2"><span className="c0">&nbsp;</span></p>

                  <p className="c2"><span className="c4">2. HOW DO WE PROCESS YOUR INFORMATION?</span><span className="c0">&nbsp;</span></p>

                  <p className="c2"><span className="c0">In Short: We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
                        your information for other purposes with your consent. </span></p>

                  <p className="c2"><span className="c0">We process your personal information for a variety of reasons, depending on how you
                        interact with our Services, including: </span></p>

                  <p className="c2"><span className="c0">To facilitate account creation and authentication and otherwise manage user accounts.
                        We may process your information so you can create and log in to your account, as well as keep your account in
                        working order. </span></p>


                        <p className="c2"><span className="c0">To request feedback. We may process your information when necessary to request
                            feedback and to contact you about your use of our Services. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span><span
                              className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">In Short: We may share information in specific situations described in this section
                              and/or with the following third parties. </span></p>

                        <p className="c2"><span className="c0">We may need to share your personal information in the following situations:
                              &nbsp;</span></p>

                        <p className="c2"><span className="c0">Business Transfers. We may share or transfer your information in connection with, or
                              during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
                              our business to another company. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span><span
                              className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">In Short: We may use cookies and other tracking technologies to collect and store your
                              information. We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                              store information. Specific information about how we use such technologies and how you can refuse certain
                              cookies is set out in our Cookie Notice. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">In Short: We keep your information for as long as necessary to fulfill the purposes
                              outlined in this privacy notice unless otherwise required by law. </span></p>
                        
                        <p className="c2"><span className="c0">We will only keep your personal information for as long as it is necessary for the
                              purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such
                              as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your
                              personal information for longer than the period of time in which users have an account with us. When we have no
                              ongoing legitimate business need to process your personal information, we will either delete or anonymize such
                              information, or, if this is not possible (for example, because your personal information has been stored in
                              backup archives), then we will securely store your personal information and isolate it from any further
                              processing until deletion is possible. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">In Short: We aim to protect your personal information through a system of
                              organizational and technical security measures. We have implemented appropriate and reasonable technical and
                              organizational security measures designed to protect the security of any personal information we process.
                              However, despite our safeguards and efforts to secure your information, no electronic transmission over the
                              Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                              guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our
                              security and improperly collect, access, steal, or modify your information. Although we will do our best to
                              protect your personal information, transmission of personal information to and from our Services is at your own
                              risk. You should only access the Services within a secure environment. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">7. WHAT ARE YOUR PRIVACY RIGHTS?</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span>In Short: &nbsp;You may review, change, or terminate your account at any time.</span></p>
                        <p className="c2"><span className="c0">Withdrawing your consent: If we are relying on your consent to process your personal
                              information, which may be express and/or implied consent depending on the applicable law, you have the right to
                              withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the
                              contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. </span></p>

                        <p className="c2"><span className="c0">However, please note that this will not affect the lawfulness of the processing before
                              its withdrawal nor, when applicable law allows, will it affect the processing of your personal information
                              conducted in reliance on lawful processing grounds other than consent. </span></p>

                        <p className="c2"><span className="c4">Account Information</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">If you would at any time like to review or change the information in your account or
                              terminate your account, you can: &nbsp;</span></p>

                        <p className="c2"><span className="c0">Log in to your account settings and update your user account. </span></p>

                        <p className="c2"><span className="c0">Contact us using the contact information provided. </span></p>

                        <p className="c2"><span className="c0">Upon your request to terminate your account, we will deactivate or delete your account
                              and information from our active databases. However, we may retain some information in our files to prevent
                              fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                              applicable legal requirements. </span></p>

                        <p className="c2"><span>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
                              prefer, you can usually choose to set your browser to remove cookies and to reject cookies. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">8. CONTROLS FOR DO-NOT-TRACK FEATURES</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">Most web browsers and some mobile operating systems and mobile applications include a
                              Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data
                              about your online browsing activities monitored and collected. At this stage no uniform technology standard for
                              recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
                              browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If
                              a standard for online tracking is adopted that we must follow in the future, we will inform you about that
                              practice in a revised version of this privacy notice. </span></p>

                        <p className="c2"><span className="c0">&nbsp; &nbsp;</span></p>


                        <p className="c2"><span className="c4">9. DO WE MAKE UPDATES TO THIS NOTICE?</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">In Short: Yes, we will update this notice as necessary to stay compliant with relevant
                              laws. </span></p>

                        <p className="c2"><span className="c0">We may update this privacy notice from time to time. The updated version will be
                              indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.
                              If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of
                              such changes or by directly sending you a notification. We encourage you to review this privacy notice
                              frequently to be informed of how we are protecting your information. </span></p>

                        <p className="c2"><span className="c0">&nbsp; </span></p>

                        <p className="c2"><span className="c4">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span className="c0">&nbsp;</span></p>

                        <p className="c2"><span className="c0">If you have questions or comments about this notice, you may email us at reconsupport@kihomac.us </span>
                            </p>
           
      </Stack>
    </>
  );
};

export default PrivacyPolicy;
