// @ts-nocheck

import "@arcgis/core/assets/esri/themes/light/main.css";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

import "App.css";

import { getGeoJsonLayer, getGeoJsonLayerBridge } from "./Layers";
import {
  JoinedLayerTemplate,
  rendererProps,
  SurveyorSymbol,
} from "./MapConstants";
import { createLayer } from "./MapCustomFunctions";
import { query } from "./Queries";
// import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export const initialize = async (
  container: any,
  views: any,
  app: any,
  infrastructureLists: any,
  hexUrl:string,

) => {
  console.log(hexUrl,'hex url ')
  views?.ui?.move("zoom", "bottom-right");
  views?.ui?.remove("attribution");
  views.when(() => {
    views.goTo(
      {
        center: [
          infrastructureLists?.features[0].geometry?.coordinates[0] ||
            -112.029403,
          infrastructureLists?.features[0].geometry?.coordinates[1] ||
            40.732586000000005,
        ],
        zoom: 9,
      },
      {
        duration: 1000,
      }
    );
  });

  // console.log("infrastructureLists================",infrastructureLists )
  const { tessalationLayer: geojsonlayer } =
    getGeoJsonLayer(hexUrl);
  const { BridgeLayer: geojsonlayerBridge } =
    getGeoJsonLayerBridge(infrastructureLists);
  const features = [];

  try {
    // get features for layer and bridges
    const geoJsonResults = await geojsonlayer.queryFeatures(query);
    const geoJsonBridgeResult = await geojsonlayerBridge.queryFeatures(query);
    // assign to temp
    let temp = [...geoJsonBridgeResult.features];

    for (let feat of geoJsonResults.features) {
      // set default values
      feat.attributes.count = 0;
      feat.attributes.infrastructure_status = "";
      feat.attributes.last_inspected_role = "";

      const filteredBridges = temp.filter((currentBridge) => {
        return (
          currentBridge &&
          feat.geometry &&
          currentBridge.geometry &&
          geometryEngine.contains(feat.geometry, currentBridge.geometry)
        );
      });
      // console.log({ filteredBridges });

      // loop through briges details
      for (let i = 0; i < filteredBridges.length; i++) {
        // get current index bridge
        const currentBridge = filteredBridges[i];

        feat.attributes.infrastructure_status =
          currentBridge.attributes.infrastructure_status === "DA" ||
          feat.attributes.infrastructure_status === "DA"
            ? "DA"
            : currentBridge.attributes.infrastructure_status === "DSE" ||
              feat.attributes.infrastructure_status === "DSE"
            ? "DSE"
            : currentBridge.attributes.infrastructure_status === "DS" ||
              feat.attributes.infrastructure_status === "DS"
            ? "DS"
            : currentBridge.attributes.infrastructure_status === "PD" ||
              feat.attributes.infrastructure_status === "PD"
            ? "PD"
            : currentBridge.attributes.infrastructure_status === "PDS" ||
              feat.attributes.infrastructure_status === "PDS"
            ? "PDS"
            : currentBridge.attributes.infrastructure_status === "ND" ||
              feat.attributes.infrastructure_status === "ND"
            ? "ND"
            : currentBridge.attributes.infrastructure_status === "NDS" ||
              feat.attributes.infrastructure_status === "NDS"
            ? "NDS"
            : currentBridge.attributes.infrastructure_status === "DNS" ||
              feat.attributes.infrastructure_status === "DNS"
            ? "DNS"
            : feat.attributes.infrastructure_status;
        const currentRole = currentBridge.attributes.last_inspected_role;
        const featRole = feat.attributes.last_inspected_role;
        if (featRole === "EAUPU") {
          feat.attributes.last_inspected_role = "EAUPU";
        } else if (currentRole === "PU" || featRole === "PU") {
          feat.attributes.last_inspected_role = "PU";
        } else if (currentRole === "EAU" || featRole === "EAU") {
          feat.attributes.last_inspected_role = "EAU";
        } else if (
          (currentRole === "PU" && featRole === "EAU") ||
          (featRole === "PU" && currentRole === "EAU")
        ) {
          feat.attributes.last_inspected_role = "EAUPU";
        } else if (currentRole === null || featRole === null) {
          feat.attributes.last_inspected_role = "null";
        } else {
          feat.attributes.last_inspected_role = "*";
        }

        if (
          (currentRole === "PU" && featRole === "EAU") ||
          (featRole === "PU" && currentRole === "EAU")
        ) {
          feat.attributes.last_inspected_role = "EAUPU";
        }

        feat.attributes.count++;
        // filteredBridges.splice(i, 1);
        // }
      }
      features.push(feat);
    }

    // console.log("Featuers", features);

    // app.map.removeMany([geojsonlayer]);
  } catch (err) {
    console.log(err, "error in views.when");
  }
  const { featLayer: joinLayer, SurveyorLayer } = await createLayer(
    geojsonlayer,
    features,
    [
      {
        name: "count",
        alist: "Count",
        type: "integer",
      },
      {
        name: "infrastructure_status",
        alist: "Infrastructure_status",
        type: "string",
      },
      {
        name: "last_inspected_role",
        alist: "Last_inspected_role",
        type: "string",
      },
    ]
  );
  // console.log(joinLayer, "created join layerys");
  // const surveyFeatures = await SurveyorLayer.queryFeatures(query);
  // console.log(
  //   "Surveyor Feautures",
  //   surveyFeatures.features.filter((item) => item.attributes.count > 0)
  // );

  SurveyorLayer.renderer = SurveyorSymbol;
  joinLayer.renderer = rendererProps;

  joinLayer.screenSizePerspectiveEnabled = true;

  joinLayer.opacity = 0.8;
  joinLayer.maxScale = 145500;
  joinLayer.popupTemplate = JoinedLayerTemplate;

  return { container, geojsonlayerBridge, joinLayer, SurveyorLayer };
};
