export enum OrganizationActionTypes {
  SET_ORGANIZATION = 'SET_ORGANIZATION',
}

export interface OrganizationAction {
  data: any;
  type: OrganizationActionTypes;
}

/**
 * Action to get organization detail.
 * @param {object} organizationDetail
 * @returns {object}
 */
export function setOrganizationDetail(organizationDetail: any): OrganizationAction {
  return {
    data: organizationDetail,
    type: OrganizationActionTypes.SET_ORGANIZATION,
  };
}
