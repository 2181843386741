import * as authService from "services/auth";

const accessToken = authService.getAccessToken();

export function createSocket(): WebSocket {
  return new WebSocket(
    `wss://api.kihomac.us/ws/socket-server/?token=${accessToken}`
  );
}

let socket: WebSocket = createSocket();
export function reconnect() {
  socket = createSocket();
}
export function connectSocket() {
  console.log("Socket has been connected");
  const joinRoom = {
    event_type: "join_room",
  };
  socket.send(JSON.stringify(joinRoom));
}

function handleWebSocketError(event: Event) {
  console.error("Error on socket", event);
  setTimeout(() => {
    socket = createSocket();
    setupSocketEventListeners();
  }, 5000); // Reconnect after 5 seconds
}

function handleWebSocketClose(event: CloseEvent) {
  console.log("Socket Closed", event);
  socket = createSocket();
  setupSocketEventListeners();
}

function setupSocketEventListeners() {
  socket.onopen = () => {
    connectSocket();
  };

  socket.onerror = (event: Event) => {
    handleWebSocketError(event);
  };

  socket.onclose = (event: CloseEvent) => {
    handleWebSocketClose(event);
  };
}
socket.addEventListener("error", (event) => {
  console.log("WebSocket error: ", event);
});
setupSocketEventListeners();

export { socket };
