import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import Pagination from "components/common/Pagination";
import InfrastructureListItem from "components/infrastructure/InfrastructureListItem";
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from "constants/common";
import { assessmentListColumns } from "constants/data";
import { FilterParams } from "interface/common/filterParam";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { useQuery } from "react-query";
import { getStartingSerialNumber } from "utils";

import InfrastructureResource from "api/infrastructures";
import TableHeaderComponent from "components/common/TableHeaderComponent";
import TableSkeletonLoader from "components/common/TableSkeletonLoader";
import BarChart from "components/infrastructure/BarChart";
import { AiOutlineBarChart, AiOutlineTable } from "react-icons/ai";

const InfrastructureList: React.FC = () => {
  const infrastructureAPI = new InfrastructureResource();
  const [currentView, setCurrentView] = useState("table");

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    sort_by: "",
    keyword: "",
  });

  const { data: infrastructures, isLoading: isListLoading } = useQuery(
    [
      "infrastructureList",
      {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        sort_by: filterParams.sort_by,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
      };
      if (filterParams.keyword !== "")
        queryParams.keyword = filterParams.keyword;

      if (filterParams.sort_by !== "")
        queryParams.sort_by = filterParams.sort_by;

      const response = await infrastructureAPI.list(queryParams);

      return response?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.name,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleView = (viewType: string) => {
    setCurrentView(viewType);
  };

  return (
    <>
      <Stack direction="column" spacing="4" className="Assessments--wrapper">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md" color={"#23282C"}>
              Assessments
            </Heading>
          </Stack>
          <ButtonGroup size="sm" isAttached>
            <Button
              leftIcon={<AiOutlineTable />}
              onClick={() => handleView("table")}
              colorScheme={currentView === "table" ? "primary" : undefined}
            >
              Table View
            </Button>
            <Button
              leftIcon={<AiOutlineBarChart />}
              onClick={() => handleView("chart")}
              colorScheme={currentView === "chart" ? "primary" : undefined}
            >
              Chart View
            </Button>
          </ButtonGroup>
        </Flex>

        {currentView === "table" && (
          <>
            <Flex justify={"space-between"}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon color="#737373" w="4" h="4" as={BiSearch} />
                    }
                  />
                  <Input
                    w={[96, 60, 60, 96]}
                    type="text"
                    placeholder="Search"
                    {...methods.register("search")}
                    onBlur={(e: any) => {
                      setFilterParams((prevState: any) => ({
                        ...prevState,
                        keyword: e.target.value,
                      }));
                    }}
                  />
                </InputGroup>
              </form>

              {!isListLoading && (
                <Pagination
                  dataList={infrastructures}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                />
              )}
            </Flex>

            <Stack bg="white" shadow="box" rounded="sm">
              <TableContainer>
                <Table>
                  <TableHeaderComponent
                    columns={assessmentListColumns}
                    setFilterParams={setFilterParams}
                    showDelete={false}
                  />{" "}
                  <Tbody>
                    {!isListLoading &&
                      infrastructures?.data?.map(
                        (listData: any, index: number) => (
                          <InfrastructureListItem
                            listData={listData}
                            key={index}
                            index={startingSN + index}
                          />
                        )
                      )}
                    {isListLoading && (
                      <TableSkeletonLoader
                        rows={filterParams.pageSize}
                        cols={6}
                      />
                    )}
                    {!isListLoading && infrastructures?.data?.length === 0 && (
                      <Tr>
                        <Td colSpan={5}>
                          <Center color={"#605A54"} w={"100%"}>
                            No assessments found
                          </Center>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </>
        )}

        {currentView === "chart" && (
          <>
            <BarChart />
          </>
        )}
      </Stack>
    </>
  );
};

export default InfrastructureList;
