import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { setOrganizationDetail } from "actions/data/organization";
import OrganizationResource from "api/organization";
import ChatResource from "api/chat";
import OrganizationForm from "components/organization/OrganizationForm";
// import PermissionRequest from "constants/PermissionRequest";
import { UserSchema } from "interface/user/userListItem";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import AccessControl from "services/AccessControl";
import UserList from "../users/UserList";
import "./organization.css";
import { setUnreadCount } from "actions/data/chat";

const EditOrganization: React.FC = () => {
  const { pathname }: any = useLocation();

  const id = pathname.split("/")[pathname.split("/").length - 1];
  const dispatch = useDispatch();

  const [uploadDetail, setUploadDetail] = useState({ id: "", file_doc: "" });
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [uploadHexDetail, setUploadHexDetail] = useState({ id: "", file_doc: "" });
  const [isHexFileRemoved, setIsHexFileRemoved] = useState(false);

  const toast = useToast();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;

  const methods = useForm<UserSchema>();
  const organizationAPI = new OrganizationResource();
  const chatAPI = new ChatResource();

  // const { userPermission } = useSelector(
  //   (state: any) => ({
  //     userPermission: state?.data?.auth?.permissions,
  //   }),
  //   shallowEqual
  // );

  const [errorMessage, setErrorMessage] = useState<any>();
  const [listData, setListData] = useState<any>();

  useQuery(["organizationChats"], async () => {
    const response = await chatAPI.list();
    
    dispatch(setUnreadCount(response?.data?.unread_count));
  },{
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  useQuery(["organizationDetail"], async () => {
    const response = await organizationAPI.get(id);
    setListData(response?.data);
    dispatch(setOrganizationDetail(response.data))
    return response?.data?.data;
  },{
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const updateOrganization = useMutation(
    (data: any) => organizationAPI.update(id, data),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Organization detail has been updated.",
          status: "success",
          isClosable: true,
        });

        dispatch(
          setOrganizationDetail(res.data)
        );
      },
      onError: (error: any) => {
        if(error.response.status === 403){
          setErrorMessage(error.response.data.detail);
        }
        else{
          setErrorMessage(error.response.data.message);
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    // if(!userPermission.includes(PermissionRequest["manage:user"])) return false
    delete data.file_name;
    delete data.file_url;

    if (isFileRemoved) {
      data.file_url = "";
      data.file_name = "";
    }
    if(isHexFileRemoved){
      data.hex_file = "";
    }
        
    if (uploadHexDetail) {
      data.hex_file =
        uploadHexDetail?.file_doc !== "" ? uploadHexDetail?.file_doc : undefined;
      data.hex_file = data?.hex_file?.name;
    }
    if (uploadDetail) {
      data.file_url =
        uploadDetail?.file_doc !== "" ? uploadDetail?.file_doc : undefined;
      data.file_name = data?.file_doc?.name;
    }

    updateOrganization.mutate(data);
  };

  return (
    <Stack direction="column" spacing="4" className="organization--wrapper">
      <Flex justify="space-between">
        <Heading size="md" color={"#23282C"}>
          Edit Organization
        </Heading>
      </Flex>

      <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateOrganization.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <OrganizationForm
                listData={listData}
                setUploadDetail={setUploadDetail}
                setUploadHexDetail={setUploadHexDetail}
                setIsHexFileRemoved={setIsHexFileRemoved}
                setIsFileRemoved={setIsFileRemoved}
              />
              {/* <AccessControl
                allowedPermissions={[PermissionRequest["manage:user"]]}
                renderNoAccess={(data: any) => data}
              > */}
                <Button
                  mt={"8 !important"}
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateOrganization.isLoading}
                  backgroundColor={"#000000"}
                  w={"15%"}
                  rounded={"md"}
                  className={"edit-btn-wrapper"}
                  _hover={{ backgroundColor: "#000000" }}
                >
                  Save Changes
                </Button>
              {/* </AccessControl> */}
            </Stack>
          </form>
        </FormProvider>
      </Box>
      <UserList listData={listData} showRole={true} />
    </Stack>
  );
};

export default EditOrganization;
