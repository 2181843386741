import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import { default as ArcGISMap } from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { Box, ChakraProvider } from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import BridgeList from "components/bridges/BridgeList";
import { CenterSpinner } from "components/common/CenterSpinner";
import TessalationMap from "components/map/TessalationMap";
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from "constants/common";
import { FilterParams } from "interface/common/filterParam";
import http from "utils/http";

const HomeMap: React.FC = () => {
  const organizationAPI = new OrganizationResource();
  const mapRef = useRef<ArcGISMap>(null);
  const [featureLayer, setFeatureLayer] = useState<GeoJSONLayer>();
  const webmap = useRef(
    new ArcGISMap({
      basemap: "streets-navigation-vector",
    })
  );

  const app = {
    map: webmap.current,
    center: [-111.95292894799996, 40.75033736100005],
    scale: 25000,
  };

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: "",
  });

  const organisationData = useSelector((state: any) => state?.data?.organization?.organizationDetail);
  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  var { data: infrastructureList, isLoading: isListLoading, isSuccess } = useQuery(
    [
      "organizationInfrastructureList",
      {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        keyword: filterParams.keyword,
        sort_by: filterParams.sort_by,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.sort_by) queryParams.sort_by = filterParams.sort_by;

      const response = await organizationAPI.organizationInfrastructureList(
        user.organization_id ? Number(user.organization_id) : 0,
        queryParams
      );

      return response?.data;
    }
  );

  const { data: hexLayer } = useQuery(
    ["hexLayer"],
    async () => {
      if (organisationData?.hex_file) {
        const res = await http({
          url: organisationData.hex_file,
          method: "GET",
        });
        return res?.data;
      }
    },
    { enabled: !!(organisationData.hex_file && infrastructureList) }
  );

  const view = useRef(new MapView(app));

  if (isSuccess && infrastructureList) {
    view.current.goTo(
      {
        center: [
          infrastructureList[0].geometry_json?.coordinates[0] || -112.029403,
          infrastructureList[0].geometry_json?.coordinates[1] || 40.732586000000005,
        ],
        zoom: 9,
      },
      { duration: 1000 }
    );

    const modified = infrastructureList.map((data: any) => ({
      geometry: {
        type: "Point",
        coordinates: data?.geometry_json?.coordinates,
      },
      properties: data,
      type: "Feature",
    }));

    infrastructureList = {
      type: "FeatureCollection",
      name: "survey_repub",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS84",
        },
      },
      features: modified,
    };
  }

  const showLocation = async (item: any, feature: any) => {
    try {
      const specificQuery = {
        where: `id=${feature.properties.id}`,
        outFields: ["*"],
        returnGeometry: true,
      };
      view.current.popup.highlightEnabled = true;
      const result = await featureLayer?.queryFeatures(specificQuery);

      view.current.zoom = 15;
      view.current.openPopup({
        features: result?.features,
        updateLocationEnabled: true,
      });
    } catch (err) {
      console.error("Show Location Error", err);
    }
  };

  return (
    <ChakraProvider>
      {isListLoading && <CenterSpinner />}
      <Box display="flex" flexDirection="row" height="100%" width="100%">
        <Box flex="0.25" display="flex">
          {!isListLoading && (
            <BridgeList
              showLocation={showLocation}
              infrastructureLists={infrastructureList}
              setFilterParams={setFilterParams}
            />
          )}
        </Box>
        <Box flex="0.75">
          {!isListLoading && (
            <TessalationMap
              setBridgeLayer={setFeatureLayer}
              views={view.current}
              app={app}
              hexLayer={hexLayer}
              infrastructureLists={infrastructureList}
              mapRef={mapRef}
            />
          )}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default React.memo(HomeMap);
