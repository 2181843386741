import {
  setIsLoggedIn,
  setLoggedInUser,
  setLoggingIn,
  setPermissions,
  setPrimaryGroup,
} from "actions/data/auth";
import { setUnreadCount } from "actions/data/chat";
import { login, setFCMToken } from "api/auth";
import { ClientJS } from "clientjs";
import routes from "constants/routes";
import { getFirebaseToken } from "FirebaseConfig";
import { Component } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import * as authService from "services/auth";

/**
 * Auth state Higher Order Component.
 * Use this HOC if you need to use/modify User state.
 */
function withAuthState(WrappedComponent: any) {
  class Auth extends Component<any, any> {
    /**
     * Login user and save tokens and user data.
     *
     * @param {string} email
     * @param {string} password
     */
    login = async (email: string, password: string) => {
      try {
        // setPermissions
        const {
          setLoggingIn,
          setIsLoggedIn,
          setLoggedInUser,
          setPermissions,
          setPrimaryGroup,
        }: any = this.props;

        setLoggingIn(true);

        const { data } = await login({ email, password });
        const fcmToken = await getFirebaseToken();
        await authService.persist({
          token: data?.access,
          refreshToken: data?.refresh,
        });

        /**
         * set Firebase
         */
        data.user.fcmToken = "";
        data.user.deviceId = "";
        if (fcmToken) {
          const client = new ClientJS();
          const fingerprint = client.getFingerprint();
          await setFCMToken({ token: fcmToken }, fingerprint);
          data.user.fcmToken = fcmToken;
          data.user.deviceId = fingerprint;
        }
        await /**
         * Fetch permissions for a given users
         */
        setLoggingIn(false);
        setIsLoggedIn(true);
        setLoggedInUser(data?.user);
        setPermissions(data?.permissions);
        setUnreadCount(0);
        setPrimaryGroup(data?.primary_group);
        setTimeout(async () => {
          window.location.href = routes.organization.edit.replace(
            ":id",
            data?.user?.organization_id
          );
        }, 500);
      } catch (err: any) {
        console.log("Error", err);
        setLoggingIn(false);
        const errMsg = err?.response?.data?.message || "Invalid Credentials";
        throw new Error(errMsg);
      }
    };

    logout = async () => {
      authService.logout();
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          login={this.login}
          logout={this.logout}
        />
      );
    }
  }

  const mapStateToProps = (state: any) => {
    let { isLoggedIn, isLoggingIn, user, permissions, setPrimaryGroup } =
      state.data.auth;

    return {
      isLoggedIn,
      isLoggingIn,
      loggedInUser: user,
      permissions: permissions,
      setPrimaryGroup,
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
      {
        setLoggingIn,
        setIsLoggedIn,
        setLoggedInUser,
        setPermissions,
        setPrimaryGroup,
      },
      dispatch
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(Auth);
}

export { withAuthState };
