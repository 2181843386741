import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface ButtonConfig {
  label: string;
  colorScheme?: string;
  variant?: string;
  color?: string;
  identifier: string;
  onClick: (identifier: string) => void;
}

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  buttonGroup?: ButtonConfig[];
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  buttonGroup,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody boxShadow={"none !important"}>{children}</ModalBody>
        <ModalFooter>
          {buttonGroup && (
            <ButtonGroup>
              {buttonGroup.map((button, index) => (
                <Button
                  key={index}
                  colorScheme={button?.colorScheme}
                  color={button?.color}
                  variant={button?.variant}
                  onClick={() => button.onClick(button.identifier)}
                >
                  {button.label}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
