import Resource from "api/resource";
import http from "utils/http";
class AssessmentResource extends Resource {
  constructor() {
    super("geo/assessments");
  }

  storeFemaForm(resource: any, headers?: any) {
    return http({
      url: "geo/infrastructures/fema-form/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }

  getFemaForm(assessmentId: any) {
    return http({
      url: `geo/assessments/${assessmentId}/fema-form/`,
      method: "get",
    });
  }
}

export { AssessmentResource as default };
