//@ts-nocheck

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Feature from "@arcgis/core/widgets/Feature";
import { infrastructureStatuses } from "constants/data";
import { DateFormatYMD } from "utils";
import { SurveyorSymbol } from "./MapConstants";
import BridgePlaceHolder from "../../assets/images/bridgePlaceholder.webp"



export const customPopupFunction=(feature: Feature)=> {
  const div = document.createElement("div");
  div.innerHTML = `
  <a href="/infrastructures/bridge/${feature.graphic.attributes.id}" target="_blank">
  <div id="container" style="display: flex; width="375px" >
    <div style="width: 30%;">
      <img
      src="${
        feature.graphic.attributes.image
          ? feature.graphic.attributes.image
          : BridgePlaceHolder
      }" alt="Your Image" style="height: 100%;object-fit: contain;">
    </div>
    <div style="width: 70%; padding: 10px;">
      <div style="font-weight: 600;
      font-size: 16px;
      line-height: 22px;">${feature.graphic.attributes.bridge_name}</div>
      <div style="font-size:13px">${DateFormatYMD(
        feature.graphic.attributes.next_assessment_date
      )}
      </div>
      <div style="font-size:13px">${
        infrastructureStatuses[feature.graphic.attributes.infrastructure_status]
      }</div>
      </div>
    </div>
  </div>
  </a>
  `;
  
  return div;
  // <div style="font-size:13px">${DateFormatYMD(
  //   feature.graphic.attributes.next_assessment_date
  // )}</div>
  // <div style="font-size:13px">${
  //   infrastructureStatuses[
  //     feature.graphic.attributes.infrastructure_status
  //   ]
  // }</div>
  // <div style="font-size:13px">${feature.graphic.attributes.infrastructure_type}</div>
  //     <div style="font-size:13px">${feature.graphic.attributes.creator}</div>
}

export async function createLayer(layer: any, source: any, extraFields: any) {
  // console.log("entered into  CreateLayer");

  await layer.load();

  const fieldInfosMap = new Map();

  for (const field of [...layer.fields]) {
    fieldInfosMap.set(field.name, {
      fieldName: field.name,
      label: field.alias || field.name,
    });
  }
  const fieldInfos = [...new Set(fieldInfosMap.values())];

  const featLayer = new FeatureLayer({
    title: "SPATIAL JOIN",
    objectIdField: layer.objectIdField,
    fields: [...new Set([...layer.fields, ...extraFields])],
    geometryType: layer.geometryType,
    source,
    popupTemplate: {
      title: "Copy Layer",
      contentL: [
        {
          type: "fields",
          fieldInfos: [...fieldInfos.values()],
        },
      ],
    },
  });

  const SurveyorLayer = new FeatureLayer({
    title: "Survey Layer",
    objectIdField: layer.objectIdField,
    fields: [...new Set([...layer.fields, ...extraFields])],
    geometryType: layer.geometryType,
    source,

    renderer: SurveyorSymbol,
    maxScale: 145500,
  });
  console.log("surveyor layer", SurveyorLayer);
  return { featLayer, SurveyorLayer };
}
