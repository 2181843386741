import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AssessmentResource from "api/assessment";
import InfrastructureResource from "api/infrastructures";
import TableHeaderComponent from "components/common/TableHeaderComponent";
import InfraStructureAssessmentDetailPDF from "components/infrastructure/InfraStructureAssessmentDetailPDF";
import InfrastructureAssessmentEditedListItem from "components/infrastructure/infrastructureAssessmentEditedListItem";
import {
  damageTypes,
  infrastructureEditHistoryListColumns,
  infrastructureStatuses,
} from "constants/data";
import routes from "constants/routes";
import dayjs from "dayjs";
import { ReactElement, useRef, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { GiInfo } from "react-icons/gi";
import {
  MdOutlineDelete,
  MdOutlineDownload,
  MdOutlineRestartAlt,
} from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { DateFormat } from "utils";
import { femaExport } from "utils/fileExport";
import { getFullName } from "utils/recon-command-center";

const AssessmentDetail = () => {
  const infrastructureAPI = new InfrastructureResource();
  const assessmentAPI = new AssessmentResource();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const selectDownloadRef = useRef<HTMLSelectElement | null>(null);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { data: assessmentDetail } = useQuery(
    [`assessmentDetail-${params.assessment_id?params.assessment_id:0}`],
    async () => {
      const response = await assessmentAPI.get(
        params?.assessment_id ? Number(params.assessment_id) : 0
      );

      return response?.data;
    }
  );

  const { data: assessmentList, isLoading: isAssessmentLoading } = useQuery(
    [`assessmentDetailLis-${ params?.bridge_id ? Number(params.bridge_id) : 0}`],
    async () => {
      const response = await infrastructureAPI.getInfrastructureAssessment(
        params?.bridge_id ? Number(params.bridge_id) : 0
      );

      return response?.data;
    }
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [currentImageGroup, setCurrentImageGroup] = useState<any>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentMeasurement, setCurrentMeasurement] = useState<any>({});
  const [isMeasurementViewerOpen, setIsMeasurementViewerOpen] = useState(false);

  const DamageSection = ({
    title,
    children,
    buttonData,
    images = [],
    notes = [],
    measurements = [],
  }: {
    title: String;
    children?: ReactElement;
    buttonData?: { status: String | null | boolean };
    images?: String[];
    measurements?: any;
    notes?: String[];
  }) => {
    return (
      <Box className="overall-damage-container">
        <Text color={"black"} fontSize="13px" my="5px">
          {title}
        </Text>
        <Box className="damage-rating-container" justifyContent={""}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mr="10px"
            flex={0.4}
          >
            <Text
              backgroundColor={
                buttonData?.status === "ND"
                  ? "#8CB832"
                  : buttonData?.status === true
                  ? "#8CB832"
                  : "#9C9C9C"
              }
              variant="solid"
              borderStartRadius={"6px"}
              borderRadius={0}
              className="damage-rating-button"
              p="10px"
              justifyContent={"center"}
            >
              <Text as="span" className="rating-button-text">
                No&nbsp;Damage
              </Text>
            </Text>
            <Text
              backgroundColor={
                buttonData?.status === "PD"
                  ? "#ECD37B"
                  : buttonData?.status === true
                  ? "#ECD37B"
                  : "#D1D1D1"
              }
              variant="solid"
              borderRadius={0}
              className="damage-rating-button"
              p="10px"
              justifyContent={"center"}
            >
              <Text as="span" className="rating-button-text">
                {" "}
                Partial Damage
              </Text>
            </Text>
            <Text
              backgroundColor={
                buttonData?.status === "DA"
                  ? "#FC6C72"
                  : buttonData?.status === true
                  ? "#FC6C72"
                  : "#989898"
              }
              variant="solid"
              borderEndRadius={"6px"}
              borderRadius={0}
              className="damage-rating-button"
              p="10px"
              justifyContent={"center"}
            >
              <Text as="span" className="rating-button-text">
                {" "}
                Severe
              </Text>
            </Text>
          </Box>
          <Box className="blackInfo-container">
            <GiInfo color="white" className="rating-info-icon" />
          </Box>
        </Box>
        <Box h={"23px"} />

        <ImageGallery images={images} />

        <Box h={"23px"} />

        <ImageGalleryMeasurement measurements={measurements} />

        {notes.length > 0 &&
          notes.map((note: any, index: number) => {
            return (
              <Flex key={index}>
                <Box h={21} />
                <NoteBox heading={`Note ${index}`} desc={note} />
              </Flex>
            );
          })}

        {children && <Box h={"50px"} />}
        <Divider borderColor={"#E8E8E8"} borderBottomWidth="2px" />
        <Box h={"20px"} />
      </Box>
    );
  };

  const NoteBox = ({ heading, desc }: { heading?: string; desc: string }) => {
    return (
      <Box
        display={"flex"}
        flexDirection="column"
        borderWidth={"1px"}
        borderColor="#DEDEDE"
        borderRadius={"8px"}
        p="18px"
        minW={"100%"}
        mt={2}
      >
        <Text color={"#8B8B8B"} fontWeight="bold" fontSize={"12px"}>
          {heading}
        </Text>
        <Text color={"#8B8B8B"} fontWeight="semibold" fontSize={"12px"}>
          {desc}
        </Text>
      </Box>
    );
  };

  const handleImageClick = (images: Array<String>, currentPosition: number) => {
    setCurrentImage(currentPosition);
    setCurrentImageGroup(images);
    setIsViewerOpen(true);
  };

  const handleMeasurementClick = (measurement: any) => {
    setCurrentMeasurement(measurement);
    setIsMeasurementViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const closeImageMeasurementViewer = () => {
    setIsMeasurementViewerOpen(false);
  };

  const ImageGallery = (props: any) => {
    const { images } = props;
    return (
      <Box display={"flex"} flexDirection="row">
        {/* overflowX="scroll" */}
        {images &&
          images.map((img: any, index: number) => {
            return (
              <Image
                key={index}
                className="assessment-gallery-thumb"
                src={img}
                onClick={() => handleImageClick(images, index)}
              />
            );
          })}
      </Box>
    );
  };

  const ImageGalleryMeasurement = (props: any) => {
    const { measurements } = props;
    return (
      <Box display={"flex"} flexDirection="row">
        {/* overflowX="scroll" */}
        {measurements &&
          measurements.map((measurement: any, index: number) => {
            return (
              <Image
                key={index}
                className="assessment-gallery-thumb"
                src={measurement?.image}
                onClick={() => handleMeasurementClick(measurement)}
              />
            );
          })}
      </Box>
    );
  };


  const goToPrevPage = () => {
    const currentBridgeId = params?.bridge_id;

    navigate(
      routes.infrastructures.bridge.assessment.list.replace(
        ":bridge_id",
        currentBridgeId ? currentBridgeId : ""
      )
    );
  };

  const resetMutation = useMutation(
    (id: number) => infrastructureAPI.assessmentReset(id),
    {
      onSuccess: (res: any) => {
        toast({
          title: `Status has been changed.`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        window.location.reload();
      },
      onError: (error: any) => {
        toast({
          title: `${error?.response?.data?.detail}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const handleResetStatus = () => {
    if (params?.assessment_id) {
      resetMutation.mutate(+params?.assessment_id);
    }
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDownloadModalClose = () => {
    setDownloadModalOpen(false);
    setSelectedInput("");
  };

  const deleteInfraStructureMutation = useMutation(
    (id: number) => infrastructureAPI.assessmentDelete(id),
    {
      onSuccess: (res: any) => {
        params?.bridge_id &&
          navigate(
            routes.infrastructures.bridge.assessment.list.replace(
              ":bridge_id",
              params?.bridge_id
            )
          );
      },
      onError: (error: any) => {
        toast({
          title: `${error?.response?.data?.detail}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const onDeleteConfirm = () => {
    if (params?.assessment_id) {
      deleteInfraStructureMutation.mutate(+params?.assessment_id);
    }
  };

  const [showPDF, setShowPDF] = useState(false);
  const handleDownload = async () => {
    if (selectDownloadRef.current?.value) {
      switch (selectDownloadRef.current?.value) {
        case "fema":
          const dataArray = assessmentList?.data?.map(
            (data: any, index: number) => {
              const prevStatus =
                index === assessmentList?.data?.length - 1
                  ? "ND"
                  : assessmentList?.data?.[index + 1].damage_level;
              return {
                "CHANGED BY": getFullName(data?.user),
                FROM: infrastructureStatuses[prevStatus],
                TO: infrastructureStatuses[data?.damage_level],
                "TIME AND DATE": dayjs(data?.date).format("MM/DD/YY hh:mm A"),
              };
            }
          );

          const damages = assessmentList?.data?.map((data: any) => {
            return data.damages;
          });

          let images = [];

          for (const damage of damages) {
            for (const assessment of damage) {
              if (assessment.images && assessment.images.length > 0) {
                for (const img of assessment.images) {
                  images.push({
                    image_name: img,
                    image_url: img,
                  });
                }
              }

              if (
                assessment.measurements &&
                assessment.measurements.length > 0
              ) {
                for (const mImg of assessment.measurements) {
                  images.push({
                    image_name: mImg,
                    image_url: mImg,
                  });
                }
              }
            }
          }

          await femaExport(images, dataArray, `Assesment`, setLoading);

          setDownloadModalOpen(false);
          onDownloadModalClose();
          break;
        case "pdf":
          setShowPDF(true);
      }
    }
  };

  const handleDownloadChange = (e: any) => {
    setSelectedInput(e?.target?.value);
    if (e?.target?.value === "pdf") {
      setShowPDF(true);
    } else {
      setShowPDF(false);
    }
  };

  return (
    <Box display={"flex"} flexDirection="column" pl={"60px"} width={"85%"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection="row"
        marginY="22px"
      >
        <Box
          // flex={0.4}
          flexDirection="row"
          display={"flex"}
          alignItems={"center"}
        >
          <AiOutlineLeft size={20} cursor={"pointer"} onClick={goToPrevPage} />
          <Text fontSize={20} color="black" ml={"5px"}>
            Bridge {assessmentDetail?.infrastructure_number} Assessment{" "}
            {DateFormat(assessmentDetail?.date)}
          </Text>
        </Box>

        <Flex justify="space-between">
          <Button
            leftIcon={<MdOutlineRestartAlt />}
            variant={"white"}
            onClick={() => handleResetStatus()}
          >
            Reset Status
          </Button>
          <Button
            leftIcon={<MdOutlineDownload />}
            variant={"white"}
            onClick={() => setDownloadModalOpen(true)}
          ></Button>
          <Button
            leftIcon={<MdOutlineDelete />}
            variant={"white"}
            onClick={() => setDeleteModalOpen(true)}
          ></Button>
        </Flex>
      </Box>
      <Box>
        <Box flexDirection={"row"} display="flex">
          <Text fontSize={"13px"} lineHeight="18px" color="black" mr="10px">
            Inspection By:
          </Text>
          <Text fontWeight={"bold"} fontSize={"14px"} color="black">
            {getFullName(assessmentDetail?.user)}
          </Text>
        </Box>
        <Box flexDirection={"row"} display="flex">
          <Text fontSize={"13px"} lineHeight="18px" color="black" mr="10px">
            Inspection Time & Date:
          </Text>
          <Text fontWeight={"bold"} fontSize={"14px"} color="black">
            {dayjs(assessmentDetail?.date).format("MMM D, YYYY h:mm A")}
          </Text>
        </Box>{" "}
      </Box>
      <DamageSection
        title="Overall Damage"
        buttonData={{ status: assessmentDetail?.damage_level }}
      />
      {assessmentDetail?.damages.map((damage: any, index: number) => {
        return (
          <DamageSection
            title={damageTypes[damage?.type]}
            key={index}
            buttonData={{ status: damage?.status }}
            images={damage.images}
            notes={damage?.notes}
            measurements={damage?.measurements}
          />
        );
      })}
      {assessmentDetail?.additional_notes && (
        <Box>
          <Text color={"black"}>Additional Notes</Text>
          <Box h={"16px"} />
          <NoteBox desc={assessmentDetail?.additional_notes} />
        </Box>
      )}
      <Stack bg="white" shadow="box" rounded="sm" my={8}>
        <TableContainer>
          <Table>
            <TableHeaderComponent
              columns={infrastructureEditHistoryListColumns}
              showDelete={false}
              // setFilterParams={setFilterParams}
            />
            <Tbody>
              {!isAssessmentLoading && (
                <InfrastructureAssessmentEditedListItem
                  listData={assessmentList?.data}
                />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      {isViewerOpen && (
        <Flex color="white">
          <Box flex="1" bg="tomato">
            <Stack className="slider-content-wrapper1">
              <ImageViewer
                src={currentImageGroup}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={true}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
              {/* <Flex flexDirection={"row"} className="slider-content">
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    All Measurements12
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 1
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 2
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 3
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 4
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 5
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 6
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 7
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Switch size="sm" mr={"10px"} />
                  <Text color={"black"} ml="4">
                    Measurement name 8
                  </Text>
                </Stack>
              </Flex> */}
            </Stack>
          </Box>
        </Flex>
      )}

      {isMeasurementViewerOpen && (
        <Flex color="white">
          <Box flex="1" bg="tomato">
            <Stack className="slider-content-wrapper">
              <ImageViewer
                src={[currentMeasurement?.image]}
                currentIndex={0}
                onClose={closeImageMeasurementViewer}
                disableScroll={true}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
              <Flex flexDirection={"row"} className="slider-content">
                <Stack align="center" direction={"row"}>
                  <Text color={"black"} ml="4">
                    LRF:{" "}
                    {currentMeasurement?.lrf ? currentMeasurement.lrf : "N/A"}
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Text color={"black"} ml="4">
                    M: {currentMeasurement?.M ? currentMeasurement.M : "N/A"}
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Text color={"black"} ml="4">
                    N: {currentMeasurement?.N ? currentMeasurement.N : "N/A"}
                  </Text>
                </Stack>
                <Stack align="center" direction={"row"}>
                  <Text color={"black"} ml="4">
                    H: {currentMeasurement?.H ? currentMeasurement.H : "N/A"}
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </Box>
        </Flex>
      )}

      {/* Download Modal Box */}
      <Modal
        isOpen={isDownloadModalOpen}
        isCentered
        onClose={onDownloadModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Center>
              <VStack>
                <IconButton
                  variant={"unstyled"}
                  aria-label="Download"
                  icon={<MdOutlineDownload />}
                />
                <Select
                  placeholder="Format Type"
                  ref={selectDownloadRef}
                  onChange={handleDownloadChange}
                >
                  <option value="fema">FEMA Form</option>
                  <option value="pdf">PDF</option>
                </Select>
                {selectedInput === "fema" && (
                  <Text color={"#000"}>
                    Would you like to fill out additional questions for FEMA?
                  </Text>
                )}
                <ButtonGroup mt={"1.5rem !important"}>
                  {showPDF && (
                    <>
                      <PDFDownloadLink
                        document={
                          <InfraStructureAssessmentDetailPDF
                            assessmentDetail={assessmentDetail}
                            assessmentList={assessmentList?.data}
                          />
                        }
                        onClick={() => setDownloadModalOpen(false)}
                        fileName={`Assesment-${DateFormat(new Date())}.pdf`}
                      >
                        {({ blob, url, loading, error }) => {
                          return (
                            <Button
                              colorScheme="primary"
                              isLoading={loading}
                              loadingText="Loading..."
                            >
                              Download
                            </Button>
                          );
                        }}
                      </PDFDownloadLink>
                      <Button variant="outline" onClick={onDownloadModalClose}>
                        {"Cancel"}
                      </Button>
                    </>
                  )}
                  {selectedInput === "fema" && (
                    <>
                      <Button
                        colorScheme="primary"
                        onClick={() =>
                          navigate(
                            routes.infrastructures.bridge.assessment.fema_form +
                              "?assessment_id=" +
                              params?.assessment_id
                          )
                        }
                      >
                        {"Yes"}
                      </Button>
                      <Button
                        colorScheme="primary"
                        onClick={handleDownload}
                        isLoading={loading}
                      >
                        {"No"}
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Delete Modal Box */}
      <Modal isOpen={isDeleteModalOpen} isCentered onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Center>
              <VStack>
                <IconButton
                  variant={"unstyled"}
                  aria-label="Delete"
                  icon={<MdOutlineDelete />}
                />
                <Text color={"primary"} as={"b"}>
                  Are you sure you want to delete?
                </Text>
                <Text color={"primary"} textAlign={"center"}>
                  Once you confirm deleting this you will not have a chance to
                  undo.
                </Text>
                <ButtonGroup mt={"1.5rem !important"}>
                  <Button
                    colorScheme="primary"
                    onClick={onDeleteConfirm}
                    isLoading={deleteInfraStructureMutation.isLoading}
                  >
                    {"Yes, Delete"}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    {"Cancel"}
                  </Button>
                </ButtonGroup>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssessmentDetail;
