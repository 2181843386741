//@ts-nocheck
import {
  Box,
  Card,
  CardBody,
  Circle,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DangerCircle } from "assets/icons";
import { infrastructureStatuses } from "constants/data";
import { DateFormatYMD } from "../../utils/index";
import { BridgeImageComponent } from "./bridgeImage";
// import { showLocation } from "components/map/MapCutomFuntioncs";

// interface DataI {
//   data: BridgeData;
// }

const BridgeListCard = ({
  data,
  showLocation,
}: {
  data: any;
  showLocation: any;
}) => {
  function ShowLocation(p: any, f: any) {
    // console.log("SHOW LOCATION CLICK=================", f);
    if (p.coordinates && p.coordinates[0] && p.coordinates[1]) {
      let pt = {
        latitude: p.coordinates[0],
        longitude: p.coordinates[1],
      };

      // showLocation(pt);
      // var line = p;

      showLocation(pt, f);
      // focusFeature(p);
    } else return;
  }

  return (
    <Box>
      {data?.features?.map((item: any, index: Number) => {
        return (
          <>
            <Card
              key={index}
              onClick={() => ShowLocation(item.properties.geometry_json, item)}
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="filled"
              bgColor={"#fff"}
              w="95%"
              display={"flex"}
              align="flex-start"
              p={"0px"}
              mb={"10px"}
              justify="flex-start"
              cursor={"pointer"}
            >
              <Box
                maxW={{ sm: "30%", md: "30%", lg: "30%" }}
                minW={{ sm: "30%", md: "30%", lg: "30%" }}
                maxH={{ sm: "100%", md: "100%", lg: "100%" }}
                minH={{ sm: "100%", md: "100%", lg: "100%" }}
              >
                {/* {isImageLoaded === null && <CenterSpinner />} */}

                <BridgeImageComponent
                  imageUrl={item?.properties?.image}
                  imageAlt={item?.properties?.bridge_name}
                />
              </Box>
              <Stack w={"60%"}>
                <CardBody py={"5px"}>
                  <Flex>
                    <Heading size="sm">{item.properties.bridge_name}</Heading>
                    {item?.properties?.is_alert && (
                      <Image
                        src={DangerCircle}
                        alt="End Alert"
                        title="End Alert"
                      />
                    )}
                  </Flex>
                  <Box noOfLines={3}>
                    <Text fontSize={"10px"}>
                      Next Assessment:{" "}
                      {DateFormatYMD(item.properties.next_assessment_date)}
                    </Text>

                    <Text fontSize={"10px"}>
                      Risk:{" "}
                      {
                        infrastructureStatuses[
                          item.properties.infrastructure_status
                        ]
                      }
                    </Text>
                  </Box>
                </CardBody>
              </Stack>
              <Box w={"10%"} pt="5px">
                <Circle
                  size="13px"
                  bg={
                    item.properties.infrastructure_status === "DA"
                      ? "#FC6C72"
                      : item.properties.infrastructure_status === "ND"
                      ? "#8CB832"
                      : item.properties.infrastructure_status === "DS"
                      ? "#ECD37B"
                      : item.properties.infrastructure_status === "DNS"
                      ? "#AFAFAF"
                      : item.properties.infrastructure_status === "DSE"
                      ? "repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px)"
                      : item.properties.infrastructure_status === "PDS"
                      ? "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px)"
                      : item.properties.infrastructure_status === "NDS"
                      ? "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px)"
                      : "#ECA47B" // PD
                  }
                  color="white"
                />
              </Box>
            </Card>
          </>
        );
      })}
    </Box>
  );
};

export default BridgeListCard;
