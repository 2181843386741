import { useToast } from "@chakra-ui/react";
import { setUnreadCount } from "actions/data/chat";
import { useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { socket } from "services/socket";
import { truncateString } from "utils";

const ChatRegister = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );
// @ts-ignore
if(socket){
  socket.onmessage = (data) => {
    queryClient.invalidateQueries("chatList");

    const messageDetail = JSON.parse(data.data);

    dispatch(setUnreadCount(messageDetail?.unread_count));

    if (
      messageDetail?.data?.sender?.id !== loggedInUser.id &&
      messageDetail?.event_type !== "join_room"
    ) {
      let title = `New Message From ${messageDetail?.data?.sender?.full_name}`;

      toast({
        title: title,
        description: truncateString(messageDetail?.message),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };
}


  return null;
};
export default ChatRegister;
