import Resource from "api/resource";
import http from "utils/http";

class ChatResource extends Resource {
  constructor() {
    super("chat");
  }

  // Chat Item List
  currentConversation(query?: any) {
    return http({
      url: `get-conversations/`,
      method: "get",
      params: query,
    });
  }

  getAllMessage(id: any, query?: any) {
    return http({
      url: `/chat/${id}/`,
      method: "get",
      params: query,
    });
  }

  nextMessage(nextUrl: string) {
    return http({
      url: nextUrl,
      method: "get",
    });
  }

  readConversation(resource: any) {
    return http({
      url: `/chat/read/messages/`,
      method: "post",
      data: resource,
    });
  }

  createNewChat(resource: any) {
    return http({
      url: `chat/private/create/`,
      method: "post",
      data: resource,
    });
  }

  createNewGroupChat(resource: any) {
    return http({
      url: `chat/group/create/`,
      method: "post",
      data: resource,
    });
  }

  deleteGroupChat(roomId: string) {
    return http({
      url: `chat/room/${roomId}/`,
      method: "delete",
    });
  }

  addRemoveMemberGroupChat(roomId: string, resource: any) {
    return http({
      url: `chat/room/${roomId}/add-remove-user/`,
      method: "post",
      data: resource,
    });
  }

  getMemberGroupChat(roomId: string) {
    return http({
      url: `chat/room/${roomId}/users/`,
      method: "get",
    });
  }

  deleteMessage(messageId: any) {
    return http({
      url: `chat/message/${messageId}/`,
      method: "delete",
    });
  }

  resetChatStatus() {
    return http({
      url: `chat/reset/`,
      method: "put",
    });
  }
}

export { ChatResource as default };
