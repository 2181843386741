import {
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import Pagination from "components/common/Pagination";
import TableHeaderComponent from "components/common/TableHeaderComponent";
import TableSkeletonLoader from "components/common/TableSkeletonLoader";
import UserListItem from "components/user/UserListItem";
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from "constants/common";
import { columns } from "constants/data";
// import PermissionRequest from "constants/PermissionRequest";
import { FilterParams } from "interface/common/filterParam";
import { OrganizationDataSchema } from "interface/organization/organizationListItem";
import { UserSchema } from "interface/user/userListItem";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
// import AccessControl from "services/AccessControl";
import { getStartingSerialNumber } from "utils";
import CreateUser from "./CreateUser";

const UserList: React.FC<OrganizationDataSchema> = (
  props: OrganizationDataSchema
) => {
  const { listData } = props;

  const { pathname } = useLocation();

  const organizationId = pathname.split("/")[pathname.split("/").length - 1];

  const organizationAPI = new OrganizationResource();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: "",
    sort_by: "",
  });

  const { data: userList, isLoading: isListLoading } = useQuery(
    [
      "organizationUserList",
      {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        keyword: filterParams.keyword,
        sort_by: filterParams.sort_by,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        has_loggedin_user: false,
      };
      if (filterParams.keyword !== "")
        queryParams.keyword = filterParams.keyword;

      if (filterParams.sort_by !== "")
        queryParams.sort_by = filterParams.sort_by;

      const response = await organizationAPI.organizationUsersList(
        organizationId ? Number(organizationId) : 0,
        queryParams
      );

      return response?.data;
    }
  );
  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.name,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md" color={"#23282C"}>
              {listData ? `${listData.org_name} Users` : `Users`}
            </Heading>
          </Stack>

          <Stack direction="row">
          {/* <AccessControl
          allowedPermissions={[
            PermissionRequest['manage:user'],
          ]}
          renderNoAccess={(data: any) => data}> */}
            <Button
              size="sm"
              colorScheme="primary"
              backgroundColor={"#FFFFFF"}
              border={"2px"}
              borderColor={"#616A72"}
              color={"#383861"}
              onClick={() => setDeleteModalOpen(true)}
              className={"add-user-btn"}
            >
              + Add User
            </Button>
            {/* </AccessControl> */}
          </Stack>
        </Flex>
        <Flex justify={"space-between"}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                {...methods.register("search")}
                onBlur={(e: any) => {
                  setFilterParams((prevState: any) => ({
                    ...prevState,
                    keyword: e.target.value,
                  }));
                }}
              />
            </InputGroup>
          </form>

          {!isListLoading && (
            <Pagination
              dataList={userList}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          )}
        </Flex>

        <Stack bg="white" shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <TableHeaderComponent
                columns={columns}
                showDelete={true}
                setFilterParams={setFilterParams}
              />
              <Tbody>
                {!isListLoading &&
                  userList?.data?.map((listData: UserSchema, index: number) => (
                    <UserListItem
                      listData={listData}
                      key={listData.id}
                      index={startingSN + index}
                    />
                  ))}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
                {!isListLoading && userList.data.length === 0 && (
                  <Tr>
                    <Td colSpan={5}>
                      <Center color={"#605A54"} w={"100%"}>
                        No users found
                      </Center>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Modal
        isOpen={isDeleteModalOpen}
        isCentered
        onClose={onDeleteModalClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <CreateUser
              listData={listData}
              setDeleteModalOpen={setDeleteModalOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserList;
