import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import ChatResource from "api/chat";
import DOMPurify from "dompurify";
import React from "react";
import { MdOutlineDelete } from "react-icons/md";
import { SlOptions } from "react-icons/sl";

import "react-perfect-scrollbar/dist/css/styles.css";
import { useMutation, useQueryClient } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { TimeFormat } from "utils";
import { isValidDate } from "utils/validate-date";

interface Props {
  message: any;
  handleDeleteMessage: Function;
}

const ChatMessageDetails: React.FC<Props> = (props) => {
  const { message, handleDeleteMessage } = props;
  const chatAPI = new ChatResource();
  const queryClient = useQueryClient();

  const { loggedInUser, currentChatUserDetails } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );

  const deleteMessageMutation = useMutation(
    (id: number) => chatAPI.deleteMessage(id),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("chatList");
        queryClient.invalidateQueries("messageList");
      },
      onError: (res: any) => {},
    }
  );

  const handleDelete = (id: number) => {
    deleteMessageMutation.mutate(id);
    handleDeleteMessage(id);
  };

  const formatMessage = (message: string) => {
    const sanitizedMessage = DOMPurify.sanitize(message);
    const mentionPattern = /@\{id:(\d+),name:([^\\}]+)\}/g;
    return sanitizedMessage.replace(mentionPattern, (match, id, name) => {
      return `<strong style="color:yellow">${name}</strong>`;
    });
  };

  const getMessage = () => {
    const formattedMessage = formatMessage(message.message);

    if (loggedInUser?.id === message?.sender?.id) {
      return (
        <Flex alignItems="center" pos={"relative"} sx={{ alignSelf: "flex-end" }} maxW={"60%"}>
          <Flex className="message-option-view" display={"none"}>
            {/* Other options can go here */}
          </Flex>
          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                borderRadius={"10px"}
                aria-label="Option"
                className="message-option"
                icon={<SlOptions />}
              />
            </PopoverTrigger>
            <PopoverContent width={"xs"}>
              <PopoverArrow />
              <IconButton
                borderRadius={"10px"}
                mr={2}
                aria-label="Delete"
                onClick={() => handleDelete(message?.id)}
                icon={<MdOutlineDelete />}
              />
            </PopoverContent>
          </Popover>
          <Stack
            spacing="6"
            sx={{ alignSelf: "flex-end" }}
            maxW={"100%"}
            className="msg-time-wrap sender-wrap"
          >
            <Text
              wordBreak="break-all"
              whiteSpace={"pre-line"}
              className="chat-msg"
              dangerouslySetInnerHTML={{
                __html: formattedMessage.replace(
                  /<a/g,
                  '<a rel="noopener noreferrer" target="_blank"'
                ),
              }}
            ></Text>

            {isValidDate(message?.created_at) && (
              <Text className="chat-timer" mt={4}>
                {TimeFormat(new Date(message?.created_at))}
              </Text>
            )}
          </Stack>
        </Flex>
      );
    } else {
      return (
        <>
          {currentChatUserDetails.isGroup && (
            <Text className="msg-time-wrap receiver-wrap" color={"#44474A"}>
              {message?.sender?.full_name}
            </Text>
          )}
          <Stack
            spacing="4"
            maxW={"60%"}
            className="msg-time-wrap receiver-wrap"
          >
            <Text
              wordBreak="break-all"
              whiteSpace={"pre-line"}
              className="chat-msg"
              dangerouslySetInnerHTML={{
                __html: formattedMessage.replace(
                  /<a/g,
                  '<a rel="noopener noreferrer" target="_blank"'
                ),
              }}
            ></Text>
            {isValidDate(message?.created_at) && (
              <Text className="chat-timer" mt={4}>
                {TimeFormat(new Date(message?.created_at))}
              </Text>
            )}
          </Stack>
        </>
      );
    }
  };

  return getMessage();
};

export default ChatMessageDetails;
