import React, { useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import LazyLoad from 'react-lazyload';

import { BiHide, BiShow } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import TopBar from "components/layout/TopBar";
import routes from "constants/routes";
import { withAuthState } from "components/hoc/auth";
import { Background, Logo } from "assets/images";

interface Props {
  login: (email: string, pw: string) => void;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC<Props> = ({ login }) => {
  const { register, formState: { errors, isSubmitting }, handleSubmit } = useForm<LoginFormInputs>();
  const [showPassword, setShowPassword] = useState(false);
  const [errorState, setErrorState] = useState<{ isError: boolean; errMsg: string }>({ isError: false, errMsg: "" });

  const togglePasswordVisibility = useCallback(() => setShowPassword((prev) => !prev), []);

  const onSubmit = useCallback(async (data: LoginFormInputs) => {
    try {
       login(data.email, data.password);
    } catch (e: any) {
      const errorMessage = e?.message === "You are not authorized to login. Please contact administrator"
        ? "You are not authorized to login. Please contact administrator."
        : "Email or password did not match.";
      setErrorState({ isError: true, errMsg: errorMessage });
    }
  }, [login]);

  const ErrorAlert = useMemo(() => {
    if (!errorState.isError) return null;
    return (
      <Alert status="error">
        <AlertIcon />
        {errorState.errMsg}
      </Alert>
    );
  }, [errorState]);

  return (
    <Box bg="gray.50">
      <TopBar />

      <Flex h="calc(100vh - 75px)" backgroundImage={Background}  backgroundSize="cover" overflow="auto" pt="7rem" className="login-container">
        <Flex className="overlay" />
        <Flex flexBasis="50%" justifyContent="center">
          <Box bg="transparent" width="330px" rounded="3xl" pt="7rem" zIndex="1">
            <Heading as="h1" textColor="#FFFFFF">
            <LazyLoad height={56}>

              <Image src={Logo} alt="Recon" w={[56, 60, 60, 72]} loading="lazy"/>
</LazyLoad>
            </Heading>
            <Text fontSize="18px" textColor="#FFFFFF">
              the rapid infrastructure evaluation collection and integration application
            </Text>
          </Box>
        </Flex>
        <Flex flexBasis="50%" justifyContent="center">
          <Stack direction="column" spacing="8">
            <Box bg="#545454" p="8" pb="77" shadow="box" rounded="3xl" width="356px" zIndex="1">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="5">
                  <Text as="h2">
                    <Center textColor="#AFAFAF">Log in to your account</Center>
                  </Text>
                  {ErrorAlert}
                  <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                    <Input
                      bg={"#FFFFFF !important"}
                      id="email"
                      className="auth-input"
                      type="email"
                      placeholder="Email"
                      {...register("email", { required: "Email is required" })}
                    />
                    <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl colorScheme="primary" isInvalid={!!errors.password}>
                    <InputGroup>
                      <Input
                        id="password"
                        className="auth-input"
                        type={showPassword ? "text" : "password"}
                        bg={"#FFFFFF !important"}
                        placeholder="Password"
                        {...register("password", { required: "Password is required" })}
                      />
                      <InputRightElement>
                        <IconButton
                          icon={showPassword ? <BiHide /> : <BiShow />}
                          onClick={togglePasswordVisibility}
                          aria-label={showPassword ? "Hide password" : "Show password"}
                          bg="transparent"
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                  </FormControl>
                  <Flex justify="end">
                    <Button variant="link" colorScheme="primary" color="#AFAFAF" fontSize="10px">
                      <Link to={routes.auth.forgotPassword} className="forget-wrap">Forgot password?</Link>
                    </Button>
                  </Flex>
                  <Button
                    className="auth-button login-btn-wrap"
                    bg="#757575"
                    w="full"
                    type="submit"
                    rounded="lg"
                    size="lg"
                    colorScheme="primary"
                    isLoading={isSubmitting}
                    fontSize="12px"
                  >
                    Log In
                  </Button>
                  <Flex justify="center" mt="10px">
                    <Text>Don&#8217;t have an account? <Link to="mailto:Jordon.Kinsman@kihomac.com" color="#FFFFFF">Contact support.</Link></Text>
                  </Flex>
                  <Flex justify="center" mt="10px">
                    <Button variant="link" colorScheme="primary" color="#AFAFAF" fontSize="10px">
                      <Link to={routes.terms_conditions} className="forget-wrap">Terms & Conditions</Link>
                    </Button>
                    <Text as="span" ml="2" mr="2">|</Text>
                    <Button variant="link" colorScheme="primary" color="#AFAFAF" fontSize="10px">
                      <Link to={routes.privacy_policy} className="forget-wrap">Privacy Policy</Link>
                    </Button>
                  </Flex>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default withAuthState(Login);
