import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import FileDetails from "./FileDetails";

interface Props {
  title?: string;
  description?: string;
  fileKey?: string;
  multiple?: boolean;
  uploadedFiles?: any;
  deleteAttachment?: any;
  acceptFileType?: string;
  clearFileInputToggle?: boolean; // clear input files (myFiles) on toggle of this prop
  maxFiles?: number;
}
const FileUploader: React.FC<Props> = (props) => {
  const {
    title = "Upload Files",
    description = "Upload Image",
    fileKey = "file",
    multiple = true,
    uploadedFiles,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    acceptFileType = {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    clearFileInputToggle,
    maxFiles = 1,
  } = props;

  //   let uploadedDataFiles: any = uploadedFiles?.filter((item: any) => !!item);
  // if (uploadedFiles !== undefined) uploadedDataFiles = [...uploadedFiles];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [myUploadedFiles, setMyUploadedFiles] = useState<any>(uploadedFiles);
  const [myFiles, setMyFiles] = useState<any>([]);
  const { setValue } = useFormContext();
  const toast = useToast();
  const isFirstMount = useRef(true);

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        let title = "Maximum upload attachments size upto 15 MB.";
        if (fileRejections[0]?.errors[0]?.code === "too-many-files") {
          title = `Only ${maxFiles} file is allowed.`;
        }
        toast({
          title: title,
          status: "error",
          isClosable: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple, myFiles, toast]
  );

  React.useMemo(() => {
    if (multiple) {
      setValue(fileKey, myFiles);
    } else {
      setValue(fileKey, myFiles[0]);
    }
  }, [fileKey, multiple, myFiles, setValue]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 5) {
      //Limit 5 MB
      return {
        code: "file-size-too-large",
        message: `File size is too large.`,
      };
    }

    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    validator: fileSizeValidator,
    multiple: multiple,
    onDrop,
    maxFiles: maxFiles,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file: any, index: number) => (
    <ListItem key={index} padding="8px 0 8px 0">
      <Flex justify="space-between">
        <FileDetails
          fileName={file.path.replace(/\.[^/.]+$/, "")}
          fileSize={file.size}
          mimeType={file.type}
          file={file}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: "pointer", color: "red.300" }}
            icon={<BiTrash />}
            variant="link"
            aria-label={"Delete"}
            onClick={removeFile(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const uploadedFileList = myUploadedFiles.map((file: any, index: number) => (
    <ListItem key={index} padding="8px 0 8px 0">
      <Flex justify="space-between">
        <FileDetails
          fileName={new URL(file.url).pathname.split("/").pop() || ""}
          fileSize={file.size}
          mimeType={"image"}
          file={file.url}
        />
      </Flex>
    </ListItem>
  ));

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setMyFiles([]);
      setValue(fileKey, []);
    }
  }, [clearFileInputToggle, fileKey, setValue]);

  return (
    <>
      <Center
        {...getRootProps()}
        cursor="pointer"
        w="100%"
        h="160px"
        bg="gray.50"
        overflow="hidden"
        objectFit="cover"
        shadow="box"
        rounded="sm"
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100"
      >
        <input {...getInputProps()} />
        <VStack>
          <Icon as={BiPlus} h="16" w="16" color="gray.300" />
          {isDragActive ? (
            <Text textAlign="center" color="gray.400">
              {title}
            </Text>
          ) : (
            <Text textAlign="center" color="gray.400">
              {description}
            </Text>
          )}
        </VStack>
      </Center>
      {myUploadedFiles.length > 0 && (
        <List py="2" spacing="2">
          {uploadedFileList}
        </List>
      )}
      {files.length > 0 && (
        <List py="2" spacing="2">
          {files}
        </List>
      )}
    </>
  );
};

export default FileUploader;
