import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import AssessmentResource from "api/assessment";
import FemaForm from "components/assessment/FemForm";
import { UserSchema } from "interface/user/userListItem";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { DateFormatYMD } from "utils";
import { downloadPdf } from "utils/fileExport";

const FemaFormPage: React.FC<any> = (props: any) => {
  const toast = useToast();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<UserSchema>();
  const api = new AssessmentResource();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const assessmentId: string = params.get("assessment_id") || "0";
  const navigate = useNavigate();

  useEffect(() => {
    if (+assessmentId <= 0) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  const {
    data: femaFormData,
    isLoading,
    isError,
  } = useQuery(["femaFormData", { assessmentId }], async () => {
    const response = await api.getFemaForm(assessmentId);
    return response?.data?.data;
  });

  if (isError) {
    navigate(-1);
  }
  const [errorMessage, setErrorMessage] = useState<any>();

  const createFemaForm = useMutation(
    (data: any) =>
      api.storeFemaForm(data, { "content-type": "multipart/form-data" }),
    {
      onSuccess: (res: any) => {
        downloadPdf(res?.data?.data?.form_pdf, "fema-form");
        toast({
          position: "top",
          title: "Data has been saved.",
          status: "success",
          isClosable: true,
        });
        navigate(-1);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
        else setErrorMessage("Something is wrong");
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("facility_type", data?.facility_type);
    formData.append("facility_name", data?.facility_name);
    formData.append("facility_description", data?.facility_description);
    formData.append("street_address", data?.street_address);
    formData.append("year_built", data?.year_built);
    formData.append("dimensions", data?.dimensions);
    formData.append("magnitude", data?.magnitude);
    formData.append("materials", data?.materials);
    formData.append("variant", data?.variant);
    formData.append("damage_cause_desc", data?.damage_cause_desc);
    formData.append("component_type", data?.component_type);
    formData.append("component_location", data?.component_location);
    formData.append("specification", data?.specification);
    formData.append("damage_dimensions", data?.damage_dimensions);
    formData.append("repair_method", data?.repair_method);
    formData.append("work_performed", data?.work_performed);
    formData.append("material_change", data?.material_change);
    formData.append("size_change", data?.size_change);
    formData.append("repair_comment", data?.repair_comment);
    formData.append("ehp_issues", data?.ehp_issues);
    formData.append(
      "incident_period_start_date",
      DateFormatYMD(data?.incident_period_start_date)
    );
    formData.append(
      "incident_period_end_date",
      DateFormatYMD(data?.incident_period_end_date)
    );
    formData.append("damaged_date", DateFormatYMD(data?.damaged_date));
    if (data?.firmette?.length > 0) {
      for (const firmette of data?.firmette) {
        formData.append("firmette", firmette);
      }
    }
    if (data?.firmette?.length > 0) {
      for (const facility_map_images of data?.facility_map_images) {
        formData.append("facility_map_images", facility_map_images);
      }
    }
    if (data?.facility_map_images?.length > 0) {
      for (const facility_map_images of data?.facility_map_images) {
        formData.append("facility_map_images", facility_map_images);
      }
    }
    if (data?.facility_sketch.length > 0) {
      for (const facility_sketch of data?.facility_sketch) {
        formData.append("facility_sketch", facility_sketch);
      }
    }
    if (data?.whole_area_images?.length > 0) {
      for (const whole_area_images of data?.whole_area_images) {
        formData.append("whole_area_images", whole_area_images);
      }
    }
    if (data?.damage_images?.length > 0) {
      for (const damage_images of data?.damage_images) {
        formData.append("damage_images", damage_images);
      }
    }
    if (data?.component_images?.length > 0) {
      for (const component_images of data?.component_images) {
        formData.append("component_images", component_images);
      }
    }
    if (data?.cracks_images?.length > 0) {
      for (const cracks_images of data?.cracks_images) {
        formData.append("cracks_images", cracks_images);
      }
    }
    formData.append("assessment", assessmentId);
    formData.append(
      "gps_coordinates",
      `POINT (${femaFormData?.longitude} ${femaFormData?.latitude})`
    );

    createFemaForm.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md" color={"#23282C"}>
          Fema Form
        </Heading>
      </Flex>

      <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {createFemaForm.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              {!isLoading && <FemaForm femaFormData={femaFormData} />}
              <Button
                mt={"8 !important"}
                colorScheme="primary"
                type="submit"
                isLoading={createFemaForm.isLoading}
                backgroundColor={"#000000"}
                _hover={{ backgroundColor: "#000000" }}
              >
                Save
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default FemaFormPage;
