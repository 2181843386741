import { Td, Text, Tr } from "@chakra-ui/react";
import { infrastructureStatuses } from "constants/data";
import dayjs from "dayjs";
import React from "react";
import { getFullName } from "utils/recon-command-center";

const InfrastructureAssessmentEditedListItem: React.FC<any> = (props) => {
  const { listData } = props;

  const getStatus = (currentStatus: string) => {
    return (
      <Text
        as={"span"}
        backgroundColor={
          currentStatus === "DA"
            ? "#FC6C72"
            : currentStatus === "ND"
            ? "#8CB832"
            : currentStatus === "DS"
            ? "#ECD37B"
            : currentStatus === "DNS"
            ? "#AFAFAF"
            : currentStatus === "DSE"
            ? "repeating-linear-gradient(-45deg, #FC6C72, #FC6C72 3px, #fff, #fff 6px)"
            : currentStatus === "PDS"
            ? "repeating-linear-gradient(-45deg, #ECA47B, #ECA47B 3px, #fff, #fff 6px)"
            : currentStatus === "NDS"
            ? "repeating-linear-gradient(-45deg, #8CB832, #8CB832 3px, #fff, #fff 6px)"
            : "#ECA47B" // PD
        }
        color={"#FFFFFF"}
        borderRadius={"lg"}
        className="infrastructure-status"
      >
        {infrastructureStatuses[currentStatus]}
      </Text>
    );
  };

  return listData?.map((data: any, index: number) => {
    const prevStatus =
      index === listData.length - 1 ? "ND" : listData[index + 1].damage_level;

    return (
      <Tr key={data?.id}>
        <Td>{getFullName(data?.user)}</Td>
        <Td>{prevStatus && getStatus(prevStatus)}</Td>
        <Td>{data?.damage_level && getStatus(data?.damage_level)}</Td>
        <Td>{dayjs(data?.date).format("MM/DD/YY hh:mm A")}</Td>
      </Tr>
    );
  });
};

export default InfrastructureAssessmentEditedListItem;
