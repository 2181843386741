// Auth
export const ACCESS_TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";
export const EXPIRES_IN = "expires";

// Pagination

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_PAGE_SIZE_MAX = 1500;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];

export const ZIP_EXTENSION =
  "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed";

export const ASSESSMENT_FREQUENCY = [
  { id: "6M", name: "6 months" },
  { id: "1Y", name: "1 Year" },
  { id: "2Y", name: "2 Years" },
  { id: "3Y", name: "3 Years" },
  { id: "4Y", name: "4 Years" },
  { id: "5Y", name: "5 Years" },
];

export const MAP_FILTERS = [
  { id: "DS", name: "Damage suspected" },
  { id: "DNS", name: "Damage not suspected" },
  { id: "DSE", name: "Damage seen" },
  { id: "PDS", name: "Partial damage seen" },
  { id: "NDS", name: "No damage seen" },
  { id: "DA", name: "Damaged" },
  { id: "PD", name: "Partially damaged" },
  { id: "ND", name: "Not damaged" },
];

export const EVENT_TYPE_LIST = [
  { id: "TI", name: "Timed" },
  { id: "EM", name: "Emergency" },
  { id: "EQ", name: "Earthquake" },
  { id: "FL", name: "Flood" },
  { id: "TR", name: "Training" },
];

export const publicAPI = ["/token/", "/reset-password/", "/set-password/"];

export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";
